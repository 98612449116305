
export default {
  name: 'ResizeMixin',
  data () {
    return {
      window: {
        w: 0,
        h: 0
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.resizeMixinEventHandler)
    // Call handler to set values on mounted
    this.resizeMixinEventHandler()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeMixinEventHandler)
  },
  methods: {
    resizeMixinEventHandler () {
      this.window.w = window.innerWidth
      this.window.h = window.innerHeight
    }
  }
}
