// ORGANIZATION CONSTANTS
export const USER_MODEL_INDEX_PENDING = 'USER_MODEL_INDEX_PENDING'
export const USER_MODEL_INDEX_SUCCESS = 'USER_MODEL_INDEX_SUCCESS'
export const USER_MODEL_INDEX_FAILURE = 'USER_MODEL_INDEX_FAILURE'
export const USER_MODEL_INDEX_NO_CONTENT = 'USER_MODEL_INDEX_NO_CONTENT'

export const USER_MODEL_READ_PENDING = 'USER_MODEL_READ_PENDING'
export const USER_MODEL_READ_SUCCESS = 'USER_MODEL_READ_SUCCESS'
export const USER_MODEL_READ_FAILURE = 'USER_MODEL_READ_FAILURE'

export const USER_MODEL_RESET_STORE = 'USER_MODEL_RESET_STORE'
