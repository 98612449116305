// IT GROUP ITEM CONSTANTS
export const IT_GROUP_PROTECTION_UPDATE_OR_CREATE_PENDING = 'IT_GROUP_PROTECTION_UPDATE_OR_CREATE_PENDING'
export const IT_GROUP_PROTECTION_UPDATE_OR_CREATE_SUCCESS = 'IT_GROUP_PROTECTION_UPDATE_OR_CREATE_SUCCESS'
export const IT_GROUP_PROTECTION_UPDATE_OR_CREATE_FAILURE = 'IT_GROUP_PROTECTION_UPDATE_OR_CREATE_FAILURE'

export const IT_GROUP_PROTECTION_DELETE_PENDING = 'IT_GROUP_PROTECTION_DELETE_PENDING'
export const IT_GROUP_PROTECTION_DELETE_SUCCESS = 'IT_GROUP_PROTECTION_DELETE_SUCCESS'
export const IT_GROUP_PROTECTION_DELETE_FAILURE = 'IT_GROUP_PROTECTION_DELETE_FAILURE'

export const IT_GROUP_PROTECTION_RESET_STORE = 'IT_GROUP_PROTECTION_RESET_STORE'
