import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.ARCHIVE_INDEX_PENDING] (state) {
    set(state, 'index', {
      ...state.index,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_INDEX_SUCCESS] (state, payload) {
    set(state, 'index', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_INDEX_FAILURE] (state, payload) {
    set(state, 'index', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_INDEX_NO_CONTENT] (state, payload) {
    set(state, 'index', {
      items: [],
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_TRASHED_PENDING] (state) {
    set(state, 'trashed', {
      ...state.trashed,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_TRASHED_SUCCESS] (state, payload) {
    set(state, 'trashed', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_TRASHED_FAILURE] (state, payload) {
    set(state, 'trashed', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_TRASHED_NO_CONTENT] (state, payload) {
    set(state, 'trashed', {
      items: [],
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_SHOW_PENDING] (state) {
    set(state, 'show', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_SHOW_SUCCESS] (state, payload) {
    set(state, 'show', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_SHOW_FAILURE] (state, payload) {
    set(state, 'show', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_CREATE_PENDING] (state) {
    set(state, 'create', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_CREATE_SUCCESS] (state, payload) {
    set(state, 'create', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_CREATE_FAILURE] (state, payload) {
    set(state, 'create', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_UPDATE_PENDING] (state) {
    set(state, 'update', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_UPDATE_SUCCESS] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_UPDATE_FAILURE] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_DELETE_PENDING] (state) {
    set(state, 'delete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_DELETE_SUCCESS] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_DELETE_FAILURE] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_RESTORE_PENDING] (state) {
    set(state, 'restore', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_RESTORE_SUCCESS] (state, payload) {
    set(state, 'restore', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_RESTORE_FAILURE] (state, payload) {
    set(state, 'restore', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_USERS_PENDING] (state) {
    set(state, 'users', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_USERS_SUCCESS] (state, payload) {
    set(state, 'users', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_USERS_FAILURE] (state, payload) {
    set(state, 'users', {
      loading: false,
      error: payload
    })
  },
  /**
   * @param state
   */
  [C.ARCHIVE_USERS_PENDING] (state) {
    set(state, 'users', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_USERS_SUCCESS] (state, payload) {
    set(state, 'users', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_USERS_FAILURE] (state, payload) {
    set(state, 'users', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_CREATED_BY_PENDING] (state) {
    set(state, 'createdBy', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_CREATED_BY_SUCCESS] (state, payload) {
    set(state, 'createdBy', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_CREATED_BY_FAILURE] (state, payload) {
    set(state, 'createdBy', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_USERS_PENDING] (state) {
    set(state, 'users', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_USERS_SUCCESS] (state, payload) {
    set(state, 'users', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_USERS_FAILURE] (state, payload) {
    set(state, 'users', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_BREADCRUMBS_PENDING] (state) {
    set(state, 'breadcrumbs', {
      ...state.breadcrumbs,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_BREADCRUMBS_SUCCESS] (state, payload) {
    set(state, 'breadcrumbs', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_BREADCRUMBS_FAILURE] (state, payload) {
    set(state, 'breadcrumbs', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_BREADCRUMBS_RESET] (state) {
    set(state, 'breadcrumbs', {
      items: [],
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
