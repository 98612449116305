export default {

  /**
   * @param url
   * @returns {boolean}
   */
  validateUrl (url) {
    const regex = /^(https?:\/\/)?(www\.)?([\w-]+\.\w{2,})(\.\w{2,})*$/
    return regex.test(url)
  },

  /**
   * @returns {boolean}
   * @param min
   * @param val
   */
  validateMin (min, val) {
    return +min <= +val
  },

  /**
   * @returns {boolean}
   * @param max
   * @param val
   */
  validateMax (max, val) {
    return +max >= +val
  },

  /**
   * @param email
   * @returns {boolean}
   */
  validateEmail (email) {
    const regex = /^([a-zA-Z0-9._%-æøåÆØÅ]+@[a-zA-Z0-9.-æøåÆØÅ]+\.[a-zA-Z]{2,})$/
    return regex.test(email)
  }
}
