import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async downloadFile ({ commit }, payload) {
    try {
      commit(C.FILE_DOWNLOAD_PENDING)
      const res = await this.$axios.get(`files/${payload}/download`)
      commit(C.FILE_DOWNLOAD_SUCCESS, res.data)
    } catch (err) {
      commit(C.FILE_DOWNLOAD_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getFile ({ commit }, payload) {
    try {
      commit(C.FILE_SHOW_PENDING)
      const res = await this.$axios.get(`files/${payload}`)
      commit(C.FILE_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.FILE_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createFile ({ commit }, payload) {
    const formData = new FormData()
    formData.append('file', payload)
    try {
      commit(C.FILE_CREATE_PENDING)
      const res = await this.$axios.post('files', formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        }
      })
      commit(C.FILE_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.FILE_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateFile ({ commit }, payload) {
    try {
      commit(C.FILE_UPDATE_PENDING)
      const res = await this.$axios.put(`files/${payload.id}`, payload)
      commit(C.FILE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.FILE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteFile ({ commit }, payload) {
    try {
      commit(C.FILE_DELETE_PENDING)
      const res = await this.$axios.delete(`files/${payload}`)
      commit(C.FILE_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.FILE_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetFileStore ({ commit }) {
    commit(C.FILE_RESET_STORE)
  }
}
