// REPORT CATEGORY CONSTANTS
export const REPORT_CATEGORY_CREATE_PENDING = 'REPORT_CATEGORY_CREATE_PENDING'
export const REPORT_CATEGORY_CREATE_SUCCESS = 'REPORT_CATEGORY_CREATE_SUCCESS'
export const REPORT_CATEGORY_CREATE_FAILURE = 'REPORT_CATEGORY_CREATE_FAILURE'

export const REPORT_CATEGORY_UPDATE_PENDING = 'REPORT_CATEGORY_UPDATE_PENDING'
export const REPORT_CATEGORY_UPDATE_SUCCESS = 'REPORT_CATEGORY_UPDATE_SUCCESS'
export const REPORT_CATEGORY_UPDATE_FAILURE = 'REPORT_CATEGORY_UPDATE_FAILURE'

export const REPORT_CATEGORY_DELETE_PENDING = 'REPORT_CATEGORY_DELETE_PENDING'
export const REPORT_CATEGORY_DELETE_SUCCESS = 'REPORT_CATEGORY_DELETE_SUCCESS'
export const REPORT_CATEGORY_DELETE_FAILURE = 'REPORT_CATEGORY_DELETE_FAILURE'

export const REPORT_CATEGORY_RESTORE_PENDING = 'REPORT_CATEGORY_RESTORE_PENDING'
export const REPORT_CATEGORY_RESTORE_SUCCESS = 'REPORT_CATEGORY_RESTORE_SUCCESS'
export const REPORT_CATEGORY_RESTORE_FAILURE = 'REPORT_CATEGORY_RESTORE_FAILURE'

export const REPORT_CATEGORY_RESET_STORE = 'REPORT_CATEGORY_RESET_STORE'
