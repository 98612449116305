// ORGANIZATION CONSTANTS
export const USER_ORGANIZATION_PENDING = 'USER_ORGANIZATION_PENDING'
export const USER_ORGANIZATION_SUCCESS = 'USER_ORGANIZATION_SUCCESS'
export const USER_ORGANIZATION_FAILURE = 'USER_ORGANIZATION_FAILURE'
export const USER_ORGANIZATION_NO_CONTENT = 'USER_ORGANIZATION_NO_CONTENT'

export const USER_SHOW_PENDING = 'USER_SHOW_PENDING'
export const USER_SHOW_SUCCESS = 'USER_SHOW_SUCCESS'
export const USER_SHOW_FAILURE = 'USER_SHOW_FAILURE'

export const USER_UPDATE_PENDING = 'USER_UPDATE_PENDING'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE'

export const USER_DELETE_PENDING = 'USER_DELETE_PENDING'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE'

export const USER_CREATE_PENDING = 'USER_CREATE_PENDING'
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const USER_CREATE_FAILURE = 'USER_CREATE_FAILURE'

export const USER_RESEND_VERIFICATION_PENDING = 'USER_RESEND_VERIFICATION_PENDING'
export const USER_RESEND_VERIFICATION_SUCCESS = 'USER_RESEND_VERIFICATION_SUCCESS'
export const USER_RESEND_VERIFICATION_FAILURE = 'USER_RESEND_VERIFICATION_FAILURE'
