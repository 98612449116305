// ORGANIZATION CONSTANTS
export const ORGANIZATION_SET = 'ORGANIZATION_SET'

export const ORGANIZATION_INDEX_PENDING = 'ORGANIZATION_INDEX_PENDING'
export const ORGANIZATION_INDEX_SUCCESS = 'ORGANIZATION_INDEX_SUCCESS'
export const ORGANIZATION_INDEX_FAILURE = 'ORGANIZATION_INDEX_FAILURE'
export const ORGANIZATION_INDEX_NO_CONTENT = 'ORGANIZATION_INDEX_NO_CONTENT'

export const ORGANIZATION_CREATE_PENDING = 'ORGANIZATION_CREATE_PENDING'
export const ORGANIZATION_CREATE_SUCCESS = 'ORGANIZATION_CREATE_SUCCESS'
export const ORGANIZATION_CREATE_FAILURE = 'ORGANIZATION_CREATE_FAILURE'

export const ORGANIZATION_UPDATE_PENDING = 'ORGANIZATION_UPDATE_PENDING'
export const ORGANIZATION_UPDATE_SUCCESS = 'ORGANIZATION_UPDATE_SUCCESS'
export const ORGANIZATION_UPDATE_FAILURE = 'ORGANIZATION_UPDATE_FAILURE'

export const ORGANIZATION_DELETE_PENDING = 'ORGANIZATION_DELETE_PENDING'
export const ORGANIZATION_DELETE_SUCCESS = 'ORGANIZATION_DELETE_SUCCESS'
export const ORGANIZATION_DELETE_FAILURE = 'ORGANIZATION_DELETE_FAILURE'

export const ORGANIZATION_USERS_PENDING = 'ORGANIZATION_USERS_PENDING'
export const ORGANIZATION_USERS_SUCCESS = 'ORGANIZATION_USERS_SUCCESS'
export const ORGANIZATION_USERS_FAILURE = 'ORGANIZATION_USERS_FAILURE'
export const ORGANIZATION_USERS_NO_CONTENT = 'ORGANIZATION_USERS_NO_CONTENT'

export const ORGANIZATION_SESSION_PENDING = 'ORGANIZATION_SESSION_PENDING'
export const ORGANIZATION_SESSION_SUCCESS = 'ORGANIZATION_SESSION_SUCCESS'
export const ORGANIZATION_SESSION_FAILURE = 'ORGANIZATION_SESSION_FAILURE'

export const ORGANIZATION_ONBOARDING_PENDING = 'ORGANIZATION_ONBOARDING_PENDING'
export const ORGANIZATION_ONBOARDING_SUCCESS = 'ORGANIZATION_ONBOARDING_SUCCESS'
export const ORGANIZATION_ONBOARDING_FAILURE = 'ORGANIZATION_ONBOARDING_FAILURE'

export const ORGANIZATION_SUBSCRIPTION_PENDING = 'ORGANIZATION_SUBSCRIPTION_PENDING'
export const ORGANIZATION_SUBSCRIPTION_SUCCESS = 'ORGANIZATION_SUBSCRIPTION_SUCCESS'
export const ORGANIZATION_SUBSCRIPTION_FAILURE = 'ORGANIZATION_SUBSCRIPTION_FAILURE'

export const ORGANIZATION_SUBSCRIPTION_STATS_PENDING = 'ORGANIZATION_SUBSCRIPTION_STATS_PENDING'
export const ORGANIZATION_SUBSCRIPTION_STATS_SUCCESS = 'ORGANIZATION_SUBSCRIPTION_STATS_SUCCESS'
export const ORGANIZATION_SUBSCRIPTION_STATS_FAILURE = 'ORGANIZATION_SUBSCRIPTION_STATS_FAILURE'

export const ORGANIZATION_RESET_STORE = 'ORGANIZATION_RESET_STORE'
