import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getTaskIndex ({ commit }, payload) {
    try {
      commit(C.TASK_INDEX_PENDING)
      const res = await this.$axios.get('tasks', { params: payload })
      if (res.status === 200) {
        commit(C.TASK_INDEX_SUCCESS, res.data)
      } else {
        commit(C.TASK_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.TASK_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getTaskCount ({ commit }, payload) {
    try {
      commit(C.TASK_COUNT_PENDING)
      const res = await this.$axios.get('tasks/count', { params: payload })
      commit(C.TASK_COUNT_SUCCESS, res.data)
    } catch (err) {
      commit(C.TASK_COUNT_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getPrioritizedTasksForUser ({ commit }) {
    try {
      commit(C.TASK_PRIORITIZED_PENDING)
      const res = await this.$axios.get('tasks/prioritized')
      if (res.status === 200) {
        commit(C.TASK_PRIORITIZED_SUCCESS, res.data)
      } else {
        commit(C.TASK_PRIORITIZED_NO_CONTENT, res.data)
      }
    } catch (err) {
      commit(C.TASK_PRIORITIZED_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getTask ({ commit }, payload) {
    try {
      commit(C.TASK_SHOW_PENDING)
      const res = await this.$axios.get(`tasks/${payload}`)
      commit(C.TASK_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.TASK_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createTask ({ commit }, payload) {
    try {
      commit(C.TASK_CREATE_PENDING)
      const res = await this.$axios.post('tasks', payload)
      commit(C.TASK_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.TASK_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateTask ({ commit }, payload) {
    try {
      commit(C.TASK_UPDATE_PENDING)
      const res = await this.$axios.put(`tasks/${payload.id}`, payload)
      commit(C.TASK_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.TASK_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async reopenTask ({ commit }, payload) {
    try {
      commit(C.TASK_UPDATE_PENDING)
      const res = await this.$axios.put(`tasks/${payload.id}/reopen`)
      commit(C.TASK_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.TASK_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteTask ({ commit }, payload) {
    try {
      commit(C.TASK_DELETE_PENDING)
      const res = await this.$axios.delete(`tasks/${payload}`)
      commit(C.TASK_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.TASK_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetTaskStore ({ commit }) {
    commit(C.TASK_RESET_STORE)
  }
}
