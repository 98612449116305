import * as C from './constants'

export default {

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getAuditIndex ({ commit }) {
    try {
      commit(C.AUDIT_INDEX_PENDING)
      const res = await this.$axios.get('audits')
      if (res.status === 200) {
        commit(C.AUDIT_INDEX_SUCCESS, res.data)
      } else {
        commit(C.AUDIT_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.AUDIT_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getAuditIndexByReport ({ commit }, payload) {
    try {
      commit(C.AUDIT_REPORT_PENDING)
      const res = await this.$axios.get(`audits/report/${payload}`)
      if (res.status === 200) {
        commit(C.AUDIT_REPORT_SUCCESS, res.data)
      } else {
        commit(C.AUDIT_REPORT_NO_CONTENT)
      }
    } catch (err) {
      commit(C.AUDIT_REPORT_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getUpcomingAudit ({ commit }) {
    try {
      commit(C.AUDIT_UPCOMING_PENDING)
      const res = await this.$axios.get('audits/upcoming')
      commit(C.AUDIT_UPCOMING_SUCCESS, res.data)
    } catch (err) {
      commit(C.AUDIT_UPCOMING_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getAudit ({ commit }, payload) {
    try {
      commit(C.AUDIT_SHOW_PENDING)
      const res = await this.$axios.get(`audits/${payload}`)
      commit(C.AUDIT_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.AUDIT_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createAudit ({ commit }, payload) {
    try {
      commit(C.AUDIT_CREATE_PENDING)
      const res = await this.$axios.post('audits', payload)
      commit(C.AUDIT_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.AUDIT_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateAudit ({ commit }, payload) {
    try {
      commit(C.AUDIT_UPDATE_PENDING)
      const res = await this.$axios.put(`audits/${payload.id}`, payload)
      commit(C.AUDIT_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.AUDIT_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async completeAudit ({ commit }, payload) {
    try {
      commit(C.AUDIT_COMPLETE_PENDING)
      await this.$axios.get(`audits/${payload}/complete`)
      commit(C.AUDIT_COMPLETE_SUCCESS)
    } catch (err) {
      commit(C.AUDIT_COMPLETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteAudit ({ commit }, payload) {
    try {
      commit(C.AUDIT_DELETE_PENDING)
      const res = await this.$axios.delete(`audits/${payload}`)
      commit(C.AUDIT_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.AUDIT_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadAuditFile ({ commit }, payload) {
    try {
      const formData = new FormData()
      formData.append('file', payload.file)
      commit(C.AUDIT_UPLOAD_FILE_PENDING)
      const res = await this.$axios.post(`audits/${payload.id}/upload`, formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        }
      })
      commit(C.AUDIT_UPLOAD_FILE_SUCCESS, res.data)
    } catch (err) {
      commit(C.AUDIT_UPLOAD_FILE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetAuditStore ({ commit }) {
    commit(C.AUDIT_RESET_STORE)
  }
}
