import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.SUBSCRIPTION_KEY_REDEEM_PENDING] (state) {
    set(state, 'redeem', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUBSCRIPTION_KEY_REDEEM_SUCCESS] (state, payload) {
    set(state, 'redeem', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUBSCRIPTION_KEY_REDEEM_FAILURE] (state, payload) {
    set(state, 'redeem', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.SUBSCRIPTION_KEY_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
