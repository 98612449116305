export default () => {
  return {
    show: {
      item: null,
      loading: false,
      error: null
    },
    update: {
      item: null,
      loading: false,
      error: null
    }
  }
}
