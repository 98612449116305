
export default {
  name: 'ErrorHandlingMixin',
  methods: {
    displayErrorMessages (err) {
      if (err.response) {
        if (err.response.status === 422 && err.response.data.errors) {
          // If validation error then display validation error messages
          const errors = err.response.data.errors
          for (const field in errors) {
            if (Object.hasOwnProperty.call(errors, field)) {
              const errorMessages = errors[field]
              errorMessages.forEach((errorMessage) => {
                this.$toast.error(errorMessage)
              })
            }
          }
        } else {
          // If not validation exception then display message from server
          this.$toast.error(err.response.data.message)
        }
      } else {
        // If not a response in the error, display default error message
        this.$toast.error(this.$t('toast.failure'))
      }
    }
  }
}
