import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_CREATE_PENDING] (state) {
    set(state, 'create', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_CREATE_SUCCESS] (state, payload) {
    set(state, 'create', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_CREATE_FAILURE] (state, payload) {
    set(state, 'create', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_UPDATE_PENDING] (state) {
    set(state, 'update', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_UPDATE_SUCCESS] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_UPDATE_FAILURE] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_DELETE_PENDING] (state) {
    set(state, 'delete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_DELETE_SUCCESS] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_DELETE_FAILURE] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_BATCH_PENDING] (state) {
    set(state, 'batch', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_BATCH_SUCCESS] (state, payload) {
    set(state, 'batch', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_BATCH_FAILURE] (state, payload) {
    set(state, 'batch', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_ORDER_PENDING] (state) {
    set(state, 'order', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_ORDER_SUCCESS] (state, payload) {
    set(state, 'order', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_ORDER_FAILURE] (state, payload) {
    set(state, 'order', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_PROCEDURE_APPENDIX_SECTION_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
