// UNIT CONSTANTS
export const UNIT_INDEX_PENDING = 'UNIT_INDEX_PENDING'
export const UNIT_INDEX_SUCCESS = 'UNIT_INDEX_SUCCESS'
export const UNIT_INDEX_FAILURE = 'UNIT_INDEX_FAILURE'
export const UNIT_INDEX_NO_CONTENT = 'UNIT_INDEX_NO_CONTENT'

export const UNIT_LIST_PENDING = 'UNIT_LIST_PENDING'
export const UNIT_LIST_SUCCESS = 'UNIT_LIST_SUCCESS'
export const UNIT_LIST_FAILURE = 'UNIT_LIST_FAILURE'
export const UNIT_LIST_NO_CONTENT = 'UNIT_LIST_NO_CONTENT'

export const UNIT_SHOW_PENDING = 'UNIT_SHOW_PENDING'
export const UNIT_SHOW_SUCCESS = 'UNIT_SHOW_SUCCESS'
export const UNIT_SHOW_FAILURE = 'UNIT_SHOW_FAILURE'

export const UNIT_CREATE_PENDING = 'UNIT_CREATE_PENDING'
export const UNIT_CREATE_SUCCESS = 'UNIT_CREATE_SUCCESS'
export const UNIT_CREATE_FAILURE = 'UNIT_CREATE_FAILURE'

export const UNIT_UPDATE_PENDING = 'UNIT_UPDATE_PENDING'
export const UNIT_UPDATE_SUCCESS = 'UNIT_UPDATE_SUCCESS'
export const UNIT_UPDATE_FAILURE = 'UNIT_UPDATE_FAILURE'

export const UNIT_DELETE_PENDING = 'UNIT_DELETE_PENDING'
export const UNIT_DELETE_SUCCESS = 'UNIT_DELETE_SUCCESS'
export const UNIT_DELETE_FAILURE = 'UNIT_DELETE_FAILURE'

export const UNIT_TASKS_PENDING = 'UNIT_TASKS_PENDING'
export const UNIT_TASKS_SUCCESS = 'UNIT_TASKS_SUCCESS'
export const UNIT_TASKS_FAILURE = 'UNIT_TASKS_FAILURE'
export const UNIT_TASKS_NO_CONTENT = 'UNIT_TASKS_NO_CONTENT'

export const UNIT_RESET_STORE = 'UNIT_RESET_STORE'
