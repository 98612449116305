// APPROVAL CONSTANTS
export const TASK_COMMENT_INDEX_PENDING = 'TASK_COMMENT_INDEX_PENDING'
export const TASK_COMMENT_INDEX_SUCCESS = 'TASK_COMMENT_INDEX_SUCCESS'
export const TASK_COMMENT_INDEX_FAILURE = 'TASK_COMMENT_INDEX_FAILURE'
export const TASK_COMMENT_INDEX_NO_CONTENT = 'TASK_COMMENT_INDEX_NO_CONTENT'

export const TASK_COMMENT_CREATE_PENDING = 'TASK_COMMENT_CREATE_PENDING'
export const TASK_COMMENT_CREATE_SUCCESS = 'TASK_COMMENT_CREATE_SUCCESS'
export const TASK_COMMENT_CREATE_FAILURE = 'TASK_COMMENT_CREATE_FAILURE'

export const TASK_COMMENT_UPDATE_PENDING = 'TASK_COMMENT_UPDATE_PENDING'
export const TASK_COMMENT_UPDATE_SUCCESS = 'TASK_COMMENT_UPDATE_SUCCESS'
export const TASK_COMMENT_UPDATE_FAILURE = 'TASK_COMMENT_UPDATE_FAILURE'

export const TASK_COMMENT_DELETE_PENDING = 'TASK_COMMENT_DELETE_PENDING'
export const TASK_COMMENT_DELETE_SUCCESS = 'TASK_COMMENT_DELETE_SUCCESS'
export const TASK_COMMENT_DELETE_FAILURE = 'TASK_COMMENT_DELETE_FAILURE'

export const TASK_COMMENT_RESET_STORE = 'TASK_COMMENT_RESET_STORE'
