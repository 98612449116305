import * as C from './constants'

export default {

  /**
   * @param commit
   * @param $config
   * @param payload
   * @returns {Promise<void>}
   */
  async register ({ commit }, payload) {
    try {
      commit(C.AUTHENTICATION_REGISTER_PENDING)
      await this.$base.get('/sanctum/csrf-cookie')
      await this.$base.post('/register', payload)
      commit(C.AUTHENTICATION_REGISTER_SUCCESS)
    } catch (err) {
      commit(C.AUTHENTICATION_REGISTER_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async verify ({ commit }, payload) {
    try {
      commit(C.AUTHENTICATION_VERIFY_PENDING)
      await this.$axios.get(`/verification/email/${payload.id}/${payload.hash}?expires=${payload.expires}&signature=${payload.signature}`)
      commit(C.AUTHENTICATION_VERIFY_SUCCESS)
    } catch (err) {
      commit(C.AUTHENTICATION_VERIFY_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async notification ({ commit }, payload) {
    try {
      commit(C.AUTHENTICATION_NOTIFICATION_PENDING)
      await this.$axios.post('/email/verification-notification', payload)
      commit(C.AUTHENTICATION_NOTIFICATION_SUCCESS)
    } catch (err) {
      commit(C.AUTHENTICATION_NOTIFICATION_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async forgotPassword ({ commit }, payload) {
    try {
      commit(C.AUTHENTICATION_FORGOT_PENDING)
      await this.$base.get('/sanctum/csrf-cookie')
      await this.$base.post('/forgot-password', payload)
      commit(C.AUTHENTICATION_FORGOT_SUCCESS)
    } catch (err) {
      commit(C.AUTHENTICATION_FORGOT_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async resetPassword ({ commit }, payload) {
    try {
      commit(C.AUTHENTICATION_RESET_PENDING)
      await this.$axios.post('/reset-password', payload)
      commit(C.AUTHENTICATION_RESET_SUCCESS)
    } catch (err) {
      commit(C.AUTHENTICATION_RESET_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async activate ({ commit }, payload) {
    try {
      commit(C.AUTHENTICATION_ACTIVATE_PENDING)
      await this.$base.get('/sanctum/csrf-cookie')
      await this.$base.post('/activate', payload)
      commit(C.AUTHENTICATION_ACTIVATE_SUCCESS)
    } catch (err) {
      commit(C.AUTHENTICATION_ACTIVATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetAuthenticationStore ({ commit }) {
    commit(C.AUTHENTICATION_RESET_STORE)
  }
}
