// UNIT CONSTANTS
export const UNIT_FILE_INDEX_PENDING = 'UNIT_FILE_INDEX_PENDING'
export const UNIT_FILE_INDEX_SUCCESS = 'UNIT_FILE_INDEX_SUCCESS'
export const UNIT_FILE_INDEX_FAILURE = 'UNIT_FILE_INDEX_FAILURE'
export const UNIT_FILE_INDEX_NO_CONTENT = 'UNIT_FILE_INDEX_NO_CONTENT'

export const UNIT_FILE_CREATE_PENDING = 'UNIT_FILE_CREATE_PENDING'
export const UNIT_FILE_CREATE_SUCCESS = 'UNIT_FILE_CREATE_SUCCESS'
export const UNIT_FILE_CREATE_FAILURE = 'UNIT_FILE_CREATE_FAILURE'

export const UNIT_FILE_DELETE_PENDING = 'UNIT_FILE_DELETE_PENDING'
export const UNIT_FILE_DELETE_SUCCESS = 'UNIT_FILE_DELETE_SUCCESS'
export const UNIT_FILE_DELETE_FAILURE = 'UNIT_FILE_DELETE_FAILURE'

export const UNIT_FILE_RESET_STORE = 'UNIT_FILE_RESET_STORE'
