// PERMISSION CONSTANTS
export const PERMISSION_INDEX_PENDING = 'PERMISSION_INDEX_PENDING'
export const PERMISSION_INDEX_SUCCESS = 'PERMISSION_INDEX_SUCCESS'
export const PERMISSION_INDEX_FAILURE = 'PERMISSION_INDEX_FAILURE'
export const PERMISSION_INDEX_NO_CONTENT = 'PERMISSION_INDEX_NO_CONTENT'

export const PERMISSION_ADD_PENDING = 'PERMISSION_ADD_PENDING'
export const PERMISSION_ADD_SUCCESS = 'PERMISSION_ADD_SUCCESS'
export const PERMISSION_ADD_FAILURE = 'PERMISSION_ADD_FAILURE'

export const PERMISSION_REVOKE_PENDING = 'PERMISSION_REVOKE_PENDING'
export const PERMISSION_REVOKE_SUCCESS = 'PERMISSION_REVOKE_SUCCESS'
export const PERMISSION_REVOKE_FAILURE = 'PERMISSION_REVOKE_FAILURE'

export const PERMISSION_RESET_STORE = 'PERMISSION_RESET_STORE'
