
import { mapActions, mapState } from 'vuex'
import PaginationMixin from '../mixins/paginationMixin'
import errorHandlingMixin from '@/mixins/errorHandlingMixin.vue'
export default {
  name: 'NowNavNotification',
  mixins: [PaginationMixin, errorHandlingMixin],
  data () {
    return {
      items: [],
      loading: false,
      showMenu: false,
      interval: null,
      onlyUnread: false,
      perPage: 20, // Set the default per page to 20
      delay: 10000 // Set default long polling delay to 10 seconds
    }
  },
  computed: {
    ...mapState({
      notificationIndex: state => state.notification.index.item,
      unreadNotificationCount: state => state.notification.unreadCount.item
    }),
    hasMore () {
      if (this.notificationIndex) {
        return this.notificationIndex.current_page < this.notificationIndex.last_page
      }
      return false
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
    this.interval = null
  },
  mounted () {
    this.initialize()
  },
  methods: {
    ...mapActions({
      getNotificationIndex: 'notification/getNotificationIndex',
      getUnreadNotificationCount: 'notification/getUnreadNotificationCount',
      allReadNotifications: 'notification/allReadNotifications'
    }),
    initialize () {
      this.getUnreadNotificationCount()
      this.initializeInterval()
    },
    initializeInterval () {
      this.interval = null
      this.interval = setInterval(() => {
        this.getUnreadNotificationCount()
      }, this.delay)
    },
    toggleMenu () {
      this.showMenu = !this.showMenu
      // Load notifications when open
      if (this.showMenu) {
        // Reset before load
        this.reset()
        this.handleLoad()
      }
    },
    fetch () {
      this.getUnreadNotificationCount()
      this.initializeInterval()
    },
    handleFetch () {
      // If not on last page, then load next page
      if (this.hasMore) {
        this.page++
        this.handleLoad()
      }
    },
    async handleReadAll () {
      try {
        await this.allReadNotifications()
        await this.getNotificationIndex()
        await this.getUnreadNotificationCount()
        this.close()
      } catch (err) {
        this.displayErrorMessages(err)
      }
    },
    async handleLoad () {
      this.loading = true
      await this.getNotificationIndex({ ...this.paginationQuery })
      await this.setPaginationMeta(this.notificationIndex)
      this.items = [...this.items, ...this.notificationIndex.data]
      this.loading = false
    },
    syncQueryParams () {
      // Do nothing
    },
    reset () {
      this.page = 1
      this.items = []
    },
    close () {
      this.showMenu = false
    }
  }
}
