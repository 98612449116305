// CVR CONSTANTS
export const REPORT_SECTION_INDEX_PENDING = 'REPORT_SECTION_INDEX_PENDING'
export const REPORT_SECTION_INDEX_SUCCESS = 'REPORT_SECTION_INDEX_SUCCESS'
export const REPORT_SECTION_INDEX_FAILURE = 'REPORT_SECTION_INDEX_FAILURE'
export const REPORT_SECTION_INDEX_NO_CONTENT = 'REPORT_SECTION_INDEX_NO_CONTENT'

export const REPORT_SECTION_REPORT_TYPE_PENDING = 'REPORT_SECTION_REPORT_TYPE_PENDING'
export const REPORT_SECTION_REPORT_TYPE_SUCCESS = 'REPORT_SECTION_REPORT_TYPE_SUCCESS'
export const REPORT_SECTION_REPORT_TYPE_FAILURE = 'REPORT_SECTION_REPORT_TYPE_FAILURE'
export const REPORT_SECTION_REPORT_TYPE_NO_CONTENT = 'REPORT_SECTION_REPORT_TYPE_NO_CONTENT'

export const REPORT_SECTION_RESET_STORE = 'REPORT_SECTION_RESET_STORE'
