import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createApprovalComment ({ commit }, payload) {
    try {
      commit(C.APPROVAL_COMMENT_CREATE_PENDING)
      const res = await this.$axios.post('approval-comments', payload)
      commit(C.APPROVAL_COMMENT_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.APPROVAL_COMMENT_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateApprovalComment ({ commit }, payload) {
    try {
      commit(C.APPROVAL_COMMENT_UPDATE_PENDING)
      const res = await this.$axios.put(`approval-comments/${payload.id}`, payload)
      commit(C.APPROVAL_COMMENT_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.APPROVAL_COMMENT_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteApprovalComment ({ commit }, payload) {
    try {
      commit(C.APPROVAL_COMMENT_DELETE_PENDING)
      const res = await this.$axios.delete(`approval-comments/${payload}`)
      commit(C.APPROVAL_COMMENT_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.APPROVAL_COMMENT_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetApprovalCommentStore ({ commit }) {
    commit(C.APPROVAL_COMMENT_RESET_STORE)
  }
}
