export default () => {
  return {
    index: {
      items: [],
      loading: false,
      error: null
    },
    user: {
      items: [],
      loading: false,
      error: null
    },
    create: {
      item: null,
      loading: false,
      error: null
    },
    show: {
      item: null,
      loading: false,
      error: null
    },
    update: {
      item: null,
      loading: false,
      error: null
    },
    delete: {
      loading: false,
      error: null
    },
    users: {
      loading: false,
      error: null
    },
    units: {
      loading: false,
      error: null
    }
  }
}
