
export default {
  name: 'NowBadge',
  props: {
    user: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      validator: val => ['small', 'medium', 'large', 'xl'].includes(val),
      default: 'large'
    },
    showName: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    initials () {
      if (this.user && this.user.firstname && this.user.lastname) {
        return `${this.user.firstname.charAt(0).toUpperCase()}${this.user.lastname.charAt(0).toUpperCase()}`
      } else if (this.user && this.user.firstname) {
        return `${this.user.firstname.substring(0, 2).toUpperCase()}`
      }
      return 'NA' // If no name, return NA
    }
  }
}
