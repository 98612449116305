import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.COMPANY_INDEX_PENDING] (state) {
    set(state, 'index', {
      ...state.index,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_INDEX_SUCCESS] (state, payload) {
    set(state, 'index', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_INDEX_FAILURE] (state, payload) {
    set(state, 'index', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_INDEX_NO_CONTENT] (state, payload) {
    set(state, 'index', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.COMPANY_INDEX_FLATTEN_PENDING] (state) {
    set(state, 'flatten', {
      ...state.index,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_INDEX_FLATTEN_SUCCESS] (state, payload) {
    set(state, 'flatten', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_INDEX_FLATTEN_FAILURE] (state, payload) {
    set(state, 'flatten', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_INDEX_FLATTEN_NO_CONTENT] (state, payload) {
    set(state, 'flatten', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.COMPANY_INDEX_FLAT_PENDING] (state) {
    set(state, 'indexFlat', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_INDEX_FLAT_SUCCESS] (state, payload) {
    set(state, 'indexFlat', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_INDEX_FLAT_FAILURE] (state, payload) {
    set(state, 'indexFlat', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_INDEX_FLAT_NO_CONTENT] (state, payload) {
    set(state, 'indexFlat', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.COMPANY_CREATE_PENDING] (state) {
    set(state, 'create', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_CREATE_SUCCESS] (state, payload) {
    set(state, 'create', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_CREATE_FAILURE] (state, payload) {
    set(state, 'create', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.COMPANY_UPDATE_PENDING] (state) {
    set(state, 'update', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_UPDATE_SUCCESS] (state, payload) {
    set(state, 'update', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_UPDATE_FAILURE] (state, payload) {
    set(state, 'update', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.COMPANY_CREATE_CUSTOM_PENDING] (state) {
    set(state, 'create', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_CREATE_CUSTOM_SUCCESS] (state, payload) {
    set(state, 'create', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_CREATE_CUSTOM_FAILURE] (state, payload) {
    set(state, 'create', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.COMPANY_SYNC_PENDING] (state) {
    set(state, 'sync', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.COMPANY_SYNC_SUCCESS] (state) {
    set(state, 'sync', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_SYNC_FAILURE] (state, payload) {
    set(state, 'sync', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.COMPANY_DELETE_PENDING] (state) {
    set(state, 'delete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.COMPANY_DELETE_SUCCESS] (state) {
    set(state, 'delete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.COMPANY_DELETE_FAILURE] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.COMPANY_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
