import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getRoleIndex ({ commit }) {
    try {
      commit(C.ROLE_INDEX_PENDING)
      const res = await this.$axios.get('roles')
      if (res.status === 200 && res.data) {
        commit(C.ROLE_INDEX_SUCCESS, res.data)
      } else {
        commit(C.ROLE_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.ROLE_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetRoleStore ({ commit }) {
    commit(C.ROLE_RESET_STORE)
  }
}
