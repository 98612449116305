import Vue from 'vue'
Vue.filter('address', function (unit, showCity = false) {
  if (!unit) { return '' }
  let address = ''
  address += unit.address.street
  if (unit.address.street_no_from) {
    address += ` ${unit.address.street_no_from}`
    if (unit.address.street_letter_from) {
      address += `${unit.address.street_letter_from}`
    }
  }
  if (unit.address.street_no_to) {
    address += `-${unit.address.street_no_to}`
    if (unit.address.street_letter_to) {
      address += `${unit.address.street_letter_to}`
    }
  }
  if (unit.address.floor) {
    address += `, ${unit.address.floor}`
  }
  if (unit.address.door) {
    address += ` ${unit.address.door}`
  }
  if (showCity) {
    if (unit.address.city.zip && unit.address.city.name) {
      address += `, ${unit.address.city.zip} ${unit.address.city.name}`
    }
  }
  return address
})
