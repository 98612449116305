export default () => {
  return {
    index: {
      items: [],
      loading: false,
      error: null
    },
    create: {
      item: null,
      loading: false,
      error: null
    }
  }
}
