import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.REPORT_TYPE_INDEX_PENDING] (state) {
    set(state, 'index', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TYPE_INDEX_SUCCESS] (state, payload) {
    set(state, 'index', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TYPE_INDEX_FAILURE] (state, payload) {
    set(state, 'index', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TYPE_INDEX_NO_CONTENT] (state, payload) {
    set(state, 'index', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_TYPE_TEMPLATES_PENDING] (state) {
    set(state, 'templates', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TYPE_TEMPLATES_SUCCESS] (state, payload) {
    set(state, 'templates', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TYPE_TEMPLATES_FAILURE] (state, payload) {
    set(state, 'templates', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TYPE_TEMPLATES_NO_CONTENT] (state, payload) {
    set(state, 'templates', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_TYPE_NOTIFICATIONS_PENDING] (state) {
    set(state, 'notifications', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TYPE_NOTIFICATIONS_SUCCESS] (state, payload) {
    set(state, 'notifications', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TYPE_NOTIFICATIONS_FAILURE] (state, payload) {
    set(state, 'notifications', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TYPE_NOTIFICATIONS_NO_CONTENT] (state, payload) {
    set(state, 'notifications', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_TYPE_NOTIFICATIONS_COUNT_PENDING] (state) {
    set(state, 'notificationsUnreadCount', {
      ...state.notificationsUnreadCount,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TYPE_NOTIFICATIONS_COUNT_SUCCESS] (state, payload) {
    set(state, 'notificationsUnreadCount', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TYPE_NOTIFICATIONS_COUNT_FAILURE] (state, payload) {
    set(state, 'notificationsUnreadCount', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_TYPE_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
