import * as C from './constants'

export default {

  /**
   * @param commit
   * @param $config
   * @returns {Promise<void>}
   */
  async getSubscriptionOrganizationByOrganization ({ commit }) {
    try {
      commit(C.SUBSCRIPTION_ORGANIZATION_SHOW_PENDING)
      const res = await this.$axios.get('subscription-organizations/by-organization')
      if (res.status === 200 && res.data) {
        commit(C.SUBSCRIPTION_ORGANIZATION_SHOW_SUCCESS, res.data)
      } else {
        commit(C.SUBSCRIPTION_ORGANIZATION_SHOW_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SUBSCRIPTION_ORGANIZATION_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetSubscriptionOrganizationStore ({ commit }) {
    commit(C.SUBSCRIPTION_ORGANIZATION_RESET_STORE)
  }
}
