
import { mapActions, mapState } from 'vuex'
import subscriptionStates from '@/constants/subscriptionStates'
import intervalTypes from '@/constants/intervalTypes'

export default {
  name: 'NowNavSubscription',
  data () {
    return {
      ready: false,
      subscriptionStates
    }
  },
  computed: {
    ...mapState({
      activeOrganizationSubscription: state => state.organizationSubscription.active.item,
      activeOrganizationSubscriptionNoContent: state => state.organizationSubscription.active.noContent,
      activeOrganizationSubscriptionLoading: state => state.organizationSubscription.active.loading
    }),
    missingSubscription () {
      return this.activeOrganizationSubscriptionNoContent
    },
    trialSubscription () {
      return this.activeOrganizationSubscription?.subscription?.interval === intervalTypes.TRIAL
    },
    closeToExpire () {
      if (this.trialSubscription) {
        const expireDate = this.$moment(this.activeOrganizationSubscription.expires_at)
        const thresholdDate = this.$moment().add(1, 'days')
        return expireDate.isBefore(thresholdDate)
      }
      return false
    },
    isExpired () {
      if (this.trialSubscription) {
        const expireDate = this.$moment(this.activeOrganizationSubscription.expires_at)
        const thresholdDate = this.$moment()
        return expireDate.isBefore(thresholdDate)
      }
      return false
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    ...mapActions({
      getActiveOrganizationSubscription: 'organizationSubscription/getActiveOrganizationSubscription'
    }),
    async initialize () {
      if (!this.activeOrganizationSubscription) {
        await this.getActiveOrganizationSubscription()
      }
      this.ready = true
    }
  }
}
