// ACTION PLAN CONSTANTS
export const AUDIT_INDEX_PENDING = 'AUDIT_INDEX_PENDING'
export const AUDIT_INDEX_SUCCESS = 'AUDIT_INDEX_SUCCESS'
export const AUDIT_INDEX_FAILURE = 'AUDIT_INDEX_FAILURE'
export const AUDIT_INDEX_NO_CONTENT = 'AUDIT_INDEX_NO_CONTENT'

export const AUDIT_REPORT_PENDING = 'AUDIT_REPORT_PENDING'
export const AUDIT_REPORT_SUCCESS = 'AUDIT_REPORT_SUCCESS'
export const AUDIT_REPORT_FAILURE = 'AUDIT_REPORT_FAILURE'
export const AUDIT_REPORT_NO_CONTENT = 'AUDIT_REPORT_NO_CONTENT'

export const AUDIT_UPCOMING_PENDING = 'AUDIT_UPCOMING_PENDING'
export const AUDIT_UPCOMING_SUCCESS = 'AUDIT_UPCOMING_SUCCESS'
export const AUDIT_UPCOMING_FAILURE = 'AUDIT_UPCOMING_FAILURE'

export const AUDIT_SHOW_PENDING = 'AUDIT_SHOW_PENDING'
export const AUDIT_SHOW_SUCCESS = 'AUDIT_SHOW_SUCCESS'
export const AUDIT_SHOW_FAILURE = 'AUDIT_SHOW_FAILURE'

export const AUDIT_CREATE_PENDING = 'AUDIT_CREATE_PENDING'
export const AUDIT_CREATE_SUCCESS = 'AUDIT_CREATE_SUCCESS'
export const AUDIT_CREATE_FAILURE = 'AUDIT_CREATE_FAILURE'

export const AUDIT_UPDATE_PENDING = 'AUDIT_UPDATE_PENDING'
export const AUDIT_UPDATE_SUCCESS = 'AUDIT_UPDATE_SUCCESS'
export const AUDIT_UPDATE_FAILURE = 'AUDIT_UPDATE_FAILURE'

export const AUDIT_COMPLETE_PENDING = 'AUDIT_COMPLETE_PENDING'
export const AUDIT_COMPLETE_SUCCESS = 'AUDIT_COMPLETE_SUCCESS'
export const AUDIT_COMPLETE_FAILURE = 'AUDIT_COMPLETE_FAILURE'

export const AUDIT_DELETE_PENDING = 'AUDIT_DELETE_PENDING'
export const AUDIT_DELETE_SUCCESS = 'AUDIT_DELETE_SUCCESS'
export const AUDIT_DELETE_FAILURE = 'AUDIT_DELETE_FAILURE'

export const AUDIT_UPLOAD_FILE_PENDING = 'AUDIT_UPLOAD_FILE_PENDING'
export const AUDIT_UPLOAD_FILE_SUCCESS = 'AUDIT_UPLOAD_FILE_SUCCESS'
export const AUDIT_UPLOAD_FILE_FAILURE = 'AUDIT_UPLOAD_FILE_FAILURE'

export const AUDIT_RESET_STORE = 'AUDIT_RESET_STORE'
