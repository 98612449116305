// IT GROUP CONSTANTS
export const IT_GROUP_INDEX_PENDING = 'IT_GROUP_INDEX_PENDING'
export const IT_GROUP_INDEX_SUCCESS = 'IT_GROUP_INDEX_SUCCESS'
export const IT_GROUP_INDEX_FAILURE = 'IT_GROUP_INDEX_FAILURE'

export const IT_GROUP_COUNT_PENDING = 'IT_GROUP_COUNT_PENDING'
export const IT_GROUP_COUNT_SUCCESS = 'IT_GROUP_COUNT_SUCCESS'
export const IT_GROUP_COUNT_FAILURE = 'IT_GROUP_COUNT_FAILURE'

export const IT_GROUP_NAMES_PENDING = 'IT_GROUP_NAMES_PENDING'
export const IT_GROUP_NAMES_SUCCESS = 'IT_GROUP_NAMES_SUCCESS'
export const IT_GROUP_NAMES_FAILURE = 'IT_GROUP_NAMES_FAILURE'

export const IT_GROUP_CREATE_PENDING = 'IT_GROUP_CREATE_PENDING'
export const IT_GROUP_CREATE_SUCCESS = 'IT_GROUP_CREATE_SUCCESS'
export const IT_GROUP_CREATE_FAILURE = 'IT_GROUP_CREATE_FAILURE'

export const IT_GROUP_UPDATE_PENDING = 'IT_GROUP_UPDATE_PENDING'
export const IT_GROUP_UPDATE_SUCCESS = 'IT_GROUP_UPDATE_SUCCESS'
export const IT_GROUP_UPDATE_FAILURE = 'IT_GROUP_UPDATE_FAILURE'

export const IT_GROUP_DELETE_PENDING = 'IT_GROUP_DELETE_PENDING'
export const IT_GROUP_DELETE_SUCCESS = 'IT_GROUP_DELETE_SUCCESS'
export const IT_GROUP_DELETE_FAILURE = 'IT_GROUP_DELETE_FAILURE'

export const IT_GROUP_RESET_STORE = 'IT_GROUP_RESET_STORE'
