
import { mapActions, mapState } from 'vuex'

export default {
  name: 'NowSessionModal',
  computed: {
    ...mapState({
      sessionExpired: state => state.app.session.expired
    })
  },
  methods: {
    ...mapActions({
      toggleSessionExpired: 'app/toggleSessionExpired'
    }),
    async goToLogin () {
      await this.$auth.logout()
    },
    close () {
      this.toggleSessionExpired(!this.$auth.loggedIn)
    }
  }
}
