// ACTION PLAN CONSTANTS
export const APPROVAL_COMMENT_CREATE_PENDING = 'APPROVAL_COMMENT_CREATE_PENDING'
export const APPROVAL_COMMENT_CREATE_SUCCESS = 'APPROVAL_COMMENT_CREATE_SUCCESS'
export const APPROVAL_COMMENT_CREATE_FAILURE = 'APPROVAL_COMMENT_CREATE_FAILURE'

export const APPROVAL_COMMENT_UPDATE_PENDING = 'APPROVAL_COMMENT_UPDATE_PENDING'
export const APPROVAL_COMMENT_UPDATE_SUCCESS = 'APPROVAL_COMMENT_UPDATE_SUCCESS'
export const APPROVAL_COMMENT_UPDATE_FAILURE = 'APPROVAL_COMMENT_UPDATE_FAILURE'

export const APPROVAL_COMMENT_DELETE_PENDING = 'APPROVAL_COMMENT_DELETE_PENDING'
export const APPROVAL_COMMENT_DELETE_SUCCESS = 'APPROVAL_COMMENT_DELETE_SUCCESS'
export const APPROVAL_COMMENT_DELETE_FAILURE = 'APPROVAL_COMMENT_DELETE_FAILURE'

export const APPROVAL_COMMENT_RESET_STORE = 'APPROVAL_COMMENT_RESET_STORE'
