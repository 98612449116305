// TWO FACTOR CONSTANTS
export const TWO_FACTOR_ENABLE_PENDING = 'TWO_FACTOR_ENABLE_PENDING'
export const TWO_FACTOR_ENABLE_SUCCESS = 'TWO_FACTOR_ENABLE_SUCCESS'
export const TWO_FACTOR_ENABLE_FAILURE = 'TWO_FACTOR_ENABLE_FAILURE'

export const TWO_FACTOR_DISABLE_PENDING = 'TWO_FACTOR_DISABLE_PENDING'
export const TWO_FACTOR_DISABLE_SUCCESS = 'TWO_FACTOR_DISABLE_SUCCESS'
export const TWO_FACTOR_DISABLE_FAILURE = 'TWO_FACTOR_DISABLE_FAILURE'

export const TWO_FACTOR_CHALLENGE_PENDING = 'TWO_FACTOR_CHALLENGE_PENDING'
export const TWO_FACTOR_CHALLENGE_SUCCESS = 'TWO_FACTOR_CHALLENGE_SUCCESS'
export const TWO_FACTOR_CHALLENGE_FAILURE = 'TWO_FACTOR_CHALLENGE_FAILURE'

export const TWO_FACTOR_RECOVERY_CODES_PENDING = 'TWO_FACTOR_RECOVERY_CODES_PENDING'
export const TWO_FACTOR_RECOVERY_CODES_SUCCESS = 'TWO_FACTOR_RECOVERY_CODES_SUCCESS'
export const TWO_FACTOR_RECOVERY_CODES_FAILURE = 'TWO_FACTOR_RECOVERY_CODES_FAILURE'

export const TWO_FACTOR_RECOVERY_CODES_CREATE_PENDING = 'TWO_FACTOR_RECOVERY_CODES_CREATE_PENDING'
export const TWO_FACTOR_RECOVERY_CODES_CREATE_SUCCESS = 'TWO_FACTOR_RECOVERY_CODES_CREATE_SUCCESS'
export const TWO_FACTOR_RECOVERY_CODES_CREATE_FAILURE = 'TWO_FACTOR_RECOVERY_CODES_CREATE_FAILURE'

export const TWO_FACTOR_QR_CODE_PENDING = 'TWO_FACTOR_QR_CODE_PENDING'
export const TWO_FACTOR_QR_CODE_SUCCESS = 'TWO_FACTOR_QR_CODE_SUCCESS'
export const TWO_FACTOR_QR_CODE_FAILURE = 'TWO_FACTOR_QR_CODE_FAILURE'

export const TWO_FACTOR_PASSWORD_CONFIRMATION_PENDING = 'TWO_FACTOR_PASSWORD_CONFIRMATION_PENDING'
export const TWO_FACTOR_PASSWORD_CONFIRMATION_SUCCESS = 'TWO_FACTOR_PASSWORD_CONFIRMATION_SUCCESS'
export const TWO_FACTOR_PASSWORD_CONFIRMATION_FAILURE = 'TWO_FACTOR_PASSWORD_CONFIRMATION_FAILURE'

export const TWO_FACTOR_CONFIRMATION_PENDING = 'TWO_FACTOR_CONFIRMATION_PENDING'
export const TWO_FACTOR_CONFIRMATION_SUCCESS = 'TWO_FACTOR_CONFIRMATION_SUCCESS'
export const TWO_FACTOR_CONFIRMATION_FAILURE = 'TWO_FACTOR_CONFIRMATION_FAILURE'

export const TWO_FACTOR_PASSWORD_STATUS_PENDING = 'TWO_FACTOR_PASSWORD_STATUS_PENDING'
export const TWO_FACTOR_PASSWORD_STATUS_SUCCESS = 'TWO_FACTOR_PASSWORD_STATUS_SUCCESS'
export const TWO_FACTOR_PASSWORD_STATUS_FAILURE = 'TWO_FACTOR_PASSWORD_STATUS_FAILURE'

export const TWO_FACTOR_RESET_STORE = 'TWO_FACTOR_RESET_STORE'
