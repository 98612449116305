// IT GROUP ITEM CONSTANTS
export const IT_GROUP_ITEM_INDEX_PENDING = 'IT_GROUP_ITEM_INDEX_PENDING'
export const IT_GROUP_ITEM_INDEX_SUCCESS = 'IT_GROUP_ITEM_INDEX_SUCCESS'
export const IT_GROUP_ITEM_INDEX_FAILURE = 'IT_GROUP_ITEM_INDEX_FAILURE'

export const IT_GROUP_ITEM_CREATE_PENDING = 'IT_GROUP_ITEM_CREATE_PENDING'
export const IT_GROUP_ITEM_CREATE_SUCCESS = 'IT_GROUP_ITEM_CREATE_SUCCESS'
export const IT_GROUP_ITEM_CREATE_FAILURE = 'IT_GROUP_ITEM_CREATE_FAILURE'

export const IT_GROUP_ITEM_UPDATE_PENDING = 'IT_GROUP_ITEM_UPDATE_PENDING'
export const IT_GROUP_ITEM_UPDATE_SUCCESS = 'IT_GROUP_ITEM_UPDATE_SUCCESS'
export const IT_GROUP_ITEM_UPDATE_FAILURE = 'IT_GROUP_ITEM_UPDATE_FAILURE'

export const IT_GROUP_ITEM_CONNECTIONS_PENDING = 'IT_GROUP_ITEM_CONNECTIONS_PENDING'
export const IT_GROUP_ITEM_CONNECTIONS_SUCCESS = 'IT_GROUP_ITEM_CONNECTIONS_SUCCESS'
export const IT_GROUP_ITEM_CONNECTIONS_FAILURE = 'IT_GROUP_ITEM_CONNECTIONS_FAILURE'
export const IT_GROUP_ITEM_CONNECTIONS_NO_CONTENT = 'IT_GROUP_ITEM_CONNECTIONS_NO_CONTENT'

export const IT_GROUP_ITEM_CONNECTIONS_UPDATE_PENDING = 'IT_GROUP_ITEM_CONNECTIONS_UPDATE_PENDING'
export const IT_GROUP_ITEM_CONNECTIONS_UPDATE_SUCCESS = 'IT_GROUP_ITEM_CONNECTIONS_UPDATE_SUCCESS'
export const IT_GROUP_ITEM_CONNECTIONS_UPDATE_FAILURE = 'IT_GROUP_ITEM_CONNECTIONS_UPDATE_FAILURE'

export const IT_GROUP_ITEM_CONNECTIONS_DELETE_PENDING = 'IT_GROUP_ITEM_CONNECTIONS_DELETE_PENDING'
export const IT_GROUP_ITEM_CONNECTIONS_DELETE_SUCCESS = 'IT_GROUP_ITEM_CONNECTIONS_DELETE_SUCCESS'
export const IT_GROUP_ITEM_CONNECTIONS_DELETE_FAILURE = 'IT_GROUP_ITEM_CONNECTIONS_DELETE_FAILURE'

export const IT_GROUP_ITEM_DELETE_PENDING = 'IT_GROUP_ITEM_DELETE_PENDING'
export const IT_GROUP_ITEM_DELETE_SUCCESS = 'IT_GROUP_ITEM_DELETE_SUCCESS'
export const IT_GROUP_ITEM_DELETE_FAILURE = 'IT_GROUP_ITEM_DELETE_FAILURE'

export const IT_GROUP_ITEM_RESET_STORE = 'IT_GROUP_ITEM_RESET_STORE'
