import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getSuggestionIndex ({ commit }, payload = { page: 1, 'per-page': 10 }) {
    try {
      commit(C.SUGGESTION_INDEX_PENDING)
      const res = await this.$axios.get('suggestions', { params: payload })
      if (res.status === 200) {
        commit(C.SUGGESTION_INDEX_SUCCESS, res.data)
      } else {
        commit(C.SUGGESTION_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SUGGESTION_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getSuggestionCount ({ commit }, payload) {
    try {
      commit(C.SUGGESTION_COUNT_PENDING)
      const res = await this.$axios.get('suggestions/count', { params: payload })
      commit(C.SUGGESTION_COUNT_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUGGESTION_COUNT_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async addSuggestionTasks ({ commit }, payload) {
    try {
      commit(C.SUGGESTION_UPDATE_PENDING)
      const res = await this.$axios.post(`suggestions/${payload.id}/tasks`, payload)
      commit(C.SUGGESTION_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUGGESTION_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getSuggestionIndexByReport ({ commit }, payload) {
    try {
      commit(C.SUGGESTION_REPORT_PENDING)
      const res = await this.$axios.get(`suggestions/report/${payload}`)
      if (res.status === 200) {
        commit(C.SUGGESTION_REPORT_SUCCESS, res.data)
      } else {
        commit(C.SUGGESTION_REPORT_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SUGGESTION_REPORT_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getSuggestionIndexByUser ({ commit }) {
    try {
      commit(C.SUGGESTION_INDEX_USER_PENDING)
      const res = await this.$axios.get('suggestions/by-user')
      if (res.status === 200) {
        commit(C.SUGGESTION_INDEX_USER_SUCCESS, res.data)
      } else {
        commit(C.SUGGESTION_INDEX_USER_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SUGGESTION_INDEX_USER_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getSuggestion ({ commit }, payload) {
    try {
      commit(C.SUGGESTION_SHOW_PENDING)
      const res = await this.$axios.get(`suggestions/${payload}`)
      commit(C.SUGGESTION_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUGGESTION_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createSuggestion ({ commit }, payload) {
    const formData = new FormData()
    Object.entries(payload.form).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value)
      }
    })
    payload.images.forEach((image) => {
      formData.append('images[]', image)
    })
    try {
      commit(C.SUGGESTION_CREATE_PENDING)
      const res = await this.$axios.post('suggestions', formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        }
      })
      commit(C.SUGGESTION_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUGGESTION_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateSuggestion ({ commit }, payload) {
    try {
      commit(C.SUGGESTION_UPDATE_PENDING)
      const res = await this.$axios.put(`suggestions/${payload.id}`, payload)
      commit(C.SUGGESTION_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUGGESTION_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async completeSuggestion ({ commit }, payload) {
    try {
      commit(C.SUGGESTION_COMPLETE_PENDING)
      const res = await this.$axios.get(`suggestions/${payload}/complete`)
      commit(C.SUGGESTION_COMPLETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUGGESTION_COMPLETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteSuggestion ({ commit }, payload) {
    try {
      commit(C.SUGGESTION_DELETE_PENDING)
      const res = await this.$axios.delete(`suggestions/${payload}`)
      commit(C.SUGGESTION_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUGGESTION_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  resetSuggestionStore ({ commit }) {
    commit(C.SUGGESTION_RESET_STORE)
  }
}
