import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getUnitFileIndex ({ commit }, payload) {
    try {
      commit(C.UNIT_FILE_INDEX_PENDING)
      const res = await this.$axios.get(`units/${payload}/files`)
      if (res.status === 200) {
        commit(C.UNIT_FILE_INDEX_SUCCESS, res.data)
      } else {
        commit(C.UNIT_FILE_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.UNIT_FILE_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createUnitFile ({ commit }, payload) {
    const formData = new FormData()
    formData.append('file', payload.file)
    formData.append('unit_id', payload.unit_id)
    try {
      commit(C.UNIT_FILE_CREATE_PENDING)
      const res = await this.$axios.post(`units/${payload.unit_id}/files`, formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        }
      })
      commit(C.UNIT_FILE_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.UNIT_FILE_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteUnitFile ({ commit }, payload) {
    try {
      commit(C.UNIT_FILE_DELETE_PENDING)
      const res = await this.$axios.delete(`units/${payload.unit_id}/files/${payload.id}`)
      commit(C.UNIT_FILE_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.UNIT_FILE_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  resetUnitFileStore ({ commit }) {
    commit(C.UNIT_FILE_RESET_STORE)
  }
}
