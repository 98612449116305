// PROCEDURE TEMPLATE CONSTANTS
export const PROCEDURE_TEMPLATE_INDEX_PENDING = 'PROCEDURE_TEMPLATE_INDEX_PENDING'
export const PROCEDURE_TEMPLATE_INDEX_SUCCESS = 'PROCEDURE_TEMPLATE_INDEX_SUCCESS'
export const PROCEDURE_TEMPLATE_INDEX_FAILURE = 'PROCEDURE_TEMPLATE_INDEX_FAILURE'
export const PROCEDURE_TEMPLATE_INDEX_NO_CONTENT = 'PROCEDURE_TEMPLATE_INDEX_NO_CONTENT'

export const PROCEDURE_TEMPLATE_SHOW_PENDING = 'PROCEDURE_TEMPLATE_SHOW_PENDING'
export const PROCEDURE_TEMPLATE_SHOW_SUCCESS = 'PROCEDURE_TEMPLATE_SHOW_SUCCESS'
export const PROCEDURE_TEMPLATE_SHOW_FAILURE = 'PROCEDURE_TEMPLATE_SHOW_FAILURE'

export const PROCEDURE_TEMPLATE_RESET_STORE = 'PROCEDURE_TEMPLATE_RESET_STORE'
