// DEVIATION CONSTANTS
export const DEVIATION_INDEX_PENDING = 'DEVIATION_INDEX_PENDING'
export const DEVIATION_INDEX_SUCCESS = 'DEVIATION_INDEX_SUCCESS'
export const DEVIATION_INDEX_FAILURE = 'DEVIATION_INDEX_FAILURE'
export const DEVIATION_INDEX_NO_CONTENT = 'DEVIATION_INDEX_NO_CONTENT'

export const DEVIATION_REPORT_PENDING = 'DEVIATION_REPORT_PENDING'
export const DEVIATION_REPORT_SUCCESS = 'DEVIATION_REPORT_SUCCESS'
export const DEVIATION_REPORT_FAILURE = 'DEVIATION_REPORT_FAILURE'
export const DEVIATION_REPORT_NO_CONTENT = 'DEVIATION_REPORT_NO_CONTENT'

export const DEVIATION_COUNT_PENDING = 'DEVIATION_COUNT_PENDING'
export const DEVIATION_COUNT_SUCCESS = 'DEVIATION_COUNT_SUCCESS'
export const DEVIATION_COUNT_FAILURE = 'DEVIATION_COUNT_FAILURE'

export const DEVIATION_CALENDAR_PENDING = 'DEVIATION_CALENDAR_PENDING'
export const DEVIATION_CALENDAR_SUCCESS = 'DEVIATION_CALENDAR_SUCCESS'
export const DEVIATION_CALENDAR_FAILURE = 'DEVIATION_CALENDAR_FAILURE'

export const DEVIATION_SHOW_PENDING = 'DEVIATION_SHOW_PENDING'
export const DEVIATION_SHOW_SUCCESS = 'DEVIATION_SHOW_SUCCESS'
export const DEVIATION_SHOW_FAILURE = 'DEVIATION_SHOW_FAILURE'

export const DEVIATION_CREATE_PENDING = 'DEVIATION_CREATE_PENDING'
export const DEVIATION_CREATE_SUCCESS = 'DEVIATION_CREATE_SUCCESS'
export const DEVIATION_CREATE_FAILURE = 'DEVIATION_CREATE_FAILURE'

export const DEVIATION_UPDATE_PENDING = 'DEVIATION_UPDATE_PENDING'
export const DEVIATION_UPDATE_SUCCESS = 'DEVIATION_UPDATE_SUCCESS'
export const DEVIATION_UPDATE_FAILURE = 'DEVIATION_UPDATE_FAILURE'

export const DEVIATION_COMPLETE_PENDING = 'DEVIATION_COMPLETE_PENDING'
export const DEVIATION_COMPLETE_SUCCESS = 'DEVIATION_COMPLETE_SUCCESS'
export const DEVIATION_COMPLETE_FAILURE = 'DEVIATION_COMPLETE_FAILURE'

export const DEVIATION_DELETE_PENDING = 'DEVIATION_DELETE_PENDING'
export const DEVIATION_DELETE_SUCCESS = 'DEVIATION_DELETE_SUCCESS'
export const DEVIATION_DELETE_FAILURE = 'DEVIATION_DELETE_FAILURE'

export const DEVIATION_TYPE_INDEX_PENDING = 'DEVIATION_TYPE_INDEX_PENDING'
export const DEVIATION_TYPE_INDEX_SUCCESS = 'DEVIATION_TYPE_INDEX_SUCCESS'
export const DEVIATION_TYPE_INDEX_FAILURE = 'DEVIATION_TYPE_INDEX_FAILURE'
export const DEVIATION_TYPE_INDEX_NO_CONTENT = 'DEVIATION_TYPE_INDEX_NO_CONTENT'

export const DEVIATION_DOWNLOAD_PENDING = 'DEVIATION_DOWNLOAD_PENDING'
export const DEVIATION_DOWNLOAD_SUCCESS = 'DEVIATION_DOWNLOAD_SUCCESS'
export const DEVIATION_DOWNLOAD_FAILURE = 'DEVIATION_DOWNLOAD_FAILURE'
export const DEVIATION_DOWNLOAD_NO_CONTENT = 'DEVIATION_DOWNLOAD_NO_CONTENT'

export const DEVIATION_RESET_STORE = 'DEVIATION_RESET_STORE'
