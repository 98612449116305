export default () => {
  return {
    index: {
      items: [],
      loading: false,
      error: null
    },
    reportType: {
      items: [],
      loading: false,
      error: null
    }
  }
}
