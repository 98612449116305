import * as C from './constants'

export default {

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getCountryIndex ({ commit }) {
    try {
      commit(C.COUNTRY_INDEX_PENDING)
      const res = await this.$axios.get('countries')
      commit(C.COUNTRY_INDEX_SUCCESS, res.data)
    } catch (err) {
      commit(C.COUNTRY_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getPreferredCountryId ({ commit }) {
    try {
      commit(C.COUNTRY_PREFERRED_PENDING)
      const res = await this.$axios.get('countries/preferred')
      commit(C.COUNTRY_PREFERRED_SUCCESS, res.data)
    } catch (err) {
      commit(C.COUNTRY_PREFERRED_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetCountryStore ({ commit }) {
    commit(C.COUNTRY_RESET_STORE)
  }
}
