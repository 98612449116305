// REPORT PROCEDURE APPENDIX CONSTANTS
export const REPORT_PROCEDURE_APPENDIX_INDEX_PENDING = 'REPORT_PROCEDURE_APPENDIX_INDEX_PENDING'
export const REPORT_PROCEDURE_APPENDIX_INDEX_SUCCESS = 'REPORT_PROCEDURE_APPENDIX_INDEX_SUCCESS'
export const REPORT_PROCEDURE_APPENDIX_INDEX_FAILURE = 'REPORT_PROCEDURE_APPENDIX_INDEX_FAILURE'
export const REPORT_PROCEDURE_APPENDIX_INDEX_NO_CONTENT = 'REPORT_PROCEDURE_APPENDIX_INDEX_NO_CONTENT'

export const REPORT_PROCEDURE_APPENDIX_CREATE_PENDING = 'REPORT_PROCEDURE_APPENDIX_CREATE_PENDING'
export const REPORT_PROCEDURE_APPENDIX_CREATE_SUCCESS = 'REPORT_PROCEDURE_APPENDIX_CREATE_SUCCESS'
export const REPORT_PROCEDURE_APPENDIX_CREATE_FAILURE = 'REPORT_PROCEDURE_APPENDIX_CREATE_FAILURE'

export const REPORT_PROCEDURE_APPENDIX_UPDATE_PENDING = 'REPORT_PROCEDURE_APPENDIX_UPDATE_PENDING'
export const REPORT_PROCEDURE_APPENDIX_UPDATE_SUCCESS = 'REPORT_PROCEDURE_APPENDIX_UPDATE_SUCCESS'
export const REPORT_PROCEDURE_APPENDIX_UPDATE_FAILURE = 'REPORT_PROCEDURE_APPENDIX_UPDATE_FAILURE'

export const REPORT_PROCEDURE_APPENDIX_DELETE_PENDING = 'REPORT_PROCEDURE_APPENDIX_DELETE_PENDING'
export const REPORT_PROCEDURE_APPENDIX_DELETE_SUCCESS = 'REPORT_PROCEDURE_APPENDIX_DELETE_SUCCESS'
export const REPORT_PROCEDURE_APPENDIX_DELETE_FAILURE = 'REPORT_PROCEDURE_APPENDIX_DELETE_FAILURE'

export const REPORT_PROCEDURE_APPENDIX_RESET_STORE = 'REPORT_PROCEDURE_APPENDIX_RESET_STORE'
