import * as C from './constants'

export default {

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getRiskLevelIndex ({ commit }) {
    try {
      commit(C.RISK_LEVEL_INDEX_PENDING)
      const res = await this.$axios.get('risk-levels')
      if (res.status === 200) {
        commit(C.RISK_LEVEL_INDEX_SUCCESS, res.data)
      } else {
        commit(C.RISK_LEVEL_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.RISK_LEVEL_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateOrCreateRiskLevel ({ commit }, payload) {
    try {
      commit(C.RISK_LEVEL_UPDATE_PENDING)
      const res = await this.$axios.post('risk-levels', payload)
      commit(C.RISK_LEVEL_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_LEVEL_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetRiskLevelStore ({ commit }) {
    commit(C.RISK_LEVEL_RESET_STORE)
  }
}
