import * as C from './constants'

export default {

  /**
   * @param commit
   * @param $config
   * @returns {Promise<void>}
   */
  async getTableColumnIndex ({ commit }) {
    try {
      commit(C.TABLE_COLUMN_INDEX_PENDING)
      const res = await this.$axios.get('tables/columns')
      if (res.status === 200 && res.data) {
        commit(C.TABLE_COLUMN_INDEX_SUCCESS, res.data)
      } else {
        commit(C.TABLE_COLUMN_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.TABLE_COLUMN_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getTableColumnByName ({ commit }, payload) {
    try {
      commit(C.TABLE_COLUMN_SLUG_PENDING)
      const res = await this.$axios.get(`tables/columns/${payload}`)
      commit(C.TABLE_COLUMN_SLUG_SUCCESS, res.data)
    } catch (err) {
      commit(C.TABLE_COLUMN_SLUG_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createTableColumn ({ commit }, payload) {
    try {
      commit(C.TABLE_COLUMN_CREATE_PENDING)
      const res = await this.$axios.post('tables/columns', payload)
      commit(C.TABLE_COLUMN_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.TABLE_COLUMN_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateTableColumn ({ commit }, payload) {
    try {
      commit(C.TABLE_COLUMN_UPDATE_PENDING)
      const res = await this.$axios.put(`tables/columns/${payload.id}`, payload)
      commit(C.TABLE_COLUMN_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.TABLE_COLUMN_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteTableColumn ({ commit }, payload) {
    try {
      commit(C.TABLE_COLUMN_DELETE_PENDING)
      const res = await this.$axios.delete(`tables/columns/${payload}`)
      commit(C.TABLE_COLUMN_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.TABLE_COLUMN_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateTableColumnSections ({ commit }, payload) {
    try {
      commit(C.TABLE_COLUMN_SECTIONS_PENDING)
      const res = await this.$axios.post(`tables/columns/${payload.id}/sections`, payload)
      commit(C.TABLE_COLUMN_SECTIONS_SUCCESS, res.data)
    } catch (err) {
      commit(C.TABLE_COLUMN_SECTIONS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getTableValueIndex ({ commit }) {
    try {
      commit(C.TABLE_VALUE_INDEX_PENDING)
      const res = await this.$axios.get('tables/values')
      if (res.status === 200 && res.data) {
        commit(C.TABLE_VALUE_INDEX_SUCCESS, res.data)
      } else {
        commit(C.TABLE_VALUE_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.TABLE_VALUE_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createTableValue ({ commit }, payload) {
    try {
      commit(C.TABLE_VALUE_CREATE_PENDING)
      const res = await this.$axios.post('tables/values', payload)
      commit(C.TABLE_VALUE_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.TABLE_VALUE_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getTableColumnTypeIndex ({ commit }) {
    try {
      commit(C.TABLE_COLUMN_TYPE_INDEX_PENDING)
      const res = await this.$axios.get('tables/columns/types')
      commit(C.TABLE_COLUMN_TYPE_INDEX_SUCCESS, res.data)
    } catch (err) {
      commit(C.TABLE_COLUMN_TYPE_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  resetTableStore ({ commit }) {
    commit(C.TABLE_RESET_STORE)
  }
}
