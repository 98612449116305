export default () => {
  return {
    index: {
      items: [],
      loading: false,
      error: null
    },
    unread: {
      items: [],
      loading: false,
      error: null
    },
    create: {
      loading: false,
      error: null
    },
    update: {
      loading: false,
      error: null
    },
    restore: {
      loading: false,
      error: null
    },
    delete: {
      loading: false,
      error: null
    },
    version: {
      loading: false,
      error: null
    },
    showAppendixModal: false
  }
}
