// CVR CONSTANTS
export const CVR_COMPANY_PENDING = 'CVR_COMPANY_PENDING'
export const CVR_COMPANY_SUCCESS = 'CVR_COMPANY_SUCCESS'
export const CVR_COMPANY_FAILURE = 'CVR_COMPANY_FAILURE'
export const CVR_COMPANY_CLEAR = 'CVR_COMPANY_CLEAR'

export const CVR_UNITS_PENDING = 'CVR_UNITS_PENDING'
export const CVR_UNITS_SUCCESS = 'CVR_UNITS_SUCCESS'
export const CVR_UNITS_FAILURE = 'CVR_UNITS_FAILURE'

export const CVR_RESET_STORE = 'CVR_RESET_STORE'
