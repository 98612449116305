import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.IT_GROUP_PROTECTION_UPDATE_OR_CREATE_PENDING] (state) {
    set(state, 'updateOrCreate', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.IT_GROUP_PROTECTION_UPDATE_OR_CREATE_SUCCESS] (state, payload) {
    set(state, 'updateOrCreate', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.IT_GROUP_PROTECTION_UPDATE_OR_CREATE_FAILURE] (state, payload) {
    set(state, 'updateOrCreate', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_PROTECTION_DELETE_PENDING] (state) {
    set(state, 'delete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.IT_GROUP_PROTECTION_DELETE_SUCCESS] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.IT_GROUP_PROTECTION_DELETE_FAILURE] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_PROTECTION_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
