// CVR CONSTANTS
export const REPORT_INDEX_PENDING = 'REPORT_INDEX_PENDING'
export const REPORT_INDEX_SUCCESS = 'REPORT_INDEX_SUCCESS'
export const REPORT_INDEX_FAILURE = 'REPORT_INDEX_FAILURE'
export const REPORT_INDEX_NO_CONTENT = 'REPORT_INDEX_NO_CONTENT'

export const REPORT_PENDING = 'REPORT_PENDING'
export const REPORT_SUCCESS = 'REPORT_SUCCESS'
export const REPORT_FAILURE = 'REPORT_FAILURE'

export const REPORT_CREATE_PENDING = 'REPORT_CREATE_PENDING'
export const REPORT_CREATE_SUCCESS = 'REPORT_CREATE_SUCCESS'
export const REPORT_CREATE_FAILURE = 'REPORT_CREATE_FAILURE'

export const REPORT_UPDATE_PENDING = 'REPORT_UPDATE_PENDING'
export const REPORT_UPDATE_SUCCESS = 'REPORT_UPDATE_SUCCESS'
export const REPORT_UPDATE_FAILURE = 'REPORT_UPDATE_FAILURE'

export const REPORT_DELETE_PENDING = 'REPORT_DELETE_PENDING'
export const REPORT_DELETE_SUCCESS = 'REPORT_DELETE_SUCCESS'
export const REPORT_DELETE_FAILURE = 'REPORT_DELETE_FAILURE'

export const REPORT_LINKS_PENDING = 'REPORT_LINKS_PENDING'
export const REPORT_LINKS_SUCCESS = 'REPORT_LINKS_SUCCESS'
export const REPORT_LINKS_FAILURE = 'REPORT_LINKS_FAILURE'

export const REPORT_LINKS_TRASHED_PENDING = 'REPORT_LINKS_TRASHED_PENDING'
export const REPORT_LINKS_TRASHED_SUCCESS = 'REPORT_LINKS_TRASHED_SUCCESS'
export const REPORT_LINKS_TRASHED_FAILURE = 'REPORT_LINKS_TRASHED_FAILURE'

export const REPORT_COMPANIES_PENDING = 'REPORT_COMPANIES_PENDING'
export const REPORT_COMPANIES_SUCCESS = 'REPORT_COMPANIES_SUCCESS'
export const REPORT_COMPANIES_FAILURE = 'REPORT_COMPANIES_FAILURE'

export const REPORT_PROGRESS_PENDING = 'REPORT_PROGRESS_PENDING'
export const REPORT_PROGRESS_SUCCESS = 'REPORT_PROGRESS_SUCCESS'
export const REPORT_PROGRESS_FAILURE = 'REPORT_PROGRESS_FAILURE'

export const REPORT_PDF_PENDING = 'REPORT_PDF_PENDING'
export const REPORT_PDF_SUCCESS = 'REPORT_PDF_SUCCESS'
export const REPORT_PDF_FAILURE = 'REPORT_PDF_FAILURE'

export const REPORT_RESET_STORE = 'REPORT_RESET_STORE'
