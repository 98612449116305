import * as C from './constants'

export default {

  /**
   * @param commit
   * @param $config
   * @param payload
   * @returns {Promise<void>}
   */
  async searchCompany ({ commit }, payload) {
    try {
      commit(C.CVR_COMPANY_PENDING)
      const res = await this.$axios.post('cvr/search', payload)
      commit(C.CVR_COMPANY_SUCCESS, res.data)
    } catch (err) {
      commit(C.CVR_COMPANY_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async searchUnits ({ commit }, payload) {
    try {
      commit(C.CVR_UNITS_PENDING)
      const res = await this.$axios.post('cvr/unit', {
        pUnitNumbers: payload
      })
      commit(C.CVR_UNITS_SUCCESS, res.data)
    } catch (err) {
      commit(C.CVR_UNITS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  clearCompanySearch ({ commit }) {
    commit(C.CVR_COMPANY_CLEAR)
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  resetCvrStore ({ commit }) {
    commit(C.CVR_RESET_STORE)
  }
}
