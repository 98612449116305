import locale65879303 from '../../locales/da.json'
import locale9ec66352 from '../../locales/en.json'
import locale6c5af57f from '../../locales/de.json'
import localef44f2364 from '../../locales/se.json'
import localee32c8782 from '../../locales/no.json'
import locale5e24acbc from '../../locales/tr.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"da"},
  vueI18nLoader: false,
  locales: [{"code":"da","iso":"da","file":"da.json"},{"code":"en","iso":"en-gb","file":"en.json"},{"code":"de","iso":"de","file":"de.json"},{"code":"se","iso":"se","file":"se.json"},{"code":"no","iso":"no-nb","file":"no.json"},{"code":"tr","iso":"tr","file":"tr.json"}],
  defaultLocale: "da",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: "locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"da","iso":"da","file":"da.json"},{"code":"en","iso":"en-gb","file":"en.json"},{"code":"de","iso":"de","file":"de.json"},{"code":"se","iso":"se","file":"se.json"},{"code":"no","iso":"no-nb","file":"no.json"},{"code":"tr","iso":"tr","file":"tr.json"}],
  localeCodes: ["da","en","de","se","no","tr"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "d",
  3: "a",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "l",
  16: "o",
  17: "c",
  18: "a",
  19: "l",
  20: "e",
  21: "s",
  22: "/",
  23: "d",
  24: "a",
  25: ".",
  26: "j",
  27: "s",
  28: "o",
  29: "n",
  30: "\"",
  31: ",",
  32: "\"",
  33: "e",
  34: "n",
  35: ".",
  36: "j",
  37: "s",
  38: "o",
  39: "n",
  40: "\"",
  41: ":",
  42: "\"",
  43: ".",
  44: ".",
  45: "/",
  46: "l",
  47: "o",
  48: "c",
  49: "a",
  50: "l",
  51: "e",
  52: "s",
  53: "/",
  54: "e",
  55: "n",
  56: ".",
  57: "j",
  58: "s",
  59: "o",
  60: "n",
  61: "\"",
  62: ",",
  63: "\"",
  64: "d",
  65: "e",
  66: ".",
  67: "j",
  68: "s",
  69: "o",
  70: "n",
  71: "\"",
  72: ":",
  73: "\"",
  74: ".",
  75: ".",
  76: "/",
  77: "l",
  78: "o",
  79: "c",
  80: "a",
  81: "l",
  82: "e",
  83: "s",
  84: "/",
  85: "d",
  86: "e",
  87: ".",
  88: "j",
  89: "s",
  90: "o",
  91: "n",
  92: "\"",
  93: ",",
  94: "\"",
  95: "s",
  96: "e",
  97: ".",
  98: "j",
  99: "s",
  100: "o",
  101: "n",
  102: "\"",
  103: ":",
  104: "\"",
  105: ".",
  106: ".",
  107: "/",
  108: "l",
  109: "o",
  110: "c",
  111: "a",
  112: "l",
  113: "e",
  114: "s",
  115: "/",
  116: "s",
  117: "e",
  118: ".",
  119: "j",
  120: "s",
  121: "o",
  122: "n",
  123: "\"",
  124: ",",
  125: "\"",
  126: "n",
  127: "o",
  128: ".",
  129: "j",
  130: "s",
  131: "o",
  132: "n",
  133: "\"",
  134: ":",
  135: "\"",
  136: ".",
  137: ".",
  138: "/",
  139: "l",
  140: "o",
  141: "c",
  142: "a",
  143: "l",
  144: "e",
  145: "s",
  146: "/",
  147: "n",
  148: "o",
  149: ".",
  150: "j",
  151: "s",
  152: "o",
  153: "n",
  154: "\"",
  155: ",",
  156: "\"",
  157: "t",
  158: "r",
  159: ".",
  160: "j",
  161: "s",
  162: "o",
  163: "n",
  164: "\"",
  165: ":",
  166: "\"",
  167: ".",
  168: ".",
  169: "/",
  170: "l",
  171: "o",
  172: "c",
  173: "a",
  174: "l",
  175: "e",
  176: "s",
  177: "/",
  178: "t",
  179: "r",
  180: ".",
  181: "j",
  182: "s",
  183: "o",
  184: "n",
  185: "\"",
  186: "}",
}

export const localeMessages = {
  'da.json': () => Promise.resolve(locale65879303),
  'en.json': () => Promise.resolve(locale9ec66352),
  'de.json': () => Promise.resolve(locale6c5af57f),
  'se.json': () => Promise.resolve(localef44f2364),
  'no.json': () => Promise.resolve(localee32c8782),
  'tr.json': () => Promise.resolve(locale5e24acbc),
}
