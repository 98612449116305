import * as C from './constants'

export default {

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getApprovalIndexByUser ({ commit }) {
    try {
      commit(C.APPROVAL_INDEX_PENDING)
      const res = await this.$axios.get('approvals/user')
      if (res.status === 200) {
        commit(C.APPROVAL_INDEX_SUCCESS, res.data)
      } else {
        commit(C.APPROVAL_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.APPROVAL_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getApproval ({ commit }, payload) {
    try {
      commit(C.APPROVAL_SHOW_PENDING)
      const res = await this.$axios.get(`approvals/${payload}`)
      commit(C.APPROVAL_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.APPROVAL_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getApprovalByModel ({ commit }, payload) {
    try {
      commit(C.APPROVAL_SHOW_PENDING)
      const res = await this.$axios.post('approvals/fetch', payload)
      if (res.status === 200) {
        commit(C.APPROVAL_SHOW_SUCCESS, res.data)
      } else {
        commit(C.APPROVAL_SHOW_NO_CONTENT)
      }
    } catch (err) {
      commit(C.APPROVAL_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createApproval ({ commit }, payload) {
    try {
      commit(C.APPROVAL_CREATE_PENDING)
      const res = await this.$axios.post('approvals', payload)
      commit(C.APPROVAL_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.APPROVAL_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateApproval ({ commit }, payload) {
    try {
      commit(C.APPROVAL_UPDATE_PENDING)
      const res = await this.$axios.put(`approvals/${payload.id}`, payload)
      commit(C.APPROVAL_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.APPROVAL_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteApproval ({ commit }, payload) {
    try {
      commit(C.APPROVAL_DELETE_PENDING)
      const res = await this.$axios.delete(`approvals/${payload}`)
      commit(C.APPROVAL_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.APPROVAL_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateApprovalUserStatus ({ commit }, payload) {
    try {
      commit(C.APPROVAL_STATUS_PENDING)
      const res = await this.$axios.post(`approvals/${payload.id}/user/status`, payload)
      commit(C.APPROVAL_STATUS_SUCCESS, res.data)
    } catch (err) {
      commit(C.APPROVAL_STATUS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateApprovalCEOStatus ({ commit }, payload) {
    try {
      commit(C.APPROVAL_STATUS_PENDING)
      const res = await this.$axios.post(`approvals/${payload.id}/ceo/status`, payload)
      commit(C.APPROVAL_STATUS_SUCCESS, res.data)
    } catch (err) {
      commit(C.APPROVAL_STATUS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetApprovalStore ({ commit }) {
    commit(C.APPROVAL_RESET_STORE)
  }
}
