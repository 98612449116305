import * as C from './constants'

export default {

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getSnippetIndex ({ commit }) {
    try {
      commit(C.SNIPPET_INDEX_PENDING)
      const res = await this.$axios.get('snippets')
      if (res.status === 200) {
        commit(C.SNIPPET_INDEX_SUCCESS, res.data)
      } else {
        commit(C.SNIPPET_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SNIPPET_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getSnippetsCategorized ({ commit }) {
    try {
      commit(C.SNIPPET_CATEGORY_PENDING)
      const res = await this.$axios.get('snippets/categorized')
      if (res.status === 200) {
        commit(C.SNIPPET_CATEGORY_SUCCESS, res.data)
      } else {
        commit(C.SNIPPET_CATEGORY_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SNIPPET_CATEGORY_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getSnippet ({ commit }, payload) {
    try {
      commit(C.SNIPPET_SHOW_PENDING)
      const res = await this.$axios.get(`snippets/${payload}`)
      commit(C.SNIPPET_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.SNIPPET_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createSnippet ({ commit }, payload) {
    try {
      commit(C.SNIPPET_CREATE_PENDING)
      const res = await this.$axios.post('snippets', payload)
      commit(C.SNIPPET_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SNIPPET_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateSnippet ({ commit }, payload) {
    try {
      commit(C.SNIPPET_UPDATE_PENDING)
      const res = await this.$axios.put(`snippets/${payload.id}`, payload)
      commit(C.SNIPPET_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SNIPPET_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteSnippet ({ commit }, payload) {
    try {
      commit(C.SNIPPET_DELETE_PENDING)
      const res = await this.$axios.delete(`snippets/${payload}`)
      commit(C.SNIPPET_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SNIPPET_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetSnippetStore ({ commit }) {
    commit(C.SNIPPET_RESET_STORE)
  }
}
