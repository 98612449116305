import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.REPORT_CATEGORY_CREATE_PENDING] (state) {
    set(state, 'create', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_CATEGORY_CREATE_SUCCESS] (state, payload) {
    set(state, 'create', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_CATEGORY_CREATE_FAILURE] (state, payload) {
    set(state, 'create', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_CATEGORY_UPDATE_PENDING] (state) {
    set(state, 'update', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_CATEGORY_UPDATE_SUCCESS] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_CATEGORY_UPDATE_FAILURE] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_CATEGORY_DELETE_PENDING] (state) {
    set(state, 'delete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_CATEGORY_DELETE_SUCCESS] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_CATEGORY_DELETE_FAILURE] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_CATEGORY_RESTORE_PENDING] (state) {
    set(state, 'restore', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_CATEGORY_RESTORE_SUCCESS] (state, payload) {
    set(state, 'restore', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_CATEGORY_RESTORE_FAILURE] (state, payload) {
    set(state, 'restore', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_CATEGORY_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
