// RISK LEVEL CONSTANTS
export const RISK_LEVEL_INDEX_PENDING = 'RISK_LEVEL_INDEX_PENDING'
export const RISK_LEVEL_INDEX_SUCCESS = 'RISK_LEVEL_INDEX_SUCCESS'
export const RISK_LEVEL_INDEX_FAILURE = 'RISK_LEVEL_INDEX_FAILURE'
export const RISK_LEVEL_INDEX_NO_CONTENT = 'RISK_LEVEL_INDEX_NO_CONTENT'

export const RISK_LEVEL_UPDATE_PENDING = 'RISK_LEVEL_UPDATE_PENDING'
export const RISK_LEVEL_UPDATE_SUCCESS = 'RISK_LEVEL_UPDATE_SUCCESS'
export const RISK_LEVEL_UPDATE_FAILURE = 'RISK_LEVEL_UPDATE_FAILURE'

export const RISK_LEVEL_RESET_STORE = 'RISK_LEVEL_RESET_STORE'
