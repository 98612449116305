export default () => {
  return {
    column: {
      index: {
        items: [],
        loading: false,
        error: null
      },
      name: {
        item: null,
        loading: false,
        error: null
      },
      create: {
        item: null,
        loading: false,
        error: null
      },
      update: {
        item: null,
        loading: false,
        error: null
      },
      delete: {
        loading: false,
        error: null
      },
      type: {
        index: {
          items: [],
          loading: false,
          error: null
        }
      }
    },
    value: {
      index: {
        items: [],
        loading: false,
        error: null
      },
      create: {
        item: null,
        loading: false,
        error: null
      }
    }
  }
}
