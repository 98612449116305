
import { mapState, mapActions } from 'vuex'
import errorHandlingMixin from '@/mixins/errorHandlingMixin.vue'

export default {
  name: 'NowEmailUnverified',
  mixins: [errorHandlingMixin],
  computed: {
    ...mapState({
      show: state => state.app.verifyEmailModal.show,
      loading: state => state.authentication.notification.loading,
      error: state => state.authentication.notification.error
    })
  },
  mounted () {
    this.initialize()
  },
  methods: {
    ...mapActions({
      notification: 'authentication/notification',
      toggleVerifyEmailModal: 'app/toggleVerifyEmailModal'
    }),
    async initialize () {
      await this.toggleVerifyEmailModal(this.$auth.loggedIn && !this.$auth.user.email_verified_at)
    },
    async handleNotification () {
      if (!this.loading) {
        const email = this.$auth.user.email
        try {
          await this.$toast.show(this.$t('toast.emailPending'))
          await this.notification({
            email
          })
          await this.$toast.success(this.$t('toast.emailSuccess'))
        } catch (err) {
          this.displayErrorMessages(err)
        }
      }
    },
    async close () {
      await this.$refs.body.classList.remove('shake')
      await this.$auth.fetchUser()
      if (!this.$auth.user.email_verified_at) {
        await this.$refs.body.classList.add('shake')
      } else {
        await this.toggleVerifyEmailModal(false)
      }
    }
  }
}
