// ARCHIVE FILE CONSTANTS
export const ARCHIVE_FILE_INDEX_PENDING = 'ARCHIVE_FILE_INDEX_PENDING'
export const ARCHIVE_FILE_INDEX_SUCCESS = 'ARCHIVE_FILE_INDEX_SUCCESS'
export const ARCHIVE_FILE_INDEX_FAILURE = 'ARCHIVE_FILE_INDEX_FAILURE'
export const ARCHIVE_FILE_INDEX_NO_CONTENT = 'ARCHIVE_FILE_INDEX_NO_CONTENT'

export const ARCHIVE_FILE_VERSIONS_PENDING = 'ARCHIVE_FILE_VERSIONS_PENDING'
export const ARCHIVE_FILE_VERSIONS_SUCCESS = 'ARCHIVE_FILE_VERSIONS_SUCCESS'
export const ARCHIVE_FILE_VERSIONS_FAILURE = 'ARCHIVE_FILE_VERSIONS_FAILURE'
export const ARCHIVE_FILE_VERSIONS_NO_CONTENT = 'ARCHIVE_FILE_VERSIONS_NO_CONTENT'

export const ARCHIVE_FILE_TRASHED_PENDING = 'ARCHIVE_FILE_TRASHED_PENDING'
export const ARCHIVE_FILE_TRASHED_SUCCESS = 'ARCHIVE_FILE_TRASHED_SUCCESS'
export const ARCHIVE_FILE_TRASHED_FAILURE = 'ARCHIVE_FILE_TRASHED_FAILURE'
export const ARCHIVE_FILE_TRASHED_NO_CONTENT = 'ARCHIVE_FILE_TRASHED_NO_CONTENT'

export const ARCHIVE_FILE_CREATE_PENDING = 'ARCHIVE_FILE_CREATE_PENDING'
export const ARCHIVE_FILE_CREATE_SUCCESS = 'ARCHIVE_FILE_CREATE_SUCCESS'
export const ARCHIVE_FILE_CREATE_FAILURE = 'ARCHIVE_FILE_CREATE_FAILURE'

export const ARCHIVE_FILE_UPDATE_PENDING = 'ARCHIVE_FILE_UPDATE_PENDING'
export const ARCHIVE_FILE_UPDATE_SUCCESS = 'ARCHIVE_FILE_UPDATE_SUCCESS'
export const ARCHIVE_FILE_UPDATE_FAILURE = 'ARCHIVE_FILE_UPDATE_FAILURE'

export const ARCHIVE_FILE_DELETE_PENDING = 'ARCHIVE_FILE_DELETE_PENDING'
export const ARCHIVE_FILE_DELETE_SUCCESS = 'ARCHIVE_FILE_DELETE_SUCCESS'
export const ARCHIVE_FILE_DELETE_FAILURE = 'ARCHIVE_FILE_DELETE_FAILURE'

export const ARCHIVE_FILE_RESTORE_PENDING = 'ARCHIVE_FILE_RESTORE_PENDING'
export const ARCHIVE_FILE_RESTORE_SUCCESS = 'ARCHIVE_FILE_RESTORE_SUCCESS'
export const ARCHIVE_FILE_RESTORE_FAILURE = 'ARCHIVE_FILE_RESTORE_FAILURE'

export const ARCHIVE_FILE_CREATED_BY_PENDING = 'ARCHIVE_FILE_CREATED_BY_PENDING'
export const ARCHIVE_FILE_CREATED_BY_SUCCESS = 'ARCHIVE_FILE_CREATED_BY_SUCCESS'
export const ARCHIVE_FILE_CREATED_BY_FAILURE = 'ARCHIVE_FILE_CREATED_BY_FAILURE'

export const ARCHIVE_FILE_USERS_PENDING = 'ARCHIVE_FILE_USERS_PENDING'
export const ARCHIVE_FILE_USERS_SUCCESS = 'ARCHIVE_FILE_USERS_SUCCESS'
export const ARCHIVE_FILE_USERS_FAILURE = 'ARCHIVE_FILE_USERS_FAILURE'

export const ARCHIVE_FILE_BOOKMARKED_PENDING = 'ARCHIVE_FILE_BOOKMARKED_PENDING'
export const ARCHIVE_FILE_BOOKMARKED_SUCCESS = 'ARCHIVE_FILE_BOOKMARKED_SUCCESS'
export const ARCHIVE_FILE_BOOKMARKED_FAILURE = 'ARCHIVE_FILE_BOOKMARKED_FAILURE'

export const ARCHIVE_FILE_RESET_STORE = 'ARCHIVE_FILE_RESET_STORE'
