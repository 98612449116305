import * as C from './constants'

export default {

  /**
   * @param commit
   * @param $config
   * @returns {Promise<void>}
   */
  async getReportTypeIndex ({ commit }) {
    try {
      commit(C.REPORT_TYPE_INDEX_PENDING)
      const res = await this.$axios.get('report-types')
      if (res.status === 200 && res.data) {
        commit(C.REPORT_TYPE_INDEX_SUCCESS, res.data)
      } else {
        commit(C.REPORT_TYPE_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.REPORT_TYPE_INDEX_FAILURE, err.response.data)
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getReportTypeTemplates ({ commit }, payload) {
    try {
      commit(C.REPORT_TYPE_TEMPLATES_PENDING)
      const res = await this.$axios.get('report-types/templates', { params: payload })
      if (res.status === 200 && res.data) {
        commit(C.REPORT_TYPE_TEMPLATES_SUCCESS, res.data)
      } else {
        commit(C.REPORT_TYPE_TEMPLATES_NO_CONTENT)
      }
    } catch (err) {
      commit(C.REPORT_TYPE_TEMPLATES_FAILURE, err.response.data)
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getReportTypeNotifications ({ commit }, payload) {
    try {
      commit(C.REPORT_TYPE_NOTIFICATIONS_PENDING)
      const res = await this.$axios.get(`report-types/${payload.id}/notifications`, { params: payload })
      if (res.status === 200 && res.data) {
        commit(C.REPORT_TYPE_NOTIFICATIONS_SUCCESS, res.data)
      } else {
        commit(C.REPORT_TYPE_NOTIFICATIONS_NO_CONTENT)
      }
    } catch (err) {
      commit(C.REPORT_TYPE_NOTIFICATIONS_FAILURE, err.response.data)
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getReportTypeNotificationUnreadCount ({ commit }, payload) {
    try {
      commit(C.REPORT_TYPE_NOTIFICATIONS_COUNT_PENDING)
      const res = await this.$axios.get(`report-types/${payload.id}/notification-unread-count`, { params: payload })
      commit(C.REPORT_TYPE_NOTIFICATIONS_COUNT_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_TYPE_NOTIFICATIONS_COUNT_FAILURE, err.response.data)
    }
  },

  /**
   * @param commit
   */
  resetReportTypeStore ({ commit }) {
    commit(C.REPORT_TYPE_RESET_STORE)
  }
}
