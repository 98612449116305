import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getActionPlanIndex ({ commit }, payload) {
    try {
      commit(C.ACTION_PLAN_INDEX_PENDING)
      const res = await this.$axios.get('action-plans', { params: payload })
      commit(C.ACTION_PLAN_INDEX_SUCCESS, res.data)
    } catch (err) {
      commit(C.ACTION_PLAN_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getActionPlanCount ({ commit }, payload) {
    try {
      commit(C.ACTION_PLAN_COUNT_PENDING)
      const res = await this.$axios.get('action-plans/count', { params: payload })
      commit(C.ACTION_PLAN_COUNT_SUCCESS, res.data)
    } catch (err) {
      commit(C.ACTION_PLAN_COUNT_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getActionPlanTemplates ({ commit }) {
    try {
      commit(C.ACTION_PLAN_TEMPLATES_PENDING)
      const res = await this.$axios.get('action-plans/templates')
      if (res.status === 200) {
        commit(C.ACTION_PLAN_TEMPLATES_SUCCESS, res.data)
      } else {
        commit(C.ACTION_PLAN_TEMPLATES_NO_CONTENT)
      }
    } catch (err) {
      commit(C.ACTION_PLAN_TEMPLATES_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getActionPlan ({ commit }, payload) {
    try {
      commit(C.ACTION_PLAN_SHOW_PENDING)
      const res = await this.$axios.get(`action-plans/${payload}`)
      commit(C.ACTION_PLAN_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.ACTION_PLAN_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createActionPlan ({ commit }, payload) {
    try {
      commit(C.ACTION_PLAN_CREATE_PENDING)
      const res = await this.$axios.post('action-plans', payload)
      commit(C.ACTION_PLAN_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ACTION_PLAN_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createActionPlanFromTemplate ({ commit }, payload) {
    try {
      commit(C.ACTION_PLAN_CREATE_PENDING)
      const res = await this.$axios.post('action-plans/templates', payload)
      commit(C.ACTION_PLAN_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ACTION_PLAN_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createTemplateFromActionPlan ({ commit }, payload) {
    try {
      commit(C.ACTION_PLAN_CREATE_PENDING)
      const res = await this.$axios.post(`action-plans/${payload}/template`)
      commit(C.ACTION_PLAN_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ACTION_PLAN_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateActionPlan ({ commit }, payload) {
    try {
      commit(C.ACTION_PLAN_UPDATE_PENDING)
      const res = await this.$axios.put(`action-plans/${payload.id}`, payload)
      commit(C.ACTION_PLAN_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ACTION_PLAN_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateActionPlanSetting ({ commit }, payload) {
    try {
      commit(C.ACTION_PLAN_UPDATE_PENDING)
      const res = await this.$axios.put(`action-plans/${payload.id}/setting`, payload)
      commit(C.ACTION_PLAN_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ACTION_PLAN_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteActionPlan ({ commit }, payload) {
    try {
      commit(C.ACTION_PLAN_DELETE_PENDING)
      const res = await this.$axios.delete(`action-plans/${payload}`)
      commit(C.ACTION_PLAN_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ACTION_PLAN_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async completeActionPlanIndex ({ commit }, payload) {
    try {
      commit(C.ACTION_PLAN_COMPLETE_PENDING)
      const res = await this.$axios.get(`action-plans/${payload}/complete`)
      commit(C.ACTION_PLAN_COMPLETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ACTION_PLAN_COMPLETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetActionPlanStore ({ commit }) {
    commit(C.ACTION_PLAN_RESET_STORE)
  }
}
