// REPORT CONTENT CONSTANTS
export const REPORT_PROCEDURE_CONTENT_SECTION_PENDING = 'REPORT_SECTION_PENDING'
export const REPORT_PROCEDURE_CONTENT_SECTION_SUCCESS = 'REPORT_SECTION_SUCCESS'
export const REPORT_PROCEDURE_CONTENT_SECTION_FAILURE = 'REPORT_SECTION_FAILURE'
export const REPORT_PROCEDURE_CONTENT_SECTION_NO_CONTENT = 'REPORT_SECTION_NO_CONTENT'

export const REPORT_PROCEDURE_CONTENT_SEARCH_PENDING = 'REPORT_SEARCH_PENDING'
export const REPORT_PROCEDURE_CONTENT_SEARCH_SUCCESS = 'REPORT_SEARCH_SUCCESS'
export const REPORT_PROCEDURE_CONTENT_SEARCH_FAILURE = 'REPORT_SEARCH_FAILURE'
export const REPORT_PROCEDURE_CONTENT_SEARCH_NO_CONTENT = 'REPORT_SEARCH_NO_CONTENT'

export const REPORT_PROCEDURE_CONTENT_CREATE_PENDING = 'REPORT_CREATE_PENDING'
export const REPORT_PROCEDURE_CONTENT_CREATE_SUCCESS = 'REPORT_CREATE_SUCCESS'
export const REPORT_PROCEDURE_CONTENT_CREATE_FAILURE = 'REPORT_CREATE_FAILURE'

export const REPORT_PROCEDURE_CONTENT_ASSIGNEE_PENDING = 'REPORT_PROCEDURE_CONTENT_ASSIGNEE_PENDING'
export const REPORT_PROCEDURE_CONTENT_ASSIGNEE_SUCCESS = 'REPORT_PROCEDURE_CONTENT_ASSIGNEE_SUCCESS'
export const REPORT_PROCEDURE_CONTENT_ASSIGNEE_FAILURE = 'REPORT_PROCEDURE_CONTENT_ASSIGNEE_FAILURE'

export const REPORT_PROCEDURE_CONTENT_PUBLISH_PENDING = 'REPORT_PROCEDURE_CONTENT_PUBLISH_PENDING'
export const REPORT_PROCEDURE_CONTENT_PUBLISH_SUCCESS = 'REPORT_PROCEDURE_CONTENT_PUBLISH_SUCCESS'
export const REPORT_PROCEDURE_CONTENT_PUBLISH_FAILURE = 'REPORT_PROCEDURE_CONTENT_PUBLISH_FAILURE'

export const REPORT_PROCEDURE_CONTENT_RESET_STORE = 'REPORT_PROCEDURE_CONTENT_RESET_STORE'
