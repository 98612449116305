// SUGGESTION CONSTANTS
export const SUGGESTION_INDEX_PENDING = 'SUGGESTION_INDEX_PENDING'
export const SUGGESTION_INDEX_SUCCESS = 'SUGGESTION_INDEX_SUCCESS'
export const SUGGESTION_INDEX_FAILURE = 'SUGGESTION_INDEX_FAILURE'
export const SUGGESTION_INDEX_NO_CONTENT = 'SUGGESTION_INDEX_NO_CONTENT'

export const SUGGESTION_REPORT_PENDING = 'SUGGESTION_REPORT_PENDING'
export const SUGGESTION_REPORT_SUCCESS = 'SUGGESTION_REPORT_SUCCESS'
export const SUGGESTION_REPORT_FAILURE = 'SUGGESTION_REPORT_FAILURE'
export const SUGGESTION_REPORT_NO_CONTENT = 'SUGGESTION_REPORT_NO_CONTENT'

export const SUGGESTION_INDEX_USER_PENDING = 'SUGGESTION_INDEX_USER_PENDING'
export const SUGGESTION_INDEX_USER_SUCCESS = 'SUGGESTION_INDEX_USER_SUCCESS'
export const SUGGESTION_INDEX_USER_FAILURE = 'SUGGESTION_INDEX_USER_FAILURE'
export const SUGGESTION_INDEX_USER_NO_CONTENT = 'SUGGESTION_INDEX_USER_NO_CONTENT'

export const SUGGESTION_COUNT_PENDING = 'SUGGESTION_COUNT_PENDING'
export const SUGGESTION_COUNT_SUCCESS = 'SUGGESTION_COUNT_SUCCESS'
export const SUGGESTION_COUNT_FAILURE = 'SUGGESTION_COUNT_FAILURE'

export const SUGGESTION_SHOW_PENDING = 'SUGGESTION_SHOW_PENDING'
export const SUGGESTION_SHOW_SUCCESS = 'SUGGESTION_SHOW_SUCCESS'
export const SUGGESTION_SHOW_FAILURE = 'SUGGESTION_SHOW_FAILURE'

export const SUGGESTION_CREATE_PENDING = 'SUGGESTION_CREATE_PENDING'
export const SUGGESTION_CREATE_SUCCESS = 'SUGGESTION_CREATE_SUCCESS'
export const SUGGESTION_CREATE_FAILURE = 'SUGGESTION_CREATE_FAILURE'

export const SUGGESTION_UPDATE_PENDING = 'SUGGESTION_UPDATE_PENDING'
export const SUGGESTION_UPDATE_SUCCESS = 'SUGGESTION_UPDATE_SUCCESS'
export const SUGGESTION_UPDATE_FAILURE = 'SUGGESTION_UPDATE_FAILURE'

export const SUGGESTION_COMPLETE_PENDING = 'SUGGESTION_COMPLETE_PENDING'
export const SUGGESTION_COMPLETE_SUCCESS = 'SUGGESTION_COMPLETE_SUCCESS'
export const SUGGESTION_COMPLETE_FAILURE = 'SUGGESTION_COMPLETE_FAILURE'

export const SUGGESTION_DELETE_PENDING = 'SUGGESTION_DELETE_PENDING'
export const SUGGESTION_DELETE_SUCCESS = 'SUGGESTION_DELETE_SUCCESS'
export const SUGGESTION_DELETE_FAILURE = 'SUGGESTION_DELETE_FAILURE'

export const SUGGESTION_TYPE_INDEX_PENDING = 'SUGGESTION_TYPE_INDEX_PENDING'
export const SUGGESTION_TYPE_INDEX_SUCCESS = 'SUGGESTION_TYPE_INDEX_SUCCESS'
export const SUGGESTION_TYPE_INDEX_FAILURE = 'SUGGESTION_TYPE_INDEX_FAILURE'
export const SUGGESTION_TYPE_INDEX_NO_CONTENT = 'SUGGESTION_TYPE_INDEX_NO_CONTENT'

export const SUGGESTION_RESET_STORE = 'SUGGESTION_RESET_STORE'
