// APPROVAL CONSTANTS
export const TASK_FILE_INDEX_PENDING = 'TASK_FILE_INDEX_PENDING'
export const TASK_FILE_INDEX_SUCCESS = 'TASK_FILE_INDEX_SUCCESS'
export const TASK_FILE_INDEX_FAILURE = 'TASK_FILE_INDEX_FAILURE'
export const TASK_FILE_INDEX_NO_CONTENT = 'TASK_FILE_INDEX_NO_CONTENT'

export const TASK_FILE_CREATE_PENDING = 'TASK_FILE_CREATE_PENDING'
export const TASK_FILE_CREATE_SUCCESS = 'TASK_FILE_CREATE_SUCCESS'
export const TASK_FILE_CREATE_FAILURE = 'TASK_FILE_CREATE_FAILURE'

export const TASK_FILE_DELETE_PENDING = 'TASK_FILE_DELETE_PENDING'
export const TASK_FILE_DELETE_SUCCESS = 'TASK_FILE_DELETE_SUCCESS'
export const TASK_FILE_DELETE_FAILURE = 'TASK_FILE_DELETE_FAILURE'

export const TASK_FILE_RESET_STORE = 'TASK_FILE_RESET_STORE'
