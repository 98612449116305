export default () => {
  return {
    index: {
      items: [],
      loading: false,
      error: null
    },
    type: {
      items: [],
      loading: false,
      error: null
    },
    sectionType: {
      items: [],
      loading: false,
      error: null
    },
    id: {
      item: null,
      loading: false,
      error: null
    },
    section: {
      item: null,
      loading: false,
      error: null
    },
    update: {
      item: null,
      loading: false,
      error: null
    },
    delete: {
      loading: false,
      error: null
    }
  }
}
