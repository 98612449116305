import { render, staticRenderFns } from "./NowNavSubscription.vue?vue&type=template&id=16295ed2&"
import script from "./NowNavSubscription.vue?vue&type=script&lang=js&"
export * from "./NowNavSubscription.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NowSkeletonLoader: require('/home/forge/app.tavasta.com/releases/20241213121941/components/NowSkeletonLoader.vue').default,NowIcon: require('/home/forge/app.tavasta.com/releases/20241213121941/components/NowIcon.vue').default})
