import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async createReportCategory ({ commit }, payload) {
    try {
      commit(C.REPORT_CATEGORY_CREATE_PENDING)
      const res = await this.$axios.post('report-categories', payload)
      commit(C.REPORT_CATEGORY_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_CATEGORY_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async updateReportCategory ({ commit }, payload) {
    try {
      commit(C.REPORT_CATEGORY_UPDATE_PENDING)
      const res = await this.$axios.put(`report-categories/${payload.id}`, payload)
      commit(C.REPORT_CATEGORY_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_CATEGORY_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async deleteReportCategory ({ commit }, payload) {
    try {
      commit(C.REPORT_CATEGORY_DELETE_PENDING)
      const res = await this.$axios.delete(`report-categories/${payload.id}`)
      commit(C.REPORT_CATEGORY_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_CATEGORY_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async restoreReportCategory ({ commit }, payload) {
    try {
      commit(C.REPORT_CATEGORY_RESTORE_PENDING)
      const res = await this.$axios.get(`report-categories/${payload.id}/restore`)
      commit(C.REPORT_CATEGORY_RESTORE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_CATEGORY_RESTORE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetReportCategoryStore ({ commit }) {
    commit(C.REPORT_CATEGORY_RESET_STORE)
  }
}
