import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async googleVisionAnnotate ({ commit }, payload) {
    const formData = new FormData()
    formData.append('image', payload)
    try {
      commit(C.GOOGLE_VISION_PENDING)
      const res = await this.$axios.post('google/vision', formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        }
      })
      commit(C.GOOGLE_VISION_SUCCESS, res.data)
    } catch (err) {
      commit(C.GOOGLE_VISION_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetGoogleStore ({ commit }) {
    commit(C.GOOGLE_RESET_STORE)
  }
}
