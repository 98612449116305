// APPROVAL CONSTANTS
export const TASK_INDEX_PENDING = 'TASK_INDEX_PENDING'
export const TASK_INDEX_SUCCESS = 'TASK_INDEX_SUCCESS'
export const TASK_INDEX_FAILURE = 'TASK_INDEX_FAILURE'
export const TASK_INDEX_NO_CONTENT = 'TASK_INDEX_NO_CONTENT'

export const TASK_PRIORITIZED_PENDING = 'TASK_PRIORITIZED_PENDING'
export const TASK_PRIORITIZED_SUCCESS = 'TASK_PRIORITIZED_SUCCESS'
export const TASK_PRIORITIZED_FAILURE = 'TASK_PRIORITIZED_FAILURE'
export const TASK_PRIORITIZED_NO_CONTENT = 'TASK_PRIORITIZED_NO_CONTENT'

export const TASK_COUNT_PENDING = 'TASK_COUNT_PENDING'
export const TASK_COUNT_SUCCESS = 'TASK_COUNT_SUCCESS'
export const TASK_COUNT_FAILURE = 'TASK_COUNT_FAILURE'

export const TASK_SHOW_PENDING = 'TASK_SHOW_PENDING'
export const TASK_SHOW_SUCCESS = 'TASK_SHOW_SUCCESS'
export const TASK_SHOW_FAILURE = 'TASK_SHOW_FAILURE'

export const TASK_CREATE_PENDING = 'TASK_CREATE_PENDING'
export const TASK_CREATE_SUCCESS = 'TASK_CREATE_SUCCESS'
export const TASK_CREATE_FAILURE = 'TASK_CREATE_FAILURE'

export const TASK_UPDATE_PENDING = 'TASK_UPDATE_PENDING'
export const TASK_UPDATE_SUCCESS = 'TASK_UPDATE_SUCCESS'
export const TASK_UPDATE_FAILURE = 'TASK_UPDATE_FAILURE'

export const TASK_DELETE_PENDING = 'TASK_DELETE_PENDING'
export const TASK_DELETE_SUCCESS = 'TASK_DELETE_SUCCESS'
export const TASK_DELETE_FAILURE = 'TASK_DELETE_FAILURE'

export const TASK_RESET_STORE = 'TASK_RESET_STORE'
