
export default {
  name: 'NowBreadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      blacklist: ['a', 'procedure']
    }
  },
  computed: {
    breadcrumbLinks () {
      if (this.breadcrumbs.length) {
        return this.breadcrumbs
      } else {
        let paths = []
        const path = this.$route.path
        const pathArr = path.split('/')
        pathArr.forEach((path, index) => {
          if (index === 0) {
            paths.push({ label: 'home', to: '/' })
          } else if (path) {
            paths.push({ label: path, to: this.breadcrumbLink(path, index, pathArr) })
          }
        })
        const params = this.$route.params
        const paramKeys = Object.keys(params)
        const paramValues = Object.values(params)
        paths = paths.map((path) => {
          const paramIndex = paramValues.indexOf(path.label)
          if (paramIndex !== -1) {
            return { ...path, label: paramKeys[paramIndex] }
          }
          return path
        })
        paths = paths.filter(path => !this.blacklist.includes(path.label))
        return paths
      }
    },
    mobileBackLink () {
      if (this.breadcrumbLinks.length > 1) {
        return this.breadcrumbLinks[this.breadcrumbLinks.length - 2]
      } else {
        return { label: 'home', to: '/' }
      }
    }
  },
  methods: {
    breadcrumbLink (link, index, links) {
      let path = '/'
      for (let i = 0; i < index; i++) {
        path += `${links[i + 1]}/`
      }
      return path
    }
  }
}
