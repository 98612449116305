import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getUserIndex ({ commit }, payload = { page: 1, 'per-page': 10 }) {
    try {
      commit(C.USER_ORGANIZATION_PENDING)
      const res = await this.$axios.get('users', { params: payload })
      if (res.status === 200) {
        commit(C.USER_ORGANIZATION_SUCCESS, res.data)
      } else {
        commit(C.USER_ORGANIZATION_NO_CONTENT, res.data)
      }
    } catch (err) {
      commit(C.USER_ORGANIZATION_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getUser ({ commit }, payload) {
    try {
      commit(C.USER_SHOW_PENDING)
      const res = await this.$axios.get(`users/${payload}`)
      commit(C.USER_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.USER_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async updateUser ({ commit }, payload) {
    try {
      commit(C.USER_UPDATE_PENDING)
      const res = await this.$axios.put('users', payload)
      commit(C.USER_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.USER_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async updateUserPassword ({ commit }, payload) {
    try {
      commit(C.USER_UPDATE_PENDING)
      const res = await this.$axios.put('users/password', payload)
      commit(C.USER_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.USER_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async updateUserCompanyUnit ({ commit }, payload) {
    try {
      commit(C.USER_UPDATE_PENDING)
      const res = await this.$axios.put('users/company-unit', payload)
      commit(C.USER_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.USER_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async updateUserOrganization ({ commit, dispatch }, payload) {
    try {
      commit(C.USER_UPDATE_PENDING)
      const res = await this.$axios.put('users/set-organization', payload)
      commit(C.USER_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.USER_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async updateUserOrganizationGroup ({ commit }, payload) {
    try {
      commit(C.USER_UPDATE_PENDING)
      const res = await this.$axios.put('users/set-organization-group', payload)
      commit(C.USER_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.USER_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async updateUserLocale ({ commit }, payload) {
    try {
      commit(C.USER_UPDATE_PENDING)
      const res = await this.$axios.put('users/set-locale', payload)
      commit(C.USER_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.USER_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param $config
   * @returns {Promise<void>}
   */
  async deleteUser ({ commit }) {
    try {
      commit(C.USER_DELETE_PENDING)
      const res = await this.$axios.delete('users')
      commit(C.USER_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.USER_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createUserForOrganization ({ commit }, payload) {
    try {
      commit(C.USER_CREATE_PENDING)
      const res = await this.$axios.post('users/organization', payload)
      commit(C.USER_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.USER_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createUsersForOrganizationByFile ({ commit }, payload) {
    const formData = new FormData()
    formData.append('file', payload.file)
    formData.append('role_id', payload.role_id)
    payload.company_unit_ids.forEach((id) => {
      formData.append('company_unit_ids[]', id)
    })
    if (payload.job_title_id) {
      formData.append('job_title_id', payload.job_title_id)
    }
    try {
      commit(C.USER_CREATE_PENDING)
      const res = await this.$axios.post('users/organization/from-file', formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        }
      })
      commit(C.USER_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.USER_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateUserForOrganization ({ commit }, payload) {
    try {
      commit(C.USER_UPDATE_PENDING)
      const res = await this.$axios.put('users/organization', payload)
      commit(C.USER_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.USER_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateCompanyUnits ({ commit }, payload) {
    try {
      commit(C.USER_UPDATE_PENDING)
      const res = await this.$axios.put('users/organization/units', payload)
      commit(C.USER_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.USER_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteUserForOrganization ({ commit }, payload) {
    try {
      commit(C.USER_DELETE_PENDING)
      const res = await this.$axios.post('users/organization/delete', payload)
      commit(C.USER_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.USER_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async resendActivation ({ commit }, payload) {
    try {
      commit(C.USER_RESEND_VERIFICATION_PENDING)
      const res = await this.$axios.post('users/organization/resend-activation', payload)
      commit(C.USER_RESEND_VERIFICATION_SUCCESS, res.data)
    } catch (err) {
      commit(C.USER_RESEND_VERIFICATION_FAILURE, err.response.data)
      throw err
    }
  }
}
