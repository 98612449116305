
export default {
  name: 'Organization',
  middleware: 'adminOnly',
  data () {
    return {
      toggled: false
    }
  },
  methods: {
    toggle (value) {
      if (typeof value === 'boolean') {
        this.toggled = value
      } else {
        this.toggled = !this.toggled
      }
    }
  }
}
