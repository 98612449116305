
import { mapActions } from 'vuex'
import notificationTypes from '../constants/notificationTypes'
import notificationModels from '../constants/notificationModels'
import ErrorHandlingMixin from '../mixins/errorHandlingMixin'

export default {
  name: 'NowNavNotificationMenuItem',
  mixins: [ErrorHandlingMixin],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      hovered: false,
      notificationTypes,
      notificationModels
    }
  },
  computed: {
    title () {
      if (this.item.model === notificationModels.TASK) {
        return this.$t(`notification.types.task.${this.item.type}`, { name: this.item.name })
      } else if (this.item.model === notificationModels.DEVIATION) {
        return this.$t(`notification.types.deviation.${this.item.type}`, { name: this.item.name })
      } else if (this.item.model === notificationModels.APPROVAL) {
        return this.$t(`notification.types.approval.${this.item.type}`, { name: this.item.name })
      } else if (this.item.model === notificationModels.TASK_COMMENT) {
        return this.$t(`notification.types.taskComment.${this.item.type}`, { name: this.item.name })
      } else if (this.item.model === notificationModels.APPROVAL_COMMENT) {
        return this.$t('notification.types.approval.comment', { name: this.item.name })
      }
      return null
    }
  },
  methods: {
    ...mapActions({
      updateReadNotifications: 'notification/updateReadNotifications',
      getNotificationIndex: 'notification/getNotificationIndex',
      getUnreadNotificationCount: 'notification/getUnreadNotificationCount'
    }),
    async handleReadNotification (event) {
      if (!this.item.read_at) {
        try {
          await Promise.all([
            this.updateReadNotifications(this.item.id),
            this.getUnreadNotificationCount(),
            this.getNotificationIndex()
          ])
        } catch (err) {
          this.displayErrorMessages(err)
        }
      }
      // If link on notification then navigate to link
      if (this.item.link) {
        await this.$router.push(this.item.link)
        await this.$emit('close', event)
      }
    }
  }
}
