import * as C from './constants'

export default {

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getUnreadReportProcedures ({ commit }) {
    try {
      commit(C.REPORT_PROCEDURE_UNREAD_PENDING)
      const res = await this.$axios.get('report-procedures/unread')
      if (res.status === 200 && res.data) {
        commit(C.REPORT_PROCEDURE_UNREAD_SUCCESS, res.data)
      } else {
        commit(C.REPORT_PROCEDURE_UNREAD_NO_CONTENT)
      }
    } catch (err) {
      commit(C.REPORT_PROCEDURE_UNREAD_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getReportProcedureIndex ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_INDEX_PENDING)
      const res = await this.$axios.get(`reports/${payload}/procedures`)
      if (res.status === 200) {
        commit(C.REPORT_PROCEDURE_INDEX_SUCCESS, res.data)
      } else {
        commit(C.REPORT_PROCEDURE_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.REPORT_PROCEDURE_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createReportProcedure ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_CREATE_PENDING)
      const res = await this.$axios.post('report-procedures', payload)
      commit(C.REPORT_PROCEDURE_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateReportProcedure ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_UPDATE_PENDING)
      const res = await this.$axios.put(`report-procedures/${payload.id}`, payload)
      commit(C.REPORT_PROCEDURE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteReportProcedure ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_DELETE_PENDING)
      const res = await this.$axios.delete(`report-procedures/${payload.id}`)
      commit(C.REPORT_PROCEDURE_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async restoreReportProcedure ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_RESTORE_PENDING)
      const res = await this.$axios.get(`report-procedures/${payload.id}/restore`)
      commit(C.REPORT_PROCEDURE_RESTORE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_RESTORE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createReportProcedureFile ({ commit }, payload) {
    const formData = new FormData()
    formData.append('file', payload.file)
    formData.append('report_id', payload.report_id)
    try {
      commit(C.REPORT_PROCEDURE_CREATE_PENDING)
      const res = await this.$axios.post(`reports/${payload.report_id}/files`, formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        }
      })
      commit(C.REPORT_PROCEDURE_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteReportProcedureFile ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_DELETE_PENDING)
      const res = await this.$axios.delete(`reports/${payload.deviation_id}/files/${payload.id}`)
      commit(C.REPORT_PROCEDURE_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async versioningReportProcedure ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_VERSION_PENDING)
      const res = await this.$axios.put(`report-procedures/${payload}/versioning`)
      commit(C.REPORT_PROCEDURE_VERSION_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_VERSION_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  toggleShowReportProcedureAppendixModal ({ commit }, payload) {
    commit(C.REPORT_PROCEDURE_APPENDIX_MODAL_TOGGLE, payload)
  },

  /**
   * @param commit
   */
  resetReportProcedureStore ({ commit }) {
    commit(C.REPORT_PROCEDURE_RESET_STORE)
  }
}
