
import { mapActions } from 'vuex'
import breakpoints from '@/constants/breakpoints'
import resizeMixin from '@/mixins/resizeMixin.vue'
import roleTypes from '@/constants/roleTypes'

export default {
  name: 'NowAccountSidebar',
  mixins: [resizeMixin],
  props: {
    toggled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isDeleting: false,
      breakpoints,
      roleTypes
    }
  },
  computed: {
    isMobile () {
      return this.window.w < breakpoints.MD
    },
    isAdmin () {
      return this.$auth.user.role === roleTypes.ADMIN
    },
    showOrganizations () {
      return this.isAdmin || this.$auth.user.organizations.length > 1
    }
  },
  watch: {
    '$route.path' () {
      this.$emit('toggle', false)
    },
    isMobile () {
      this.$emit('toggle', false)
    }
  },
  methods: {
    ...mapActions({
      deleteUser: 'user/deleteUser'
    }),
    async handleDelete () {
      if (this.isDeleting) {
        try {
          await this.$toast.show(this.$t('toast.accountDeletePending'))
          await this.deleteUser()
          await this.$toast.success(this.$t('toast.accountDeleteSuccess'))
          await this.$auth.logout()
        } catch (err) {
          await this.$toast.error(this.$t('toast.accountDeleteFailure'))
        }
      } else {
        this.isDeleting = true
      }
    },
    toggle () {
      this.$emit('toggle')
    }
  }
}
