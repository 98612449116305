export default () => {
  return {
    index: {
      item: null,
      loading: false,
      error: null
    },
    organization: {
      item: null,
      loading: false,
      error: null
    },
    latest: {
      item: null,
      loading: false,
      error: null
    }
  }
}
