
export default {
  name: 'Error',
  props: {
    error: {
      type: Object,
      default: null
    }
  }
}
