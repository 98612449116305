export default {

  /**
   * @param state
   * @returns {null}
   */
  mappedCvrCompanies (state) {
    const hits = state.company.item.hits?.hits
    if (hits) {
      return hits.map((hit) => {
        const source = hit._source.Vrvirksomhed
        return {
          name: source.navne[source.navne.length - 1]?.navn || '',
          identifier: source.cvrNummer,
          phone_one: source.telefonNummer[source.telefonNummer.length - 1]?.kontaktoplysning,
          email: source.elektroniskPost[source.elektroniskPost.length - 1]?.kontaktoplysning,
          website_url: source.hjemmeside[source.hjemmeside.length - 1]?.kontaktoplysning,
          status: source.virksomhedsstatus[source.virksomhedsstatus.length - 1]?.status,
          lifecycle: source.livsforloeb[source.livsforloeb.length - 1]?.periode,
          units: source.penheder,
          address: {
            street: source.beliggenhedsadresse[source.beliggenhedsadresse.length - 1]?.vejnavn,
            street_no_from: source.beliggenhedsadresse[source.beliggenhedsadresse.length - 1]?.husnummerFra,
            street_no_to: source.beliggenhedsadresse[source.beliggenhedsadresse.length - 1]?.husnummerTil,
            street_letter_from: source.beliggenhedsadresse[source.beliggenhedsadresse.length - 1]?.bogstavFra,
            street_letter_to: source.beliggenhedsadresse[source.beliggenhedsadresse.length - 1]?.bogstavTil,
            door: source.beliggenhedsadresse[source.beliggenhedsadresse.length - 1]?.sidedoer,
            floor: source.beliggenhedsadresse[source.beliggenhedsadresse.length - 1]?.etage,
            co_name: source.beliggenhedsadresse[source.beliggenhedsadresse.length - 1]?.conavn,
            city: {
              name: source.beliggenhedsadresse[source.beliggenhedsadresse.length - 1]?.postdistrikt,
              zip: source.beliggenhedsadresse[source.beliggenhedsadresse.length - 1]?.postnummer
            },
            region: {
              name: source.beliggenhedsadresse[source.beliggenhedsadresse.length - 1]?.kommune.kommuneNavn,
              code: source.beliggenhedsadresse[source.beliggenhedsadresse.length - 1]?.kommune.kommuneKode
            }
          },
          business_type: {
            name: source.hovedbranche[source.hovedbranche.length - 1]?.branchetekst,
            code: source.hovedbranche[source.hovedbranche.length - 1]?.branchekode
          },
          service: 'CVR' // The service indicates from which service the results are fetched
        }
      })
    }
    return null
  }
}
