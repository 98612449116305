export default () => {
  return {
    verifyEmailModal: {
      show: false
    },
    session: {
      expired: false
    },
    sidebarCollapsed: false,
    sidebarToggled: false
  }
}
