import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.REPORT_TEMPLATE_INDEX_PENDING] (state) {
    set(state, 'index', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_INDEX_SUCCESS] (state, payload) {
    set(state, 'index', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_INDEX_FAILURE] (state, payload) {
    set(state, 'index', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_INDEX_NO_CONTENT] (state, payload) {
    set(state, 'index', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_TEMPLATE_TYPE_PENDING] (state) {
    set(state, 'type', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_TYPE_SUCCESS] (state, payload) {
    set(state, 'type', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_TYPE_FAILURE] (state, payload) {
    set(state, 'type', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_TYPE_NO_CONTENT] (state, payload) {
    set(state, 'type', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_TEMPLATE_PENDING] (state) {
    set(state, 'id', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_SUCCESS] (state, payload) {
    set(state, 'id', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_FAILURE] (state, payload) {
    set(state, 'id', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_TEMPLATE_SECTION_PENDING] (state) {
    set(state, 'section', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_SECTION_SUCCESS] (state, payload) {
    set(state, 'section', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_SECTION_FAILURE] (state, payload) {
    set(state, 'section', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_TEMPLATE_UPDATE_PENDING] (state) {
    set(state, 'update', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_UPDATE_SUCCESS] (state, payload) {
    set(state, 'update', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_UPDATE_FAILURE] (state, payload) {
    set(state, 'update', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_TEMPLATE_DELETE_PENDING] (state) {
    set(state, 'delete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.REPORT_TEMPLATE_DELETE_SUCCESS] (state) {
    set(state, 'delete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_DELETE_FAILURE] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_TEMPLATE_SECTION_TYPE_PENDING] (state) {
    set(state, 'sectionType', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_SECTION_TYPE_SUCCESS] (state, payload) {
    set(state, 'sectionType', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_SECTION_TYPE_FAILURE] (state, payload) {
    set(state, 'sectionType', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_TEMPLATE_SECTION_TYPE_NO_CONTENT] (state, payload) {
    set(state, 'sectionType', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_TEMPLATE_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
