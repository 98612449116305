// IT GROUP CONSTANTS
export const IT_TAG_INDEX_PENDING = 'IT_TAG_INDEX_PENDING'
export const IT_TAG_INDEX_SUCCESS = 'IT_TAG_INDEX_SUCCESS'
export const IT_TAG_INDEX_FAILURE = 'IT_TAG_INDEX_FAILURE'
export const IT_TAG_INDEX_NO_CONTENT = 'IT_TAG_INDEX_NO_CONTENT'

export const IT_TAG_CREATE_PENDING = 'IT_TAG_CREATE_PENDING'
export const IT_TAG_CREATE_SUCCESS = 'IT_TAG_CREATE_SUCCESS'
export const IT_TAG_CREATE_FAILURE = 'IT_TAG_CREATE_FAILURE'

export const IT_TAG_RESET_STORE = 'IT_TAG_RESET_STORE'
