
export default {
  name: 'NowNavNotificationMenu',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    hasMore: {
      type: Boolean,
      default: false
    },
    unreadNotificationCount: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      threshold: 100
    }
  },
  computed: {
    atBottom () {
      return this.$refs?.wrapper?.scrollHeight
    }
  },
  mounted () {
    this.$refs?.wrapper?.addEventListener('scroll', this.scrollEventHandler)
  },
  methods: {
    scrollEventHandler (event) {
      const wrapper = event.target
      const scrollPosition = wrapper.scrollTop + wrapper.clientHeight
      const totalHeight = wrapper.scrollHeight

      // Adjust the threshold as needed
      if (scrollPosition >= totalHeight - this.threshold && this.hasMore && !this.loading) {
        this.fetch()
      }
    },
    handleReadAll () {
      this.$emit('readAll')
    },
    fetch () {
      this.$emit('fetch')
    },
    close () {
      this.$emit('close')
    }
  }
}
