// ORGANIZATION CONSTANTS
export const LOG_INDEX_PENDING = 'LOG_INDEX_PENDING'
export const LOG_INDEX_SUCCESS = 'LOG_INDEX_SUCCESS'
export const LOG_INDEX_FAILURE = 'LOG_INDEX_FAILURE'

export const LOG_ORGANIZATION_INDEX_PENDING = 'LOG_ORGANIZATION_INDEX_PENDING'
export const LOG_ORGANIZATION_INDEX_SUCCESS = 'LOG_ORGANIZATION_INDEX_SUCCESS'
export const LOG_ORGANIZATION_INDEX_FAILURE = 'LOG_ORGANIZATION_INDEX_FAILURE'

export const LOG_LATEST_PENDING = 'LOG_LATEST_PENDING'
export const LOG_LATEST_SUCCESS = 'LOG_LATEST_SUCCESS'
export const LOG_LATEST_FAILURE = 'LOG_LATEST_FAILURE'

export const LOG_RESET_STORE = 'LOG_RESET_STORE'
