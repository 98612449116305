// ARCHIVE CONSTANTS
export const ARCHIVE_INDEX_PENDING = 'ARCHIVE_INDEX_PENDING'
export const ARCHIVE_INDEX_SUCCESS = 'ARCHIVE_INDEX_SUCCESS'
export const ARCHIVE_INDEX_FAILURE = 'ARCHIVE_INDEX_FAILURE'
export const ARCHIVE_INDEX_NO_CONTENT = 'ARCHIVE_INDEX_NO_CONTENT'

export const ARCHIVE_TRASHED_PENDING = 'ARCHIVE_TRASHED_PENDING'
export const ARCHIVE_TRASHED_SUCCESS = 'ARCHIVE_TRASHED_SUCCESS'
export const ARCHIVE_TRASHED_FAILURE = 'ARCHIVE_TRASHED_FAILURE'
export const ARCHIVE_TRASHED_NO_CONTENT = 'ARCHIVE_TRASHED_NO_CONTENT'

export const ARCHIVE_SHOW_PENDING = 'ARCHIVE_SHOW_PENDING'
export const ARCHIVE_SHOW_SUCCESS = 'ARCHIVE_SHOW_SUCCESS'
export const ARCHIVE_SHOW_FAILURE = 'ARCHIVE_SHOW_FAILURE'

export const ARCHIVE_CREATE_PENDING = 'ARCHIVE_CREATE_PENDING'
export const ARCHIVE_CREATE_SUCCESS = 'ARCHIVE_CREATE_SUCCESS'
export const ARCHIVE_CREATE_FAILURE = 'ARCHIVE_CREATE_FAILURE'

export const ARCHIVE_UPDATE_PENDING = 'ARCHIVE_UPDATE_PENDING'
export const ARCHIVE_UPDATE_SUCCESS = 'ARCHIVE_UPDATE_SUCCESS'
export const ARCHIVE_UPDATE_FAILURE = 'ARCHIVE_UPDATE_FAILURE'

export const ARCHIVE_DELETE_PENDING = 'ARCHIVE_DELETE_PENDING'
export const ARCHIVE_DELETE_SUCCESS = 'ARCHIVE_DELETE_SUCCESS'
export const ARCHIVE_DELETE_FAILURE = 'ARCHIVE_DELETE_FAILURE'

export const ARCHIVE_RESTORE_PENDING = 'ARCHIVE_RESTORE_PENDING'
export const ARCHIVE_RESTORE_SUCCESS = 'ARCHIVE_RESTORE_SUCCESS'
export const ARCHIVE_RESTORE_FAILURE = 'ARCHIVE_RESTORE_FAILURE'

export const ARCHIVE_CREATED_BY_PENDING = 'ARCHIVE_CREATED_BY_PENDING'
export const ARCHIVE_CREATED_BY_SUCCESS = 'ARCHIVE_CREATED_BY_SUCCESS'
export const ARCHIVE_CREATED_BY_FAILURE = 'ARCHIVE_CREATED_BY_FAILURE'

export const ARCHIVE_USERS_PENDING = 'ARCHIVE_USERS_PENDING'
export const ARCHIVE_USERS_SUCCESS = 'ARCHIVE_USERS_SUCCESS'
export const ARCHIVE_USERS_FAILURE = 'ARCHIVE_USERS_FAILURE'

export const ARCHIVE_BREADCRUMBS_PENDING = 'ARCHIVE_BREADCRUMBS_PENDING'
export const ARCHIVE_BREADCRUMBS_SUCCESS = 'ARCHIVE_BREADCRUMBS_SUCCESS'
export const ARCHIVE_BREADCRUMBS_FAILURE = 'ARCHIVE_BREADCRUMBS_FAILURE'
export const ARCHIVE_BREADCRUMBS_RESET = 'ARCHIVE_BREADCRUMBS_RESET'

export const ARCHIVE_RESET_STORE = 'ARCHIVE_RESET_STORE'
