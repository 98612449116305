export default () => {
  return {
    create: {
      loading: false,
      error: null
    },
    update: {
      loading: false,
      error: null
    },
    delete: {
      loading: false,
      error: null
    },
    batch: {
      loading: false,
      error: null
    },
    order: {
      loading: false,
      error: null
    }
  }
}
