// DEVIATION CONSTANTS
export const DEVIATION_FILE_INDEX_PENDING = 'DEVIATION_FILE_INDEX_PENDING'
export const DEVIATION_FILE_INDEX_SUCCESS = 'DEVIATION_FILE_INDEX_SUCCESS'
export const DEVIATION_FILE_INDEX_FAILURE = 'DEVIATION_FILE_INDEX_FAILURE'
export const DEVIATION_FILE_INDEX_NO_CONTENT = 'DEVIATION_FILE_INDEX_NO_CONTENT'

export const DEVIATION_FILE_CREATE_PENDING = 'DEVIATION_FILE_CREATE_PENDING'
export const DEVIATION_FILE_CREATE_SUCCESS = 'DEVIATION_FILE_CREATE_SUCCESS'
export const DEVIATION_FILE_CREATE_FAILURE = 'DEVIATION_FILE_CREATE_FAILURE'

export const DEVIATION_FILE_DELETE_PENDING = 'DEVIATION_FILE_DELETE_PENDING'
export const DEVIATION_FILE_DELETE_SUCCESS = 'DEVIATION_FILE_DELETE_SUCCESS'
export const DEVIATION_FILE_DELETE_FAILURE = 'DEVIATION_FILE_DELETE_FAILURE'

export const DEVIATION_FILE_RESET_STORE = 'DEVIATION_FILE_RESET_STORE'
