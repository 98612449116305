import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getItGroupIndex ({ commit }, payload) {
    try {
      commit(C.IT_GROUP_INDEX_PENDING)
      const res = await this.$axios.get('it-groups', { params: payload })
      commit(C.IT_GROUP_INDEX_SUCCESS, res.data)
    } catch (err) {
      commit(C.IT_GROUP_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getItGroupCount ({ commit }, payload) {
    try {
      commit(C.IT_GROUP_COUNT_PENDING)
      const res = await this.$axios.get('it-groups/count', { params: payload })
      commit(C.IT_GROUP_COUNT_SUCCESS, res.data)
    } catch (err) {
      commit(C.IT_GROUP_COUNT_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getItGroupNames ({ commit }) {
    try {
      commit(C.IT_GROUP_NAMES_PENDING)
      const res = await this.$axios.get('it-groups/names')
      commit(C.IT_GROUP_NAMES_SUCCESS, res.data)
    } catch (err) {
      commit(C.IT_GROUP_NAMES_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createItGroup ({ commit }, payload) {
    try {
      commit(C.IT_GROUP_CREATE_PENDING)
      const res = await this.$axios.post('it-groups', payload)
      commit(C.IT_GROUP_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.IT_GROUP_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateItGroup ({ commit }, payload) {
    try {
      commit(C.IT_GROUP_UPDATE_PENDING)
      const res = await this.$axios.put(`it-groups/${payload.id}`, payload)
      commit(C.IT_GROUP_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.IT_GROUP_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteItGroup ({ commit }, payload) {
    try {
      commit(C.IT_GROUP_DELETE_PENDING)
      const res = await this.$axios.delete(`it-groups/${payload.id}`)
      commit(C.IT_GROUP_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.IT_GROUP_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetItGroupStore ({ commit }) {
    commit(C.IT_GROUP_RESET_STORE)
  }
}
