// CVR CONSTANTS
export const TABLE_COLUMN_INDEX_PENDING = 'TABLE_COLUMN_INDEX_PENDING'
export const TABLE_COLUMN_INDEX_SUCCESS = 'TABLE_COLUMN_INDEX_SUCCESS'
export const TABLE_COLUMN_INDEX_FAILURE = 'TABLE_COLUMN_INDEX_FAILURE'
export const TABLE_COLUMN_INDEX_NO_CONTENT = 'TABLE_COLUMN_INDEX_NO_CONTENT'

export const TABLE_COLUMN_SLUG_PENDING = 'TABLE_COLUMN_SLUG_PENDING'
export const TABLE_COLUMN_SLUG_SUCCESS = 'TABLE_COLUMN_SLUG_SUCCESS'
export const TABLE_COLUMN_SLUG_FAILURE = 'TABLE_COLUMN_SLUG_FAILURE'

export const TABLE_COLUMN_CREATE_PENDING = 'TABLE_COLUMN_CREATE_PENDING'
export const TABLE_COLUMN_CREATE_SUCCESS = 'TABLE_COLUMN_CREATE_SUCCESS'
export const TABLE_COLUMN_CREATE_FAILURE = 'TABLE_COLUMN_CREATE_FAILURE'

export const TABLE_COLUMN_UPDATE_PENDING = 'TABLE_COLUMN_UPDATE_PENDING'
export const TABLE_COLUMN_UPDATE_SUCCESS = 'TABLE_COLUMN_UPDATE_SUCCESS'
export const TABLE_COLUMN_UPDATE_FAILURE = 'TABLE_COLUMN_UPDATE_FAILURE'

export const TABLE_COLUMN_DELETE_PENDING = 'TABLE_COLUMN_DELETE_PENDING'
export const TABLE_COLUMN_DELETE_SUCCESS = 'TABLE_COLUMN_DELETE_SUCCESS'
export const TABLE_COLUMN_DELETE_FAILURE = 'TABLE_COLUMN_DELETE_FAILURE'

export const TABLE_COLUMN_SECTIONS_PENDING = 'TABLE_COLUMN_SECTIONS_PENDING'
export const TABLE_COLUMN_SECTIONS_SUCCESS = 'TABLE_COLUMN_SECTIONS_SUCCESS'
export const TABLE_COLUMN_SECTIONS_FAILURE = 'TABLE_COLUMN_SECTIONS_FAILURE'

export const TABLE_VALUE_INDEX_PENDING = 'TABLE_VALUE_INDEX_PENDING'
export const TABLE_VALUE_INDEX_SUCCESS = 'TABLE_VALUE_INDEX_SUCCESS'
export const TABLE_VALUE_INDEX_FAILURE = 'TABLE_VALUE_INDEX_FAILURE'
export const TABLE_VALUE_INDEX_NO_CONTENT = 'TABLE_VALUE_INDEX_NO_CONTENT'

export const TABLE_VALUE_CREATE_PENDING = 'TABLE_VALUE_CREATE_PENDING'
export const TABLE_VALUE_CREATE_SUCCESS = 'TABLE_VALUE_CREATE_SUCCESS'
export const TABLE_VALUE_CREATE_FAILURE = 'TABLE_VALUE_CREATE_FAILURE'

export const TABLE_COLUMN_TYPE_INDEX_PENDING = 'TABLE_COLUMN_TYPE_INDEX_PENDING'
export const TABLE_COLUMN_TYPE_INDEX_SUCCESS = 'TABLE_COLUMN_TYPE_INDEX_SUCCESS'
export const TABLE_COLUMN_TYPE_INDEX_FAILURE = 'TABLE_COLUMN_TYPE_INDEX_FAILURE'

export const TABLE_RESET_STORE = 'TABLE_RESET_STORE'
