// ACTION PLAN TASK CONSTANTS
export const ACTION_PLAN_TASK_CREATE_PENDING = 'ACTION_PLAN_TASK_CREATE_PENDING'
export const ACTION_PLAN_TASK_CREATE_SUCCESS = 'ACTION_PLAN_TASK_CREATE_SUCCESS'
export const ACTION_PLAN_TASK_CREATE_FAILURE = 'ACTION_PLAN_TASK_CREATE_FAILURE'

export const ACTION_PLAN_TASK_SHOW_PENDING = 'ACTION_PLAN_TASK_SHOW_PENDING'
export const ACTION_PLAN_TASK_SHOW_SUCCESS = 'ACTION_PLAN_TASK_SHOW_SUCCESS'
export const ACTION_PLAN_TASK_SHOW_FAILURE = 'ACTION_PLAN_TASK_SHOW_FAILURE'

export const ACTION_PLAN_TASK_RESET_STORE = 'ACTION_PLAN_TASK_RESET_STORE'
