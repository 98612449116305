// AUTHENTICATION CONSTANTS
export const CONVERSATION_SHOW_PENDING = 'CONVERSATION_SHOW_PENDING'
export const CONVERSATION_SHOW_SUCCESS = 'CONVERSATION_SHOW_SUCCESS'
export const CONVERSATION_SHOW_FAILURE = 'CONVERSATION_SHOW_FAILURE'

export const CONVERSATION_CREATE_PENDING = 'CONVERSATION_CREATE_PENDING'
export const CONVERSATION_CREATE_SUCCESS = 'CONVERSATION_CREATE_SUCCESS'
export const CONVERSATION_CREATE_FAILURE = 'CONVERSATION_CREATE_FAILURE'

export const CONVERSATION_MESSAGE_PENDING = 'CONVERSATION_MESSAGE_PENDING'
export const CONVERSATION_MESSAGE_SUCCESS = 'CONVERSATION_MESSAGE_SUCCESS'
export const CONVERSATION_MESSAGE_FAILURE = 'CONVERSATION_MESSAGE_FAILURE'

export const CONVERSATION_RESET_STORE = 'CONVERSATION_RESET_STORE'
