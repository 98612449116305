import { render, staticRenderFns } from "./action.vue?vue&type=template&id=c2fca6b8&scoped=true&"
import script from "./action.vue?vue&type=script&lang=js&"
export * from "./action.vue?vue&type=script&lang=js&"
import style0 from "./action.vue?vue&type=style&index=0&id=c2fca6b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2fca6b8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NowSessionModal: require('/home/forge/app.tavasta.com/releases/20241213121941/components/NowSessionModal.vue').default,NowPortals: require('/home/forge/app.tavasta.com/releases/20241213121941/components/NowPortals.vue').default})
