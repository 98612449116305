
export default {
  name: 'NowModal',
  props: {
    width: {
      type: String,
      default: 'sm:w-auto'
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    noControls: {
      type: Boolean,
      default: false
    },
    mustConfirm: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    padding: {
      type: String,
      default: 'p-2 sm:p-4'
    },
    paddingHeader: {
      type: String,
      default: 'p-2 sm:p-4'
    },
    paddingControls: {
      type: String,
      default: 'p-2 sm:p-4'
    }
  },
  data () {
    return {
      isFullScreen: false,
      isClosing: false,
      hasFocus: false
    }
  },
  watch: {
    fullscreen () {
      this.isFullScreen = this.fullscreen
    },
    isFullScreen () {
      this.$emit('fullscreen', this.isFullScreen)
    }
  },
  mounted () {
    // Check if any open modals and set overflow
    if (document.getElementsByClassName('now-modal').length) {
      document.body.classList.add('overflow-hidden')
    }
    this.$el.addEventListener('keyup', this.keyEventHandler)
    this.initialize()
  },
  beforeDestroy () {
    // Check if no open modals and set overflow
    if (!document.getElementsByClassName('now-modal').length) {
      document.body.classList.remove('overflow-hidden')
    }
    this.$el.removeEventListener('keyup', this.keyEventHandler)
  },
  methods: {
    initialize () {
      this.isFullScreen = this.fullscreen
      // If autofocus then set focus to modal
      if (this.autofocus) {
        this.$refs.modal.focus()
      }
    },
    keyEventHandler (event) {
      if (event?.key === 'Escape') {
        this.close()
      }
    },
    clickEventHandler (event) {
      if (event.target.classList.contains('now-modal')) {
        this.close()
      }
    },
    toggleFullscreen () {
      this.isFullScreen = !this.isFullScreen
    },
    close () {
      if (this.mustConfirm && !this.isClosing) {
        this.isClosing = true
      } else if (this.mustConfirm && this.isClosing) {
        this.$emit('close')
        this.isClosing = false
      } else {
        this.$emit('close')
      }
    },
    reset () {
      this.isClosing = false
    }
  }
}
