import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getUnitCategoryIndex ({ commit }, payload) {
    try {
      commit(C.UNIT_CATEGORY_INDEX_PENDING)
      const res = await this.$axios.get('unit-categories', { params: payload })
      if (res.status === 200 && res.data?.length) {
        commit(C.UNIT_CATEGORY_INDEX_SUCCESS, res.data)
      } else {
        commit(C.UNIT_CATEGORY_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.UNIT_CATEGORY_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createUnitCategory ({ commit }, payload) {
    try {
      commit(C.UNIT_CATEGORY_CREATE_PENDING)
      const res = await this.$axios.post('unit-categories', payload)
      commit(C.UNIT_CATEGORY_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.UNIT_CATEGORY_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getUnitCategory ({ commit }, payload) {
    try {
      commit(C.UNIT_CATEGORY_SHOW_PENDING)
      const res = await this.$axios.get(`unit-categories/${payload.id}`)
      commit(C.UNIT_CATEGORY_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.UNIT_CATEGORY_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateUnitCategory ({ commit }, payload) {
    try {
      commit(C.UNIT_CATEGORY_UPDATE_PENDING)
      const res = await this.$axios.put(`unit-categories/${payload.id}`, payload)
      commit(C.UNIT_CATEGORY_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.UNIT_CATEGORY_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteUnitCategory ({ commit }, payload) {
    try {
      commit(C.UNIT_CATEGORY_DELETE_PENDING)
      const res = await this.$axios.delete(`unit-categories/${payload.id}`)
      commit(C.UNIT_CATEGORY_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.UNIT_CATEGORY_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getUnitCategoryBreadcrumbs ({ commit }, payload) {
    try {
      commit(C.UNIT_CATEGORY_BREADCRUMBS_PENDING)
      const res = await this.$axios.get(`unit-categories/${payload}/breadcrumbs`)
      commit(C.UNIT_CATEGORY_BREADCRUMBS_SUCCESS, res.data)
    } catch (err) {
      commit(C.UNIT_CATEGORY_BREADCRUMBS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetUnitCategoryBreadcrumbs ({ commit }) {
    commit(C.UNIT_CATEGORY_BREADCRUMBS_RESET)
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  resetUnitCategoryStore ({ commit }) {
    commit(C.UNIT_CATEGORY_RESET_STORE)
  }
}
