export default () => {
  return {
    index: {
      items: [],
      loading: false,
      error: null
    },
    add: {
      loading: false,
      error: null
    },
    revoke: {
      loading: false,
      error: null
    }
  }
}
