// ORGANIZATION CONSTANTS

export const ORGANIZATION_BILLING_SHOW_PENDING = 'ORGANIZATION_BILLING_SHOW_PENDING'
export const ORGANIZATION_BILLING_SHOW_SUCCESS = 'ORGANIZATION_BILLING_SHOW_SUCCESS'
export const ORGANIZATION_BILLING_SHOW_FAILURE = 'ORGANIZATION_BILLING_SHOW_FAILURE'
export const ORGANIZATION_BILLING_SHOW_NO_CONTENT = 'ORGANIZATION_BILLING_SHOW_NO_CONTENT'

export const ORGANIZATION_BILLING_UPDATE_PENDING = 'ORGANIZATION_BILLING_UPDATE_PENDING'
export const ORGANIZATION_BILLING_UPDATE_SUCCESS = 'ORGANIZATION_BILLING_UPDATE_SUCCESS'
export const ORGANIZATION_BILLING_UPDATE_FAILURE = 'ORGANIZATION_BILLING_UPDATE_FAILURE'

export const ORGANIZATION_BILLING_RESET_STORE = 'ORGANIZATION_BILLING_RESET_STORE'
