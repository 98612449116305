export default () => {
  return {
    organization: null,
    index: {
      items: [],
      loading: false,
      error: null
    },
    create: {
      item: null,
      loading: false,
      error: null
    },
    update: {
      item: null,
      loading: false,
      error: null
    },
    delete: {
      loading: false,
      error: null
    },
    users: {
      items: [],
      loading: false,
      error: null
    },
    session: {
      item: null,
      loading: false,
      error: null
    },
    dashboard: {
      item: null,
      loading: false,
      error: null
    },
    onboarding: {
      item: null,
      loading: false,
      error: null
    },
    subscriptionStats: {
      item: null,
      loading: false,
      error: null
    }
  }
}
