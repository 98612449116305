
export default {
  name: 'NowSkeletonLoader',
  props: {
    columns: {
      type: [String, Number],
      default: 1
    },
    rows: {
      type: [String, Number],
      default: 1
    },
    backgroundColor: {
      type: String,
      default: 'bg-[rgba(0,0,0,0.02)]'
    }
  }
}
