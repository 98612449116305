import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createReportProcedureContentSection ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_CREATE_PENDING)
      const res = await this.$axios.post('report-procedure-content-sections', payload)
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateReportProcedureContentSection ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_PENDING)
      const res = await this.$axios.put(`report-procedure-content-sections/${payload.id}`, payload)
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteReportProcedureContentSection ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_DELETE_PENDING)
      const res = await this.$axios.delete(`report-procedure-content-sections/${payload}`)
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async batchReportProcedureContentSection ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_BATCH_PENDING)
      const res = await this.$axios.post('report-procedure-content-sections/batch', payload)
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_BATCH_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_BATCH_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async orderReportProcedureContentSection ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_ORDER_PENDING)
      const res = await this.$axios.post('report-procedure-content-sections/order', payload)
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_ORDER_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_ORDER_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadReportProcedureContentSectionFile ({ commit }, payload) {
    const formData = new FormData()
    formData.append('file', payload.file)
    formData.append('id', payload.id)
    try {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_PENDING)
      const res = await this.$axios.post(`report-procedure-content-sections/${payload.id}/files`, formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        }
      })
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_PENDING, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_PENDING, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteReportProcedureContentSectionFile ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_PENDING)
      const res = await this.$axios.delete(`report-procedure-content-sections/${payload.id}/files/${payload.file_id}`)
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_FAILURE)
    }
  },

  /**
   * @param commit
   */
  resetReportProcedureContentSectionStore ({ commit }) {
    commit(C.REPORT_PROCEDURE_CONTENT_SECTION_RESET_STORE)
  }
}
