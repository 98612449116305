
import validation from '../plugins/validation'
import inputTypes from '@/constants/inputTypes'

export default {
  name: 'NowInput',
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    type: {
      type: String,
      validator: val => [
        'text',
        'password',
        'email',
        'date',
        'datetime-local',
        'file',
        'radio',
        'checkbox',
        'search',
        'number',
        'color',
        'submit',
        'select',
        'range'
      ].includes(val),
      default: 'text'
    },
    icon: {
      type: String,
      default: null
    },
    step: {
      type: Number,
      default: null
    },
    size: {
      type: String,
      validator: val => [
        'small',
        'base',
        'large'
      ].includes(val),
      default: 'base'
    },
    color: {
      type: String,
      validator: val => [
        'dark',
        'light'
      ].includes(val),
      default: 'dark'
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    url: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    min: {
      type: [Number, String],
      default: null
    },
    minLength: {
      type: [Number, String],
      default: null
    },
    max: {
      type: [Number, String],
      default: null
    },
    maxLength: {
      type: [Number, String],
      default: null
    },
    pattern: {
      type: String,
      default: null
    },
    checked: {
      type: Boolean,
      default: null
    },
    borderless: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    fullBorder: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    selectBorder: {
      type: Boolean,
      default: false
    },
    lang: {
      type: String,
      default: 'da'
    }
  },
  data () {
    return {
      isActive: false,
      hasChanged: false,
      hadFocus: false,
      localType: 'text',
      inputTypes
    }
  },
  computed: {
    currentValue () {
      if (this.type === inputTypes.DATE || this.type === inputTypes.DATETIME_LOCAL) {
        return this.formatDate()
      } else if (this.type === inputTypes.SUBMIT && this.loading) {
        return ' '
      } else {
        return this.value
      }
    },
    showErrors () {
      return this.errors.length && this.type !== inputTypes.SUBMIT && (this.hasChanged || this.hadFocus)
    },
    errors () {
      const errors = []
      // Validation for required field
      if (this.required && (this.value === '' || isNaN(Number(this.value)))) {
        if (this.type === inputTypes.NUMBER && (this.value === '' || isNaN(Number(this.value)))) {
          errors.push(this.$t('validation.required'))
        } else if (this.value === null || this.value === undefined || this.value === '') {
          errors.push(this.$t('validation.required'))
        }
      }
      // Validation for url field
      if (this.url && this.value) {
        if (!validation.validateUrl(this.value)) {
          errors.push(this.$t('validation.url'))
        }
      }
      // Validate for min
      if (this.min !== null) {
        if (this.type === inputTypes.NUMBER && !validation.validateMin(this.min, this.value)) {
          errors.push(this.$t('validation.min', { min: this.min }))
        } else if (this.min > this.value) {
          errors.push(this.$t('validation.min', { min: this.min }))
        }
      }
      // Validate for max
      if (this.max !== null) {
        if (this.type === inputTypes.NUMBER && !validation.validateMax(this.max, this.value)) {
          errors.push(this.$t('validation.max', { max: this.max }))
        } else if (this.max < this.value) {
          errors.push(this.$t('validation.max', { max: this.max }))
        }
      }
      // Validate for email field
      if (this.type === inputTypes.EMAIL && this.value) {
        if (!validation.validateEmail(this.value)) {
          errors.push(this.$t('validation.email'))
        }
      }
      return errors
    },
    aboveMaxLength () {
      return this.maxLength ? this.value?.length > this.maxLength : false
    },
    hasMarginTop () {
      return this.type !== inputTypes.SUBMIT &&
          this.type !== inputTypes.CHECKBOX &&
          this.type !== inputTypes.SEARCH &&
          this.type !== inputTypes.SELECT &&
          !this.fullBorder
    },
    hasMarginBottom () {
      return this.type !== inputTypes.SUBMIT &&
          this.type !== inputTypes.CHECKBOX &&
          this.type !== inputTypes.SEARCH &&
          this.type !== inputTypes.SELECT
    }
  },
  created () {
    this.localType = this.type
  },
  mounted () {
    if (this.autofocus && this.$refs.input) {
      this.$refs.input.focus()
      this.isActive = true
    }
  },
  methods: {
    handleFocus () {
      if (!this.readonly) {
        this.isActive = true
        this.$emit('focus')
      }
    },
    handleBlur () {
      if (!this.readonly) {
        this.$nextTick(() => {
          this.isActive = false
          this.hadFocus = true
          this.$emit('blur')
        })
      }
    },
    handleInput (event) {
      this.hasChanged = true
      if (this.type === inputTypes.CHECKBOX) {
        this.$emit('input', event.target.checked)
      } else {
        this.$emit('input', event.target.value)
      }
    },
    handleClear () {
      this.$emit('input', null)
      this.$emit('clear')
    },
    handleSearch () {
      if (!this.disabled && this.value) {
        this.$emit('search')
      }
    },
    toggleShowPassword () {
      this.localType = this.localType === inputTypes.PASSWORD ? inputTypes.TEXT : inputTypes.PASSWORD
    },
    formatDate () {
      if (this.type === inputTypes.DATE) {
        return this.$moment(this.value).format('YYYY-MM-DD')
      } else if (this.type === inputTypes.DATETIME_LOCAL) {
        return this.$moment(this.value).format('YYYY-MM-DDThh:mm')
      }
    }
  }
}
