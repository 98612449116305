import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.TWO_FACTOR_ENABLE_PENDING] (state) {
    set(state, 'index', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.TWO_FACTOR_ENABLE_SUCCESS] (state) {
    set(state, 'index', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_ENABLE_FAILURE] (state, payload) {
    set(state, 'index', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TWO_FACTOR_DISABLE_PENDING] (state) {
    set(state, 'disable', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_DISABLE_SUCCESS] (state, payload) {
    set(state, 'disable', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_DISABLE_FAILURE] (state, payload) {
    set(state, 'disable', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TWO_FACTOR_CHALLENGE_PENDING] (state) {
    set(state, 'challenge', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_CHALLENGE_SUCCESS] (state, payload) {
    set(state, 'challenge', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_CHALLENGE_FAILURE] (state, payload) {
    set(state, 'challenge', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TWO_FACTOR_RECOVERY_CODES_PENDING] (state) {
    set(state, 'recoveryCodes', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_RECOVERY_CODES_SUCCESS] (state, payload) {
    set(state, 'recoveryCodes', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_RECOVERY_CODES_FAILURE] (state, payload) {
    set(state, 'recoveryCodes', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TWO_FACTOR_RECOVERY_CODES_CREATE_PENDING] (state) {
    set(state, 'recoveryCodesCreate', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_RECOVERY_CODES_CREATE_SUCCESS] (state, payload) {
    set(state, 'recoveryCodesCreate', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_RECOVERY_CODES_CREATE_FAILURE] (state, payload) {
    set(state, 'recoveryCodesCreate', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TWO_FACTOR_QR_CODE_PENDING] (state) {
    set(state, 'qrCode', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_QR_CODE_SUCCESS] (state, payload) {
    set(state, 'qrCode', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_QR_CODE_FAILURE] (state, payload) {
    set(state, 'qrCode', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TWO_FACTOR_PASSWORD_CONFIRMATION_PENDING] (state) {
    set(state, 'passwordConfirmation', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_PASSWORD_CONFIRMATION_SUCCESS] (state, payload) {
    set(state, 'passwordConfirmation', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_PASSWORD_CONFIRMATION_FAILURE] (state, payload) {
    set(state, 'passwordConfirmation', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TWO_FACTOR_CONFIRMATION_PENDING] (state) {
    set(state, 'confirmation', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_CONFIRMATION_SUCCESS] (state, payload) {
    set(state, 'confirmation', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_CONFIRMATION_FAILURE] (state, payload) {
    set(state, 'confirmation', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TWO_FACTOR_PASSWORD_STATUS_PENDING] (state) {
    set(state, 'passwordStatus', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_PASSWORD_STATUS_SUCCESS] (state, payload) {
    set(state, 'passwordStatus', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TWO_FACTOR_PASSWORD_STATUS_FAILURE] (state, payload) {
    set(state, 'passwordStatus', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TWO_FACTOR_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
