import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.IT_GROUP_ITEM_INDEX_PENDING] (state) {
    set(state, 'index', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.IT_GROUP_ITEM_INDEX_SUCCESS] (state, payload) {
    set(state, 'index', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.IT_GROUP_ITEM_INDEX_FAILURE] (state, payload) {
    set(state, 'index', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_ITEM_CREATE_PENDING] (state) {
    set(state, 'create', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_ITEM_CREATE_SUCCESS] (state) {
    set(state, 'create', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.IT_GROUP_ITEM_CREATE_FAILURE] (state, payload) {
    set(state, 'create', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_ITEM_UPDATE_PENDING] (state) {
    set(state, 'update', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_ITEM_UPDATE_SUCCESS] (state) {
    set(state, 'update', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.IT_GROUP_ITEM_UPDATE_FAILURE] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_ITEM_DELETE_PENDING] (state) {
    set(state, 'delete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_ITEM_DELETE_SUCCESS] (state) {
    set(state, 'delete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.IT_GROUP_ITEM_DELETE_FAILURE] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_ITEM_CONNECTIONS_PENDING] (state) {
    set(state, 'connections', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.IT_GROUP_ITEM_CONNECTIONS_SUCCESS] (state, payload) {
    set(state, 'connections', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.IT_GROUP_ITEM_CONNECTIONS_FAILURE] (state, payload) {
    set(state, 'connections', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_ITEM_CONNECTIONS_NO_CONTENT] (state) {
    set(state, 'connections', {
      items: [],
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_ITEM_CONNECTIONS_UPDATE_PENDING] (state) {
    set(state, 'connectionsUpdate', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_ITEM_CONNECTIONS_UPDATE_SUCCESS] (state) {
    set(state, 'connectionsUpdate', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.IT_GROUP_ITEM_CONNECTIONS_UPDATE_FAILURE] (state, payload) {
    set(state, 'connectionsUpdate', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_ITEM_CONNECTIONS_DELETE_PENDING] (state) {
    set(state, 'connectionsDelete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_ITEM_CONNECTIONS_DELETE_SUCCESS] (state) {
    set(state, 'connectionsDelete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.IT_GROUP_ITEM_CONNECTIONS_DELETE_FAILURE] (state, payload) {
    set(state, 'connectionsDelete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.IT_GROUP_ITEM_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
