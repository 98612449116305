import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async redeemSubscriptionKey ({ commit }, payload) {
    try {
      commit(C.SUBSCRIPTION_KEY_REDEEM_PENDING)
      const res = await this.$axios.post('subscription-keys/redeem', payload)
      commit(C.SUBSCRIPTION_KEY_REDEEM_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUBSCRIPTION_KEY_REDEEM_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetSubscriptionOrganizationStore ({ commit }) {
    commit(C.SUBSCRIPTION_KEY_RESET_STORE)
  }
}
