import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.AUTHENTICATION_REGISTER_PENDING] (state) {
    set(state, 'register', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.AUTHENTICATION_REGISTER_SUCCESS] (state) {
    set(state, 'register', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUTHENTICATION_REGISTER_FAILURE] (state, payload) {
    set(state, 'register', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.AUTHENTICATION_VERIFY_PENDING] (state) {
    set(state, 'verify', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.AUTHENTICATION_VERIFY_SUCCESS] (state) {
    set(state, 'verify', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUTHENTICATION_VERIFY_FAILURE] (state, payload) {
    set(state, 'verify', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.AUTHENTICATION_NOTIFICATION_PENDING] (state) {
    set(state, 'notification', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.AUTHENTICATION_NOTIFICATION_SUCCESS] (state) {
    set(state, 'notification', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUTHENTICATION_NOTIFICATION_FAILURE] (state, payload) {
    set(state, 'notification', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.AUTHENTICATION_FORGOT_PENDING] (state) {
    set(state, 'forgot', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.AUTHENTICATION_FORGOT_SUCCESS] (state) {
    set(state, 'forgot', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUTHENTICATION_FORGOT_FAILURE] (state, payload) {
    set(state, 'forgot', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.AUTHENTICATION_RESET_PENDING] (state) {
    set(state, 'reset', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.AUTHENTICATION_RESET_SUCCESS] (state) {
    set(state, 'reset', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUTHENTICATION_RESET_FAILURE] (state, payload) {
    set(state, 'reset', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.AUTHENTICATION_ACTIVATE_PENDING] (state) {
    set(state, 'activate', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.AUTHENTICATION_ACTIVATE_SUCCESS] (state) {
    set(state, 'activate', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUTHENTICATION_ACTIVATE_FAILURE] (state, payload) {
    set(state, 'activate', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.AUTHENTICATION_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
