import { render, staticRenderFns } from "./account.vue?vue&type=template&id=05437302&scoped=true&"
import script from "./account.vue?vue&type=script&lang=js&"
export * from "./account.vue?vue&type=script&lang=js&"
import style0 from "./account.vue?vue&type=style&index=0&id=05437302&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05437302",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NowAccountNav: require('/home/forge/app.tavasta.com/releases/20241213121941/components/NowAccountNav.vue').default,NowSessionModal: require('/home/forge/app.tavasta.com/releases/20241213121941/components/NowSessionModal.vue').default,NowAccountSidebar: require('/home/forge/app.tavasta.com/releases/20241213121941/components/NowAccountSidebar.vue').default,NowPortals: require('/home/forge/app.tavasta.com/releases/20241213121941/components/NowPortals.vue').default})
