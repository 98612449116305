import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.AUDIT_INDEX_PENDING] (state) {
    set(state, 'index', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_INDEX_SUCCESS] (state, payload) {
    set(state, 'index', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_INDEX_FAILURE] (state, payload) {
    set(state, 'index', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_INDEX_NO_CONTENT] (state, payload) {
    set(state, 'index', {
      items: [],
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.AUDIT_REPORT_PENDING] (state) {
    set(state, 'report', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_REPORT_SUCCESS] (state, payload) {
    set(state, 'report', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_REPORT_FAILURE] (state, payload) {
    set(state, 'report', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_REPORT_NO_CONTENT] (state, payload) {
    set(state, 'report', {
      items: [],
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.AUDIT_UPCOMING_PENDING] (state) {
    set(state, 'upcoming', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_UPCOMING_SUCCESS] (state, payload) {
    set(state, 'upcoming', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_UPCOMING_FAILURE] (state, payload) {
    set(state, 'upcoming', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.AUDIT_SHOW_PENDING] (state) {
    set(state, 'show', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_SHOW_SUCCESS] (state, payload) {
    set(state, 'show', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_SHOW_FAILURE] (state, payload) {
    set(state, 'show', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.AUDIT_CREATE_PENDING] (state) {
    set(state, 'create', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_CREATE_SUCCESS] (state, payload) {
    set(state, 'create', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_CREATE_FAILURE] (state, payload) {
    set(state, 'create', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.AUDIT_UPDATE_PENDING] (state) {
    set(state, 'update', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_UPDATE_SUCCESS] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_UPDATE_FAILURE] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.AUDIT_COMPLETE_PENDING] (state) {
    set(state, 'complete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_COMPLETE_SUCCESS] (state, payload) {
    set(state, 'complete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_COMPLETE_FAILURE] (state, payload) {
    set(state, 'complete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.AUDIT_DELETE_PENDING] (state) {
    set(state, 'delete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_DELETE_SUCCESS] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_DELETE_FAILURE] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.AUDIT_UPLOAD_FILE_PENDING] (state) {
    set(state, 'upload', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_UPLOAD_FILE_SUCCESS] (state, payload) {
    set(state, 'upload', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.AUDIT_UPLOAD_FILE_FAILURE] (state, payload) {
    set(state, 'upload', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.AUDIT_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
