export default () => {
  return {
    section: {
      item: null,
      loading: false,
      error: null
    },
    search: {
      items: [],
      loading: false,
      error: null
    },
    create: {
      item: null,
      loading: false,
      error: null
    },
    publish: {
      loading: false,
      error: null
    },
    assignee: {
      loading: false,
      error: null
    }
  }
}
