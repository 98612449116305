
import { mapState } from 'vuex'

export default {
  name: 'Default',
  computed: {
    ...mapState({
      sidebarCollapsed: state => state.app.sidebarCollapsed
    })
  }
}
