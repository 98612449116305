// ORGANIZATION CONSTANTS
export const NOTIFICATION_INDEX_PENDING = 'NOTIFICATION_INDEX_PENDING'
export const NOTIFICATION_INDEX_SUCCESS = 'NOTIFICATION_INDEX_SUCCESS'
export const NOTIFICATION_INDEX_FAILURE = 'NOTIFICATION_INDEX_FAILURE'
export const NOTIFICATION_INDEX_NO_CONTENT = 'NOTIFICATION_INDEX_NO_CONTENT'

export const NOTIFICATION_UNREAD_PENDING = 'NOTIFICATION_UNREAD_PENDING'
export const NOTIFICATION_UNREAD_SUCCESS = 'NOTIFICATION_UNREAD_SUCCESS'
export const NOTIFICATION_UNREAD_FAILURE = 'NOTIFICATION_UNREAD_FAILURE'
export const NOTIFICATION_UNREAD_NO_CONTENT = 'NOTIFICATION_UNREAD_NO_CONTENT'

export const NOTIFICATION_UNREAD_COUNT_PENDING = 'NOTIFICATION_UNREAD_COUNT_PENDING'
export const NOTIFICATION_UNREAD_COUNT_SUCCESS = 'NOTIFICATION_UNREAD_COUNT_SUCCESS'
export const NOTIFICATION_UNREAD_COUNT_FAILURE = 'NOTIFICATION_UNREAD_COUNT_FAILURE'

export const NOTIFICATION_READ_PENDING = 'NOTIFICATION_READ_PENDING'
export const NOTIFICATION_READ_SUCCESS = 'NOTIFICATION_READ_SUCCESS'
export const NOTIFICATION_READ_FAILURE = 'NOTIFICATION_READ_FAILURE'

export const NOTIFICATION_RESET_STORE = 'NOTIFICATION_RESET_STORE'
