// ACTION PLAN CONSTANTS
export const ACTION_PLAN_INDEX_PENDING = 'ACTION_PLAN_INDEX_PENDING'
export const ACTION_PLAN_INDEX_SUCCESS = 'ACTION_PLAN_INDEX_SUCCESS'
export const ACTION_PLAN_INDEX_FAILURE = 'ACTION_PLAN_INDEX_FAILURE'

export const ACTION_PLAN_COUNT_PENDING = 'ACTION_PLAN_COUNT_PENDING'
export const ACTION_PLAN_COUNT_SUCCESS = 'ACTION_PLAN_COUNT_SUCCESS'
export const ACTION_PLAN_COUNT_FAILURE = 'ACTION_PLAN_COUNT_FAILURE'

export const ACTION_PLAN_TEMPLATES_PENDING = 'ACTION_PLAN_TEMPLATES_PENDING'
export const ACTION_PLAN_TEMPLATES_SUCCESS = 'ACTION_PLAN_TEMPLATES_SUCCESS'
export const ACTION_PLAN_TEMPLATES_FAILURE = 'ACTION_PLAN_TEMPLATES_FAILURE'
export const ACTION_PLAN_TEMPLATES_NO_CONTENT = 'ACTION_PLAN_TEMPLATES_NO_CONTENT'

export const ACTION_PLAN_SHOW_PENDING = 'ACTION_PLAN_SHOW_PENDING'
export const ACTION_PLAN_SHOW_SUCCESS = 'ACTION_PLAN_SHOW_SUCCESS'
export const ACTION_PLAN_SHOW_FAILURE = 'ACTION_PLAN_SHOW_FAILURE'

export const ACTION_PLAN_CREATE_PENDING = 'ACTION_PLAN_CREATE_PENDING'
export const ACTION_PLAN_CREATE_SUCCESS = 'ACTION_PLAN_CREATE_SUCCESS'
export const ACTION_PLAN_CREATE_FAILURE = 'ACTION_PLAN_CREATE_FAILURE'

export const ACTION_PLAN_TEMPLATE_PENDING = 'ACTION_PLAN_TEMPLATE_PENDING'
export const ACTION_PLAN_TEMPLATE_SUCCESS = 'ACTION_PLAN_TEMPLATE_SUCCESS'
export const ACTION_PLAN_TEMPLATE_FAILURE = 'ACTION_PLAN_TEMPLATE_FAILURE'

export const ACTION_PLAN_UPDATE_PENDING = 'ACTION_PLAN_UPDATE_PENDING'
export const ACTION_PLAN_UPDATE_SUCCESS = 'ACTION_PLAN_UPDATE_SUCCESS'
export const ACTION_PLAN_UPDATE_FAILURE = 'ACTION_PLAN_UPDATE_FAILURE'

export const ACTION_PLAN_DELETE_PENDING = 'ACTION_PLAN_DELETE_PENDING'
export const ACTION_PLAN_DELETE_SUCCESS = 'ACTION_PLAN_DELETE_SUCCESS'
export const ACTION_PLAN_DELETE_FAILURE = 'ACTION_PLAN_DELETE_FAILURE'

export const ACTION_PLAN_COMPLETE_PENDING = 'ACTION_PLAN_COMPLETE_PENDING'
export const ACTION_PLAN_COMPLETE_SUCCESS = 'ACTION_PLAN_COMPLETE_SUCCESS'
export const ACTION_PLAN_COMPLETE_FAILURE = 'ACTION_PLAN_COMPLETE_FAILURE'

export const ACTION_PLAN_RESET_STORE = 'ACTION_PLAN_RESET_STORE'
