// AUTHENTICATION CONSTANTS
export const AUTHENTICATION_REGISTER_PENDING = 'AUTHENTICATION_REGISTER_PENDING'
export const AUTHENTICATION_REGISTER_SUCCESS = 'AUTHENTICATION_REGISTER_SUCCESS'
export const AUTHENTICATION_REGISTER_FAILURE = 'AUTHENTICATION_REGISTER_FAILURE'

export const AUTHENTICATION_VERIFY_PENDING = 'AUTHENTICATION_VERIFY_PENDING'
export const AUTHENTICATION_VERIFY_SUCCESS = 'AUTHENTICATION_VERIFY_SUCCESS'
export const AUTHENTICATION_VERIFY_FAILURE = 'AUTHENTICATION_VERIFY_FAILURE'

export const AUTHENTICATION_NOTIFICATION_PENDING = 'AUTHENTICATION_NOTIFICATION_PENDING'
export const AUTHENTICATION_NOTIFICATION_SUCCESS = 'AUTHENTICATION_NOTIFICATION_SUCCESS'
export const AUTHENTICATION_NOTIFICATION_FAILURE = 'AUTHENTICATION_NOTIFICATION_FAILURE'

export const AUTHENTICATION_FORGOT_PENDING = 'AUTHENTICATION_FORGOT_PENDING'
export const AUTHENTICATION_FORGOT_SUCCESS = 'AUTHENTICATION_FORGOT_SUCCESS'
export const AUTHENTICATION_FORGOT_FAILURE = 'AUTHENTICATION_FORGOT_FAILURE'

export const AUTHENTICATION_RESET_PENDING = 'AUTHENTICATION_RESET_PENDING'
export const AUTHENTICATION_RESET_SUCCESS = 'AUTHENTICATION_RESET_SUCCESS'
export const AUTHENTICATION_RESET_FAILURE = 'AUTHENTICATION_RESET_FAILURE'

export const AUTHENTICATION_ACTIVATE_PENDING = 'AUTHENTICATION_ACTIVATE_PENDING'
export const AUTHENTICATION_ACTIVATE_SUCCESS = 'AUTHENTICATION_ACTIVATE_SUCCESS'
export const AUTHENTICATION_ACTIVATE_FAILURE = 'AUTHENTICATION_ACTIVATE_FAILURE'

export const AUTHENTICATION_RESET_STORE = 'AUTHENTICATION_RESET_STORE'
