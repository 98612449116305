import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getUnitIndex ({ commit }, payload) {
    try {
      commit(C.UNIT_INDEX_PENDING)
      const res = await this.$axios.get('units', { params: payload })
      if (res.status === 200 && res.data) {
        commit(C.UNIT_INDEX_SUCCESS, res.data)
      } else {
        commit(C.UNIT_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.UNIT_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getUnitList ({ commit }, payload) {
    try {
      commit(C.UNIT_LIST_PENDING)
      const res = await this.$axios.get('units/list')
      if (res.status === 200 && res.data) {
        commit(C.UNIT_LIST_SUCCESS, res.data)
      } else {
        commit(C.UNIT_LIST_NO_CONTENT)
      }
    } catch (err) {
      commit(C.UNIT_LIST_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getUnit ({ commit }, payload) {
    try {
      commit(C.UNIT_SHOW_PENDING)
      const res = await this.$axios.get(`units/${payload}`)
      commit(C.UNIT_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.UNIT_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createUnit ({ commit }, payload) {
    try {
      commit(C.UNIT_CREATE_PENDING)
      const res = await this.$axios.post('units', payload)
      commit(C.UNIT_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.UNIT_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateUnit ({ commit }, payload) {
    try {
      commit(C.UNIT_UPDATE_PENDING)
      const res = await this.$axios.put(`units/${payload.id}`, payload)
      commit(C.UNIT_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.UNIT_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteUnit ({ commit }, payload) {
    try {
      commit(C.UNIT_DELETE_PENDING)
      const res = await this.$axios.delete(`units/${payload.id}`)
      commit(C.UNIT_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.UNIT_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteUnitAndTasks ({ commit }, payload) {
    try {
      commit(C.UNIT_DELETE_PENDING)
      const res = await this.$axios.delete(`units/${payload.id}/tasks`)
      commit(C.UNIT_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.UNIT_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getUnitTasks ({ commit }, payload) {
    try {
      commit(C.UNIT_TASKS_PENDING)
      const res = await this.$axios.get(`units/${payload.id}/tasks`, { params: payload })
      if (res.status === 200 && res.data) {
        commit(C.UNIT_TASKS_SUCCESS, res.data)
      } else {
        commit(C.UNIT_TASKS_NO_CONTENT)
      }
    } catch (err) {
      commit(C.UNIT_TASKS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  resetUnitStore ({ commit }) {
    commit(C.UNIT_RESET_STORE)
  }
}
