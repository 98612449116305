export default () => {
  return {
    create: {
      loading: false,
      error: null
    },
    update: {
      loading: false,
      error: null
    },
    restore: {
      loading: false,
      error: null
    },
    delete: {
      loading: false,
      error: null
    }
  }
}
