import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/actionPlan/actions.js'), 'actionPlan/actions.js')
  resolveStoreModules(require('../store/actionPlan/constants.js'), 'actionPlan/constants.js')
  resolveStoreModules(require('../store/actionPlan/mutations.js'), 'actionPlan/mutations.js')
  resolveStoreModules(require('../store/actionPlan/state.js'), 'actionPlan/state.js')
  resolveStoreModules(require('../store/actionPlanTask/actions.js'), 'actionPlanTask/actions.js')
  resolveStoreModules(require('../store/actionPlanTask/constants.js'), 'actionPlanTask/constants.js')
  resolveStoreModules(require('../store/actionPlanTask/mutations.js'), 'actionPlanTask/mutations.js')
  resolveStoreModules(require('../store/actionPlanTask/state.js'), 'actionPlanTask/state.js')
  resolveStoreModules(require('../store/app/actions.js'), 'app/actions.js')
  resolveStoreModules(require('../store/app/constants.js'), 'app/constants.js')
  resolveStoreModules(require('../store/app/mutations.js'), 'app/mutations.js')
  resolveStoreModules(require('../store/app/state.js'), 'app/state.js')
  resolveStoreModules(require('../store/approval/actions.js'), 'approval/actions.js')
  resolveStoreModules(require('../store/approval/constants.js'), 'approval/constants.js')
  resolveStoreModules(require('../store/approval/mutations.js'), 'approval/mutations.js')
  resolveStoreModules(require('../store/approval/state.js'), 'approval/state.js')
  resolveStoreModules(require('../store/approvalComment/actions.js'), 'approvalComment/actions.js')
  resolveStoreModules(require('../store/approvalComment/constants.js'), 'approvalComment/constants.js')
  resolveStoreModules(require('../store/approvalComment/mutations.js'), 'approvalComment/mutations.js')
  resolveStoreModules(require('../store/approvalComment/state.js'), 'approvalComment/state.js')
  resolveStoreModules(require('../store/archive/actions.js'), 'archive/actions.js')
  resolveStoreModules(require('../store/archive/constants.js'), 'archive/constants.js')
  resolveStoreModules(require('../store/archive/mutations.js'), 'archive/mutations.js')
  resolveStoreModules(require('../store/archive/state.js'), 'archive/state.js')
  resolveStoreModules(require('../store/archiveFile/actions.js'), 'archiveFile/actions.js')
  resolveStoreModules(require('../store/archiveFile/constants.js'), 'archiveFile/constants.js')
  resolveStoreModules(require('../store/archiveFile/mutations.js'), 'archiveFile/mutations.js')
  resolveStoreModules(require('../store/archiveFile/state.js'), 'archiveFile/state.js')
  resolveStoreModules(require('../store/archiveFileBookmark/actions.js'), 'archiveFileBookmark/actions.js')
  resolveStoreModules(require('../store/archiveFileBookmark/constants.js'), 'archiveFileBookmark/constants.js')
  resolveStoreModules(require('../store/archiveFileBookmark/mutations.js'), 'archiveFileBookmark/mutations.js')
  resolveStoreModules(require('../store/archiveFileBookmark/state.js'), 'archiveFileBookmark/state.js')
  resolveStoreModules(require('../store/audit/actions.js'), 'audit/actions.js')
  resolveStoreModules(require('../store/audit/constants.js'), 'audit/constants.js')
  resolveStoreModules(require('../store/audit/mutations.js'), 'audit/mutations.js')
  resolveStoreModules(require('../store/audit/state.js'), 'audit/state.js')
  resolveStoreModules(require('../store/auditor/actions.js'), 'auditor/actions.js')
  resolveStoreModules(require('../store/auditor/constants.js'), 'auditor/constants.js')
  resolveStoreModules(require('../store/auditor/mutations.js'), 'auditor/mutations.js')
  resolveStoreModules(require('../store/auditor/state.js'), 'auditor/state.js')
  resolveStoreModules(require('../store/authentication/actions.js'), 'authentication/actions.js')
  resolveStoreModules(require('../store/authentication/constants.js'), 'authentication/constants.js')
  resolveStoreModules(require('../store/authentication/mutations.js'), 'authentication/mutations.js')
  resolveStoreModules(require('../store/authentication/state.js'), 'authentication/state.js')
  resolveStoreModules(require('../store/businessType/actions.js'), 'businessType/actions.js')
  resolveStoreModules(require('../store/businessType/constants.js'), 'businessType/constants.js')
  resolveStoreModules(require('../store/businessType/mutations.js'), 'businessType/mutations.js')
  resolveStoreModules(require('../store/businessType/state.js'), 'businessType/state.js')
  resolveStoreModules(require('../store/company/actions.js'), 'company/actions.js')
  resolveStoreModules(require('../store/company/constants.js'), 'company/constants.js')
  resolveStoreModules(require('../store/company/mutations.js'), 'company/mutations.js')
  resolveStoreModules(require('../store/company/state.js'), 'company/state.js')
  resolveStoreModules(require('../store/companyUnit/actions.js'), 'companyUnit/actions.js')
  resolveStoreModules(require('../store/companyUnit/constants.js'), 'companyUnit/constants.js')
  resolveStoreModules(require('../store/companyUnit/mutations.js'), 'companyUnit/mutations.js')
  resolveStoreModules(require('../store/companyUnit/state.js'), 'companyUnit/state.js')
  resolveStoreModules(require('../store/conversation/actions.js'), 'conversation/actions.js')
  resolveStoreModules(require('../store/conversation/constants.js'), 'conversation/constants.js')
  resolveStoreModules(require('../store/conversation/mutations.js'), 'conversation/mutations.js')
  resolveStoreModules(require('../store/conversation/state.js'), 'conversation/state.js')
  resolveStoreModules(require('../store/country/actions.js'), 'country/actions.js')
  resolveStoreModules(require('../store/country/constants.js'), 'country/constants.js')
  resolveStoreModules(require('../store/country/mutations.js'), 'country/mutations.js')
  resolveStoreModules(require('../store/country/state.js'), 'country/state.js')
  resolveStoreModules(require('../store/cvr/actions.js'), 'cvr/actions.js')
  resolveStoreModules(require('../store/cvr/constants.js'), 'cvr/constants.js')
  resolveStoreModules(require('../store/cvr/getters.js'), 'cvr/getters.js')
  resolveStoreModules(require('../store/cvr/mutations.js'), 'cvr/mutations.js')
  resolveStoreModules(require('../store/cvr/state.js'), 'cvr/state.js')
  resolveStoreModules(require('../store/deviation/actions.js'), 'deviation/actions.js')
  resolveStoreModules(require('../store/deviation/constants.js'), 'deviation/constants.js')
  resolveStoreModules(require('../store/deviation/mutations.js'), 'deviation/mutations.js')
  resolveStoreModules(require('../store/deviation/state.js'), 'deviation/state.js')
  resolveStoreModules(require('../store/deviationFile/actions.js'), 'deviationFile/actions.js')
  resolveStoreModules(require('../store/deviationFile/constants.js'), 'deviationFile/constants.js')
  resolveStoreModules(require('../store/deviationFile/mutations.js'), 'deviationFile/mutations.js')
  resolveStoreModules(require('../store/deviationFile/state.js'), 'deviationFile/state.js')
  resolveStoreModules(require('../store/file/actions.js'), 'file/actions.js')
  resolveStoreModules(require('../store/file/constants.js'), 'file/constants.js')
  resolveStoreModules(require('../store/file/mutations.js'), 'file/mutations.js')
  resolveStoreModules(require('../store/file/state.js'), 'file/state.js')
  resolveStoreModules(require('../store/google/actions.js'), 'google/actions.js')
  resolveStoreModules(require('../store/google/constants.js'), 'google/constants.js')
  resolveStoreModules(require('../store/google/mutations.js'), 'google/mutations.js')
  resolveStoreModules(require('../store/google/state.js'), 'google/state.js')
  resolveStoreModules(require('../store/itGroup/actions.js'), 'itGroup/actions.js')
  resolveStoreModules(require('../store/itGroup/constants.js'), 'itGroup/constants.js')
  resolveStoreModules(require('../store/itGroup/mutations.js'), 'itGroup/mutations.js')
  resolveStoreModules(require('../store/itGroup/state.js'), 'itGroup/state.js')
  resolveStoreModules(require('../store/itGroupItem/actions.js'), 'itGroupItem/actions.js')
  resolveStoreModules(require('../store/itGroupItem/constants.js'), 'itGroupItem/constants.js')
  resolveStoreModules(require('../store/itGroupItem/mutations.js'), 'itGroupItem/mutations.js')
  resolveStoreModules(require('../store/itGroupItem/state.js'), 'itGroupItem/state.js')
  resolveStoreModules(require('../store/itGroupProtection/actions.js'), 'itGroupProtection/actions.js')
  resolveStoreModules(require('../store/itGroupProtection/constants.js'), 'itGroupProtection/constants.js')
  resolveStoreModules(require('../store/itGroupProtection/mutations.js'), 'itGroupProtection/mutations.js')
  resolveStoreModules(require('../store/itGroupProtection/state.js'), 'itGroupProtection/state.js')
  resolveStoreModules(require('../store/itTag/actions.js'), 'itTag/actions.js')
  resolveStoreModules(require('../store/itTag/constants.js'), 'itTag/constants.js')
  resolveStoreModules(require('../store/itTag/mutations.js'), 'itTag/mutations.js')
  resolveStoreModules(require('../store/itTag/state.js'), 'itTag/state.js')
  resolveStoreModules(require('../store/jobTitle/actions.js'), 'jobTitle/actions.js')
  resolveStoreModules(require('../store/jobTitle/constants.js'), 'jobTitle/constants.js')
  resolveStoreModules(require('../store/jobTitle/mutations.js'), 'jobTitle/mutations.js')
  resolveStoreModules(require('../store/jobTitle/state.js'), 'jobTitle/state.js')
  resolveStoreModules(require('../store/log/actions.js'), 'log/actions.js')
  resolveStoreModules(require('../store/log/constants.js'), 'log/constants.js')
  resolveStoreModules(require('../store/log/mutations.js'), 'log/mutations.js')
  resolveStoreModules(require('../store/log/state.js'), 'log/state.js')
  resolveStoreModules(require('../store/notification/actions.js'), 'notification/actions.js')
  resolveStoreModules(require('../store/notification/constants.js'), 'notification/constants.js')
  resolveStoreModules(require('../store/notification/mutations.js'), 'notification/mutations.js')
  resolveStoreModules(require('../store/notification/state.js'), 'notification/state.js')
  resolveStoreModules(require('../store/organization/actions.js'), 'organization/actions.js')
  resolveStoreModules(require('../store/organization/constants.js'), 'organization/constants.js')
  resolveStoreModules(require('../store/organization/mutations.js'), 'organization/mutations.js')
  resolveStoreModules(require('../store/organization/state.js'), 'organization/state.js')
  resolveStoreModules(require('../store/organizationBilling/actions.js'), 'organizationBilling/actions.js')
  resolveStoreModules(require('../store/organizationBilling/constants.js'), 'organizationBilling/constants.js')
  resolveStoreModules(require('../store/organizationBilling/mutations.js'), 'organizationBilling/mutations.js')
  resolveStoreModules(require('../store/organizationBilling/state.js'), 'organizationBilling/state.js')
  resolveStoreModules(require('../store/organizationGroup/actions.js'), 'organizationGroup/actions.js')
  resolveStoreModules(require('../store/organizationGroup/constants.js'), 'organizationGroup/constants.js')
  resolveStoreModules(require('../store/organizationGroup/mutations.js'), 'organizationGroup/mutations.js')
  resolveStoreModules(require('../store/organizationGroup/state.js'), 'organizationGroup/state.js')
  resolveStoreModules(require('../store/organizationReportType/actions.js'), 'organizationReportType/actions.js')
  resolveStoreModules(require('../store/organizationReportType/constants.js'), 'organizationReportType/constants.js')
  resolveStoreModules(require('../store/organizationReportType/mutations.js'), 'organizationReportType/mutations.js')
  resolveStoreModules(require('../store/organizationReportType/state.js'), 'organizationReportType/state.js')
  resolveStoreModules(require('../store/organizationSubscription/actions.js'), 'organizationSubscription/actions.js')
  resolveStoreModules(require('../store/organizationSubscription/constants.js'), 'organizationSubscription/constants.js')
  resolveStoreModules(require('../store/organizationSubscription/mutations.js'), 'organizationSubscription/mutations.js')
  resolveStoreModules(require('../store/organizationSubscription/state.js'), 'organizationSubscription/state.js')
  resolveStoreModules(require('../store/permission/actions.js'), 'permission/actions.js')
  resolveStoreModules(require('../store/permission/constants.js'), 'permission/constants.js')
  resolveStoreModules(require('../store/permission/mutations.js'), 'permission/mutations.js')
  resolveStoreModules(require('../store/permission/state.js'), 'permission/state.js')
  resolveStoreModules(require('../store/procedureAppendix/actions.js'), 'procedureAppendix/actions.js')
  resolveStoreModules(require('../store/procedureAppendix/constants.js'), 'procedureAppendix/constants.js')
  resolveStoreModules(require('../store/procedureAppendix/mutations.js'), 'procedureAppendix/mutations.js')
  resolveStoreModules(require('../store/procedureAppendix/state.js'), 'procedureAppendix/state.js')
  resolveStoreModules(require('../store/procedureTemplate/actions.js'), 'procedureTemplate/actions.js')
  resolveStoreModules(require('../store/procedureTemplate/constants.js'), 'procedureTemplate/constants.js')
  resolveStoreModules(require('../store/procedureTemplate/mutations.js'), 'procedureTemplate/mutations.js')
  resolveStoreModules(require('../store/procedureTemplate/state.js'), 'procedureTemplate/state.js')
  resolveStoreModules(require('../store/report/actions.js'), 'report/actions.js')
  resolveStoreModules(require('../store/report/constants.js'), 'report/constants.js')
  resolveStoreModules(require('../store/report/mutations.js'), 'report/mutations.js')
  resolveStoreModules(require('../store/report/state.js'), 'report/state.js')
  resolveStoreModules(require('../store/reportCategory/actions.js'), 'reportCategory/actions.js')
  resolveStoreModules(require('../store/reportCategory/constants.js'), 'reportCategory/constants.js')
  resolveStoreModules(require('../store/reportCategory/mutations.js'), 'reportCategory/mutations.js')
  resolveStoreModules(require('../store/reportCategory/state.js'), 'reportCategory/state.js')
  resolveStoreModules(require('../store/reportProcedure/actions.js'), 'reportProcedure/actions.js')
  resolveStoreModules(require('../store/reportProcedure/constants.js'), 'reportProcedure/constants.js')
  resolveStoreModules(require('../store/reportProcedure/mutations.js'), 'reportProcedure/mutations.js')
  resolveStoreModules(require('../store/reportProcedure/state.js'), 'reportProcedure/state.js')
  resolveStoreModules(require('../store/reportProcedureAppendix/actions.js'), 'reportProcedureAppendix/actions.js')
  resolveStoreModules(require('../store/reportProcedureAppendix/constants.js'), 'reportProcedureAppendix/constants.js')
  resolveStoreModules(require('../store/reportProcedureAppendix/mutations.js'), 'reportProcedureAppendix/mutations.js')
  resolveStoreModules(require('../store/reportProcedureAppendix/state.js'), 'reportProcedureAppendix/state.js')
  resolveStoreModules(require('../store/reportProcedureAppendixSection/actions.js'), 'reportProcedureAppendixSection/actions.js')
  resolveStoreModules(require('../store/reportProcedureAppendixSection/constants.js'), 'reportProcedureAppendixSection/constants.js')
  resolveStoreModules(require('../store/reportProcedureAppendixSection/mutations.js'), 'reportProcedureAppendixSection/mutations.js')
  resolveStoreModules(require('../store/reportProcedureAppendixSection/state.js'), 'reportProcedureAppendixSection/state.js')
  resolveStoreModules(require('../store/reportProcedureContent/actions.js'), 'reportProcedureContent/actions.js')
  resolveStoreModules(require('../store/reportProcedureContent/constants.js'), 'reportProcedureContent/constants.js')
  resolveStoreModules(require('../store/reportProcedureContent/mutations.js'), 'reportProcedureContent/mutations.js')
  resolveStoreModules(require('../store/reportProcedureContent/state.js'), 'reportProcedureContent/state.js')
  resolveStoreModules(require('../store/reportProcedureContentSection/actions.js'), 'reportProcedureContentSection/actions.js')
  resolveStoreModules(require('../store/reportProcedureContentSection/constants.js'), 'reportProcedureContentSection/constants.js')
  resolveStoreModules(require('../store/reportProcedureContentSection/mutations.js'), 'reportProcedureContentSection/mutations.js')
  resolveStoreModules(require('../store/reportProcedureContentSection/state.js'), 'reportProcedureContentSection/state.js')
  resolveStoreModules(require('../store/reportSection/actions.js'), 'reportSection/actions.js')
  resolveStoreModules(require('../store/reportSection/constants.js'), 'reportSection/constants.js')
  resolveStoreModules(require('../store/reportSection/mutations.js'), 'reportSection/mutations.js')
  resolveStoreModules(require('../store/reportSection/state.js'), 'reportSection/state.js')
  resolveStoreModules(require('../store/reportTemplate/actions.js'), 'reportTemplate/actions.js')
  resolveStoreModules(require('../store/reportTemplate/constants.js'), 'reportTemplate/constants.js')
  resolveStoreModules(require('../store/reportTemplate/mutations.js'), 'reportTemplate/mutations.js')
  resolveStoreModules(require('../store/reportTemplate/state.js'), 'reportTemplate/state.js')
  resolveStoreModules(require('../store/reportType/actions.js'), 'reportType/actions.js')
  resolveStoreModules(require('../store/reportType/constants.js'), 'reportType/constants.js')
  resolveStoreModules(require('../store/reportType/mutations.js'), 'reportType/mutations.js')
  resolveStoreModules(require('../store/reportType/state.js'), 'reportType/state.js')
  resolveStoreModules(require('../store/riskLevel/actions.js'), 'riskLevel/actions.js')
  resolveStoreModules(require('../store/riskLevel/constants.js'), 'riskLevel/constants.js')
  resolveStoreModules(require('../store/riskLevel/mutations.js'), 'riskLevel/mutations.js')
  resolveStoreModules(require('../store/riskLevel/state.js'), 'riskLevel/state.js')
  resolveStoreModules(require('../store/role/actions.js'), 'role/actions.js')
  resolveStoreModules(require('../store/role/constants.js'), 'role/constants.js')
  resolveStoreModules(require('../store/role/mutations.js'), 'role/mutations.js')
  resolveStoreModules(require('../store/role/state.js'), 'role/state.js')
  resolveStoreModules(require('../store/sectionType/actions.js'), 'sectionType/actions.js')
  resolveStoreModules(require('../store/sectionType/constants.js'), 'sectionType/constants.js')
  resolveStoreModules(require('../store/sectionType/mutations.js'), 'sectionType/mutations.js')
  resolveStoreModules(require('../store/sectionType/state.js'), 'sectionType/state.js')
  resolveStoreModules(require('../store/snippet/actions.js'), 'snippet/actions.js')
  resolveStoreModules(require('../store/snippet/constants.js'), 'snippet/constants.js')
  resolveStoreModules(require('../store/snippet/mutations.js'), 'snippet/mutations.js')
  resolveStoreModules(require('../store/snippet/state.js'), 'snippet/state.js')
  resolveStoreModules(require('../store/statistics/actions.js'), 'statistics/actions.js')
  resolveStoreModules(require('../store/statistics/constants.js'), 'statistics/constants.js')
  resolveStoreModules(require('../store/statistics/mutations.js'), 'statistics/mutations.js')
  resolveStoreModules(require('../store/statistics/state.js'), 'statistics/state.js')
  resolveStoreModules(require('../store/subscription/actions.js'), 'subscription/actions.js')
  resolveStoreModules(require('../store/subscription/constants.js'), 'subscription/constants.js')
  resolveStoreModules(require('../store/subscription/mutations.js'), 'subscription/mutations.js')
  resolveStoreModules(require('../store/subscription/state.js'), 'subscription/state.js')
  resolveStoreModules(require('../store/subscriptionKey/actions.js'), 'subscriptionKey/actions.js')
  resolveStoreModules(require('../store/subscriptionKey/constants.js'), 'subscriptionKey/constants.js')
  resolveStoreModules(require('../store/subscriptionKey/mutations.js'), 'subscriptionKey/mutations.js')
  resolveStoreModules(require('../store/subscriptionKey/state.js'), 'subscriptionKey/state.js')
  resolveStoreModules(require('../store/subscriptionOrganization/actions.js'), 'subscriptionOrganization/actions.js')
  resolveStoreModules(require('../store/subscriptionOrganization/constants.js'), 'subscriptionOrganization/constants.js')
  resolveStoreModules(require('../store/subscriptionOrganization/mutations.js'), 'subscriptionOrganization/mutations.js')
  resolveStoreModules(require('../store/subscriptionOrganization/state.js'), 'subscriptionOrganization/state.js')
  resolveStoreModules(require('../store/suggestion/actions.js'), 'suggestion/actions.js')
  resolveStoreModules(require('../store/suggestion/constants.js'), 'suggestion/constants.js')
  resolveStoreModules(require('../store/suggestion/mutations.js'), 'suggestion/mutations.js')
  resolveStoreModules(require('../store/suggestion/state.js'), 'suggestion/state.js')
  resolveStoreModules(require('../store/suggestionFile/actions.js'), 'suggestionFile/actions.js')
  resolveStoreModules(require('../store/suggestionFile/constants.js'), 'suggestionFile/constants.js')
  resolveStoreModules(require('../store/suggestionFile/mutations.js'), 'suggestionFile/mutations.js')
  resolveStoreModules(require('../store/suggestionFile/state.js'), 'suggestionFile/state.js')
  resolveStoreModules(require('../store/table/actions.js'), 'table/actions.js')
  resolveStoreModules(require('../store/table/constants.js'), 'table/constants.js')
  resolveStoreModules(require('../store/table/mutations.js'), 'table/mutations.js')
  resolveStoreModules(require('../store/table/state.js'), 'table/state.js')
  resolveStoreModules(require('../store/task/actions.js'), 'task/actions.js')
  resolveStoreModules(require('../store/task/constants.js'), 'task/constants.js')
  resolveStoreModules(require('../store/task/mutations.js'), 'task/mutations.js')
  resolveStoreModules(require('../store/task/state.js'), 'task/state.js')
  resolveStoreModules(require('../store/taskComment/actions.js'), 'taskComment/actions.js')
  resolveStoreModules(require('../store/taskComment/constants.js'), 'taskComment/constants.js')
  resolveStoreModules(require('../store/taskComment/mutations.js'), 'taskComment/mutations.js')
  resolveStoreModules(require('../store/taskComment/state.js'), 'taskComment/state.js')
  resolveStoreModules(require('../store/taskFile/actions.js'), 'taskFile/actions.js')
  resolveStoreModules(require('../store/taskFile/constants.js'), 'taskFile/constants.js')
  resolveStoreModules(require('../store/taskFile/mutations.js'), 'taskFile/mutations.js')
  resolveStoreModules(require('../store/taskFile/state.js'), 'taskFile/state.js')
  resolveStoreModules(require('../store/twoFactor/actions.js'), 'twoFactor/actions.js')
  resolveStoreModules(require('../store/twoFactor/constants.js'), 'twoFactor/constants.js')
  resolveStoreModules(require('../store/twoFactor/mutations.js'), 'twoFactor/mutations.js')
  resolveStoreModules(require('../store/twoFactor/state.js'), 'twoFactor/state.js')
  resolveStoreModules(require('../store/unit/actions.js'), 'unit/actions.js')
  resolveStoreModules(require('../store/unit/constants.js'), 'unit/constants.js')
  resolveStoreModules(require('../store/unit/mutations.js'), 'unit/mutations.js')
  resolveStoreModules(require('../store/unit/state.js'), 'unit/state.js')
  resolveStoreModules(require('../store/unitCategory/actions.js'), 'unitCategory/actions.js')
  resolveStoreModules(require('../store/unitCategory/constants.js'), 'unitCategory/constants.js')
  resolveStoreModules(require('../store/unitCategory/mutations.js'), 'unitCategory/mutations.js')
  resolveStoreModules(require('../store/unitCategory/state.js'), 'unitCategory/state.js')
  resolveStoreModules(require('../store/unitFile/actions.js'), 'unitFile/actions.js')
  resolveStoreModules(require('../store/unitFile/constants.js'), 'unitFile/constants.js')
  resolveStoreModules(require('../store/unitFile/mutations.js'), 'unitFile/mutations.js')
  resolveStoreModules(require('../store/unitFile/state.js'), 'unitFile/state.js')
  resolveStoreModules(require('../store/user/actions.js'), 'user/actions.js')
  resolveStoreModules(require('../store/user/constants.js'), 'user/constants.js')
  resolveStoreModules(require('../store/user/mutations.js'), 'user/mutations.js')
  resolveStoreModules(require('../store/user/state.js'), 'user/state.js')
  resolveStoreModules(require('../store/userModelRead/actions.js'), 'userModelRead/actions.js')
  resolveStoreModules(require('../store/userModelRead/constants.js'), 'userModelRead/constants.js')
  resolveStoreModules(require('../store/userModelRead/mutations.js'), 'userModelRead/mutations.js')
  resolveStoreModules(require('../store/userModelRead/state.js'), 'userModelRead/state.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
