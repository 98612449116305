
export default {
  name: 'NowContainer',
  props: {
    padding: {
      type: Boolean,
      default: true
    }
  }
}
