// APPROVAL CONSTANTS
export const REPORT_PROCEDURE_CONTENT_SECTION_CREATE_PENDING = 'REPORT_PROCEDURE_CONTENT_SECTION_CREATE_PENDING'
export const REPORT_PROCEDURE_CONTENT_SECTION_CREATE_SUCCESS = 'REPORT_PROCEDURE_CONTENT_SECTION_CREATE_SUCCESS'
export const REPORT_PROCEDURE_CONTENT_SECTION_CREATE_FAILURE = 'REPORT_PROCEDURE_CONTENT_SECTION_CREATE_FAILURE'

export const REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_PENDING = 'REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_PENDING'
export const REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_SUCCESS = 'REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_SUCCESS'
export const REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_FAILURE = 'REPORT_PROCEDURE_CONTENT_SECTION_UPDATE_FAILURE'

export const REPORT_PROCEDURE_CONTENT_SECTION_DELETE_PENDING = 'REPORT_PROCEDURE_CONTENT_SECTION_DELETE_PENDING'
export const REPORT_PROCEDURE_CONTENT_SECTION_DELETE_SUCCESS = 'REPORT_PROCEDURE_CONTENT_SECTION_DELETE_SUCCESS'
export const REPORT_PROCEDURE_CONTENT_SECTION_DELETE_FAILURE = 'REPORT_PROCEDURE_CONTENT_SECTION_DELETE_FAILURE'

export const REPORT_PROCEDURE_CONTENT_SECTION_BATCH_PENDING = 'REPORT_PROCEDURE_CONTENT_SECTION_BATCH_PENDING'
export const REPORT_PROCEDURE_CONTENT_SECTION_BATCH_SUCCESS = 'REPORT_PROCEDURE_CONTENT_SECTION_BATCH_SUCCESS'
export const REPORT_PROCEDURE_CONTENT_SECTION_BATCH_FAILURE = 'REPORT_PROCEDURE_CONTENT_SECTION_BATCH_FAILURE'

export const REPORT_PROCEDURE_CONTENT_SECTION_ORDER_PENDING = 'REPORT_PROCEDURE_CONTENT_SECTION_ORDER_PENDING'
export const REPORT_PROCEDURE_CONTENT_SECTION_ORDER_SUCCESS = 'REPORT_PROCEDURE_CONTENT_SECTION_ORDER_SUCCESS'
export const REPORT_PROCEDURE_CONTENT_SECTION_ORDER_FAILURE = 'REPORT_PROCEDURE_CONTENT_SECTION_ORDER_FAILURE'

export const REPORT_PROCEDURE_CONTENT_SECTION_RESET_STORE = 'REPORT_PROCEDURE_CONTENT_SECTION_RESET_STORE'
