export default () => {
  return {
    index: {
      item: null,
      loading: false,
      error: null
    },
    report: {
      items: [],
      loading: false,
      error: null
    },
    count: {
      item: null,
      loading: false,
      error: null
    },
    calendar: {
      item: null,
      loading: false,
      error: null
    },
    show: {
      item: null,
      loading: false,
      error: null
    },
    create: {
      item: null,
      loading: false,
      error: null
    },
    update: {
      loading: false,
      error: null
    },
    complete: {
      loading: false,
      error: null
    },
    delete: {
      loading: false,
      error: null
    },
    download: {
      loading: false,
      error: null
    },
    type: {
      index: {
        items: [],
        loading: false,
        error: null
      }
    }
  }
}
