
export default {
  name: 'PaginationMixin',
  data () {
    return {
      page: 1,
      perPage: 10,
      query: this.$route.query // Set default to route queries
    }
  },
  computed: {
    paginationQuery () {
      return { ...this.query, page: this.page, 'per-page': this.perPage }
    }
  },
  watch: {
    query: {
      handler () {
        this.syncQueryParams()
        if (this.page === 1) {
          // If not updating the page then call fetch
          this.fetch()
        } else {
          // Updating the page will trigger page watcher
          this.page = 1
        }
      },
      deep: true
    },
    perPage () {
      this.syncQueryParams()
      if (this.page === 1) {
        // If not updating the page then call fetch
        this.fetch()
      } else {
        // Updating the page will trigger page watcher
        this.page = 1
      }
    },
    page () {
      this.syncQueryParams()
      this.fetch()
    }
  },
  methods: {
    setPerPage (perPage) {
      this.perPage = perPage
    },
    setPaginationMeta (data) {
      if (data) {
        this.page = data.current_page || 1
      }
    },
    setPaginationQuery (data) {
      this.query = { ...this.query, ...data }
    },
    syncQueryParams () {
      this.$router.push({ path: this.$route.path, query: this.paginationQuery })
    }
  }
}
