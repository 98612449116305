export default () => {
  return {
    register: {
      loading: false,
      error: null
    },
    verify: {
      loading: false,
      error: null
    },
    notification: {
      loading: false,
      error: null
    },
    forgot: {
      loading: false,
      error: null
    },
    reset: {
      loading: false,
      error: null
    },
    activate: {
      loading: false,
      error: null
    }
  }
}
