// APPROVAL CONSTANTS
export const JOB_TITLE_INDEX_PENDING = 'JOB_TITLE_INDEX_PENDING'
export const JOB_TITLE_INDEX_SUCCESS = 'JOB_TITLE_INDEX_SUCCESS'
export const JOB_TITLE_INDEX_FAILURE = 'JOB_TITLE_INDEX_FAILURE'
export const JOB_TITLE_INDEX_NO_CONTENT = 'JOB_TITLE_INDEX_NO_CONTENT'

export const JOB_TITLE_CREATE_PENDING = 'JOB_TITLE_CREATE_PENDING'
export const JOB_TITLE_CREATE_SUCCESS = 'JOB_TITLE_CREATE_SUCCESS'
export const JOB_TITLE_CREATE_FAILURE = 'JOB_TITLE_CREATE_FAILURE'

export const JOB_TITLE_UPDATE_PENDING = 'JOB_TITLE_UPDATE_PENDING'
export const JOB_TITLE_UPDATE_SUCCESS = 'JOB_TITLE_UPDATE_SUCCESS'
export const JOB_TITLE_UPDATE_FAILURE = 'JOB_TITLE_UPDATE_FAILURE'

export const JOB_TITLE_DELETE_PENDING = 'JOB_TITLE_DELETE_PENDING'
export const JOB_TITLE_DELETE_SUCCESS = 'JOB_TITLE_DELETE_SUCCESS'
export const JOB_TITLE_DELETE_FAILURE = 'JOB_TITLE_DELETE_FAILURE'

export const JOB_TITLE_RESET_STORE = 'JOB_TITLE_RESET_STORE'
