import locales from '@/constants/locales'

export default ({ i18n, $auth, $moment }) => {
  // If logged in check that the locale is same as user.locale
  if ($auth.loggedIn && $auth.user.locale !== i18n.locale) {
    i18n.setLocale($auth.user.locale)
  }

  // If moment locale differs then set to correct locale
  if (locales[$moment.locale()] && locales[$moment.locale()].code !== i18n.locale) {
    $moment.locale(locales[i18n.locale].moment)
  }

  // Add on language switch listener to update moment as well
  i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    $moment.locale(locales[newLocale].moment)
  }
}
