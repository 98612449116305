import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createConversation ({ commit }, payload) {
    try {
      commit(C.CONVERSATION_CREATE_PENDING)
      const res = await this.$axios.post('conversations', payload)
      commit(C.CONVERSATION_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.CONVERSATION_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async showConversationByModel ({ commit }, payload) {
    try {
      commit(C.CONVERSATION_SHOW_PENDING)
      const res = await this.$axios.post('conversations/by-model', payload)
      commit(C.CONVERSATION_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.CONVERSATION_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createConversationMessage ({ commit }, payload) {
    try {
      commit(C.CONVERSATION_MESSAGE_PENDING)
      const res = await this.$axios.post('conversation-messages', payload)
      commit(C.CONVERSATION_MESSAGE_SUCCESS, res.data)
    } catch (err) {
      commit(C.CONVERSATION_MESSAGE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetConversationStore ({ commit }) {
    commit(C.CONVERSATION_RESET_STORE)
  }
}
