import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.REPORT_PROCEDURE_CONTENT_SECTION_PENDING] (state) {
    set(state, 'section', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_CONTENT_SECTION_SUCCESS] (state, payload) {
    set(state, 'section', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_CONTENT_SECTION_FAILURE] (state, payload) {
    set(state, 'section', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_CONTENT_SECTION_NO_CONTENT] (state, payload) {
    set(state, 'section', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_PROCEDURE_CONTENT_SEARCH_PENDING] (state) {
    set(state, 'search', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_CONTENT_SEARCH_SUCCESS] (state, payload) {
    set(state, 'search', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_CONTENT_SEARCH_FAILURE] (state, payload) {
    set(state, 'search', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_CONTENT_SEARCH_NO_CONTENT] (state, payload) {
    set(state, 'search', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_PROCEDURE_CONTENT_CREATE_PENDING] (state) {
    set(state, 'create', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_CONTENT_CREATE_SUCCESS] (state, payload) {
    set(state, 'create', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_CONTENT_CREATE_FAILURE] (state, payload) {
    set(state, 'create', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_PROCEDURE_CONTENT_ASSIGNEE_PENDING] (state) {
    set(state, 'assignee', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_CONTENT_ASSIGNEE_SUCCESS] (state, payload) {
    set(state, 'assignee', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.REPORT_PROCEDURE_CONTENT_ASSIGNEE_FAILURE] (state, payload) {
    set(state, 'assignee', {
      loading: false,
      error: payload
    })
  },

  /**
 * @param state
 */
  [C.REPORT_PROCEDURE_CONTENT_PUBLISH_PENDING] (state) {
    set(state, 'publish', {
      loading: true,
      error: null
    })
  },

  /**
 * @param state
 * @param payload
 */
  [C.REPORT_PROCEDURE_CONTENT_PUBLISH_SUCCESS] (state, payload) {
    set(state, 'publish', {
      loading: false,
      error: null
    })
  },

  /**
 * @param state
 * @param payload
 */
  [C.REPORT_PROCEDURE_CONTENT_PUBLISH_FAILURE] (state, payload) {
    set(state, 'publish', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.REPORT_PROCEDURE_CONTENT_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
