// PROCEDURE APPENDIX CONSTANTS
export const PROCEDURE_APPENDIX_INDEX_PENDING = 'PROCEDURE_APPENDIX_INDEX_PENDING'
export const PROCEDURE_APPENDIX_INDEX_SUCCESS = 'PROCEDURE_APPENDIX_INDEX_SUCCESS'
export const PROCEDURE_APPENDIX_INDEX_FAILURE = 'PROCEDURE_APPENDIX_INDEX_FAILURE'
export const PROCEDURE_APPENDIX_INDEX_NO_CONTENT = 'PROCEDURE_APPENDIX_INDEX_NO_CONTENT'

export const PROCEDURE_APPENDIX_SHOW_PENDING = 'PROCEDURE_APPENDIX_SHOW_PENDING'
export const PROCEDURE_APPENDIX_SHOW_SUCCESS = 'PROCEDURE_APPENDIX_SHOW_SUCCESS'
export const PROCEDURE_APPENDIX_SHOW_FAILURE = 'PROCEDURE_APPENDIX_SHOW_FAILURE'

export const PROCEDURE_APPENDIX_RESET_STORE = 'PROCEDURE_APPENDIX_RESET_STORE'
