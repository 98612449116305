export default () => {
  return {
    index: {
      items: [],
      loading: false,
      error: null
    },
    templates: {
      items: [],
      loading: false,
      error: null
    },
    notifications: {
      item: null,
      loading: false,
      error: null
    },
    notificationsUnreadCount: {
      item: null,
      loading: false,
      error: null
    }
  }
}
