import * as C from './constants'

export default {

  /**
   * @param commit
   * @param $config
   * @returns {Promise<void>}
   */
  async getReportSectionIndex ({ commit }) {
    try {
      commit(C.REPORT_SECTION_INDEX_PENDING)
      const res = await this.$axios.get('report-sections')
      if (res.status === 200 && res.data) {
        commit(C.REPORT_SECTION_INDEX_SUCCESS, res.data)
      } else {
        commit(C.REPORT_SECTION_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.REPORT_SECTION_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getReportSectionIndexByReportType ({ commit }, payload) {
    try {
      commit(C.REPORT_SECTION_REPORT_TYPE_PENDING)
      const res = await this.$axios.get(`report-sections/${payload}`)
      if (res.status === 200 && res.data) {
        commit(C.REPORT_SECTION_REPORT_TYPE_SUCCESS, res.data)
      } else {
        commit(C.REPORT_SECTION_REPORT_TYPE_NO_CONTENT)
      }
    } catch (err) {
      commit(C.REPORT_SECTION_REPORT_TYPE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetReportSectionStore ({ commit }) {
    commit(C.REPORT_SECTION_RESET_STORE)
  }
}
