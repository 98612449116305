import * as C from './constants'

export default {

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getCompanyIndex ({ commit }) {
    try {
      commit(C.COMPANY_INDEX_PENDING)
      const res = await this.$axios.get('companies')
      if (res.status === 200 && res.data) {
        commit(C.COMPANY_INDEX_SUCCESS, res.data)
      } else {
        commit(C.COMPANY_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.COMPANY_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getCompanyIndexFlatten ({ commit }) {
    try {
      commit(C.COMPANY_INDEX_FLATTEN_PENDING)
      const res = await this.$axios.get('companies/flatten')
      if (res.status === 200 && res.data) {
        commit(C.COMPANY_INDEX_FLATTEN_SUCCESS, res.data)
      } else {
        commit(C.COMPANY_INDEX_FLATTEN_NO_CONTENT)
      }
    } catch (err) {
      commit(C.COMPANY_INDEX_FLATTEN_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createCompany ({ commit }, payload) {
    try {
      commit(C.COMPANY_CREATE_PENDING)
      const res = await this.$axios.post('companies', payload)
      commit(C.COMPANY_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.COMPANY_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createCustomCompany ({ commit }, payload) {
    try {
      commit(C.COMPANY_CREATE_CUSTOM_PENDING)
      const res = await this.$axios.post('companies/custom', payload)
      commit(C.COMPANY_CREATE_CUSTOM_SUCCESS, res.data)
    } catch (err) {
      commit(C.COMPANY_CREATE_CUSTOM_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateCompany ({ commit }, payload) {
    try {
      commit(C.COMPANY_UPDATE_PENDING)
      const res = await this.$axios.put(`companies/${payload.id}`, payload)
      commit(C.COMPANY_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.COMPANY_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateCompanyParent ({ commit }, payload) {
    try {
      commit(C.COMPANY_UPDATE_PENDING)
      const res = await this.$axios.put(`companies/${payload.id}/parent`, payload)
      commit(C.COMPANY_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.COMPANY_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async syncCompany ({ commit }, payload) {
    try {
      commit(C.COMPANY_SYNC_PENDING)
      const res = await this.$axios.get(`companies/${payload.id}/sync`)
      commit(C.COMPANY_SYNC_SUCCESS, res.data)
    } catch (err) {
      commit(C.COMPANY_SYNC_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteCompany ({ commit }, payload) {
    try {
      commit(C.COMPANY_DELETE_PENDING)
      const res = await this.$axios.delete(`companies/${payload}`)
      commit(C.COMPANY_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.COMPANY_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetCompanyStore ({ commit }) {
    commit(C.COMPANY_RESET_STORE)
  }
}
