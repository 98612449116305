// SUGGESTION CONSTANTS
export const SUGGESTION_FILE_INDEX_PENDING = 'SUGGESTION_FILE_INDEX_PENDING'
export const SUGGESTION_FILE_INDEX_SUCCESS = 'SUGGESTION_FILE_INDEX_SUCCESS'
export const SUGGESTION_FILE_INDEX_FAILURE = 'SUGGESTION_FILE_INDEX_FAILURE'
export const SUGGESTION_FILE_INDEX_NO_CONTENT = 'SUGGESTION_FILE_INDEX_NO_CONTENT'

export const SUGGESTION_FILE_CREATE_PENDING = 'SUGGESTION_FILE_CREATE_PENDING'
export const SUGGESTION_FILE_CREATE_SUCCESS = 'SUGGESTION_FILE_CREATE_SUCCESS'
export const SUGGESTION_FILE_CREATE_FAILURE = 'SUGGESTION_FILE_CREATE_FAILURE'

export const SUGGESTION_FILE_DELETE_PENDING = 'SUGGESTION_FILE_DELETE_PENDING'
export const SUGGESTION_FILE_DELETE_SUCCESS = 'SUGGESTION_FILE_DELETE_SUCCESS'
export const SUGGESTION_FILE_DELETE_FAILURE = 'SUGGESTION_FILE_DELETE_FAILURE'

export const SUGGESTION_FILE_RESET_STORE = 'SUGGESTION_FILE_RESET_STORE'
