export default () => {
  return {
    enable: {
      item: null,
      loading: false,
      error: null
    },
    disable: {
      loading: false,
      error: null
    },
    recoveryCodes: {
      items: [],
      loading: false,
      error: null
    },
    recoveryCodesCreate: {
      item: null,
      loading: false,
      error: null
    },
    qrCode: {
      item: null,
      loading: false,
      error: null
    },
    passwordConfirmation: {
      item: null,
      loading: false,
      error: null
    },
    confirmation: {
      item: null,
      loading: false,
      error: null
    },
    passwordStatus: {
      item: null,
      loading: false,
      error: null
    }
  }
}
