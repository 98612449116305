import * as C from './constants'

export default {

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getPermissionIndex ({ commit }) {
    try {
      commit(C.PERMISSION_INDEX_PENDING)
      const res = await this.$axios.get('permissions')
      if (res.status === 200) {
        commit(C.PERMISSION_INDEX_SUCCESS, res.data)
      } else {
        commit(C.PERMISSION_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.PERMISSION_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async addPermissionToUser ({ commit }, payload) {
    try {
      commit(C.PERMISSION_ADD_PENDING)
      const res = await this.$axios.post('permissions/add', payload)
      commit(C.PERMISSION_ADD_SUCCESS, res.data)
    } catch (err) {
      commit(C.PERMISSION_ADD_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async revokePermissionFromUser ({ commit }, payload) {
    try {
      commit(C.PERMISSION_REVOKE_PENDING)
      const res = await this.$axios.post('permissions/revoke', payload)
      commit(C.PERMISSION_REVOKE_SUCCESS, res.data)
    } catch (err) {
      commit(C.PERMISSION_REVOKE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetPermissionStore ({ commit }) {
    commit(C.PERMISSION_RESET_STORE)
  }
}
