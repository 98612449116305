import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_SET] (state, payload) {
    set(state, 'organization', payload)
  },

  /**
   * @param state
   */
  [C.ORGANIZATION_INDEX_PENDING] (state) {
    set(state, 'index', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_INDEX_SUCCESS] (state, payload) {
    set(state, 'index', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_INDEX_FAILURE] (state, payload) {
    set(state, 'index', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_INDEX_NO_CONTENT] (state, payload) {
    set(state, 'index', {
      items: [],
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.ORGANIZATION_CREATE_PENDING] (state) {
    set(state, 'create', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_CREATE_SUCCESS] (state, payload) {
    set(state, 'create', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_CREATE_FAILURE] (state, payload) {
    set(state, 'create', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ORGANIZATION_UPDATE_PENDING] (state) {
    set(state, 'update', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_UPDATE_SUCCESS] (state, payload) {
    set(state, 'update', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_UPDATE_FAILURE] (state, payload) {
    set(state, 'update', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ORGANIZATION_DELETE_PENDING] (state) {
    set(state, 'delete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.ORGANIZATION_DELETE_SUCCESS] (state) {
    set(state, 'delete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_DELETE_FAILURE] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ORGANIZATION_USERS_PENDING] (state) {
    set(state, 'users', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_USERS_SUCCESS] (state, payload) {
    set(state, 'users', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_USERS_FAILURE] (state, payload) {
    set(state, 'users', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ORGANIZATION_USERS_NO_CONTENT] (state) {
    set(state, 'users', {
      items: [],
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.ORGANIZATION_SESSION_PENDING] (state) {
    set(state, 'session', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_SESSION_SUCCESS] (state, payload) {
    set(state, 'session', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_SESSION_FAILURE] (state, payload) {
    set(state, 'session', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ORGANIZATION_ONBOARDING_PENDING] (state) {
    set(state, 'onboarding', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_ONBOARDING_SUCCESS] (state, payload) {
    set(state, 'onboarding', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_ONBOARDING_FAILURE] (state, payload) {
    set(state, 'onboarding', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ORGANIZATION_SUBSCRIPTION_PENDING] (state) {
    set(state, 'subscription', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_SUBSCRIPTION_SUCCESS] (state, payload) {
    set(state, 'subscription', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_SUBSCRIPTION_FAILURE] (state, payload) {
    set(state, 'subscription', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ORGANIZATION_SUBSCRIPTION_STATS_PENDING] (state) {
    set(state, 'subscriptionStats', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_SUBSCRIPTION_STATS_SUCCESS] (state, payload) {
    set(state, 'subscriptionStats', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ORGANIZATION_SUBSCRIPTION_STATS_FAILURE] (state, payload) {
    set(state, 'subscriptionStats', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ORGANIZATION_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
