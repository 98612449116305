import * as C from './constants'

export default {

  /**
   * @param commit
   * @param $config
   * @returns {Promise<void>}
   */
  async getOrganizationSubscriptionIndex ({ commit }) {
    try {
      commit(C.ORGANIZATION_SUBSCRIPTION_INDEX_PENDING)
      const res = await this.$axios.get('organization-subscriptions')
      if (res.status === 200 && res.data) {
        commit(C.ORGANIZATION_SUBSCRIPTION_INDEX_SUCCESS, res.data)
      } else {
        commit(C.ORGANIZATION_SUBSCRIPTION_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.ORGANIZATION_SUBSCRIPTION_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getActiveOrganizationSubscription ({ commit }, payload) {
    try {
      commit(C.ORGANIZATION_SUBSCRIPTION_ACTIVE_PENDING)
      const res = await this.$axios.get('organization-subscriptions/active')
      if (res.status === 200) {
        commit(C.ORGANIZATION_SUBSCRIPTION_ACTIVE_SUCCESS, res.data)
      } else {
        commit(C.ORGANIZATION_SUBSCRIPTION_ACTIVE_NO_CONTENT)
      }
    } catch (err) {
      commit(C.ORGANIZATION_SUBSCRIPTION_ACTIVE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createOrganizationSubscription ({ commit }, payload) {
    try {
      commit(C.ORGANIZATION_SUBSCRIPTION_CREATE_PENDING)
      const res = await this.$axios.post('organization-subscriptions', payload)
      commit(C.ORGANIZATION_SUBSCRIPTION_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ORGANIZATION_SUBSCRIPTION_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateOrganizationSubscription ({ commit }, payload) {
    try {
      commit(C.ORGANIZATION_SUBSCRIPTION_UPDATE_PENDING)
      const res = await this.$axios.put(`organization-subscriptions/${payload.id}`, payload)
      commit(C.ORGANIZATION_SUBSCRIPTION_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ORGANIZATION_SUBSCRIPTION_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetOrganizationSubscriptionStore ({ commit }) {
    commit(C.ORGANIZATION_SUBSCRIPTION_RESET_STORE)
  }
}
