// ORGANIZATION CONSTANTS

export const ORGANIZATION_GROUP_INDEX_PENDING = 'ORGANIZATION_GROUP_INDEX_PENDING'
export const ORGANIZATION_GROUP_INDEX_SUCCESS = 'ORGANIZATION_GROUP_INDEX_SUCCESS'
export const ORGANIZATION_GROUP_INDEX_FAILURE = 'ORGANIZATION_GROUP_INDEX_FAILURE'
export const ORGANIZATION_GROUP_INDEX_NO_CONTENT = 'ORGANIZATION_GROUP_INDEX_NO_CONTENT'

export const ORGANIZATION_GROUP_USER_PENDING = 'ORGANIZATION_GROUP_USER_PENDING'
export const ORGANIZATION_GROUP_USER_SUCCESS = 'ORGANIZATION_GROUP_USER_SUCCESS'
export const ORGANIZATION_GROUP_USER_FAILURE = 'ORGANIZATION_GROUP_USER_FAILURE'
export const ORGANIZATION_GROUP_USER_NO_CONTENT = 'ORGANIZATION_GROUP_USER_NO_CONTENT'

export const ORGANIZATION_GROUP_CREATE_PENDING = 'ORGANIZATION_GROUP_CREATE_PENDING'
export const ORGANIZATION_GROUP_CREATE_SUCCESS = 'ORGANIZATION_GROUP_CREATE_SUCCESS'
export const ORGANIZATION_GROUP_CREATE_FAILURE = 'ORGANIZATION_GROUP_CREATE_FAILURE'

export const ORGANIZATION_GROUP_SHOW_PENDING = 'ORGANIZATION_GROUP_SHOW_PENDING'
export const ORGANIZATION_GROUP_SHOW_SUCCESS = 'ORGANIZATION_GROUP_SHOW_SUCCESS'
export const ORGANIZATION_GROUP_SHOW_FAILURE = 'ORGANIZATION_GROUP_SHOW_FAILURE'

export const ORGANIZATION_GROUP_UPDATE_PENDING = 'ORGANIZATION_GROUP_UPDATE_PENDING'
export const ORGANIZATION_GROUP_UPDATE_SUCCESS = 'ORGANIZATION_GROUP_UPDATE_SUCCESS'
export const ORGANIZATION_GROUP_UPDATE_FAILURE = 'ORGANIZATION_GROUP_UPDATE_FAILURE'

export const ORGANIZATION_GROUP_DELETE_PENDING = 'ORGANIZATION_GROUP_DELETE_PENDING'
export const ORGANIZATION_GROUP_DELETE_SUCCESS = 'ORGANIZATION_GROUP_DELETE_SUCCESS'
export const ORGANIZATION_GROUP_DELETE_FAILURE = 'ORGANIZATION_GROUP_DELETE_FAILURE'

export const ORGANIZATION_GROUP_USERS_PENDING = 'ORGANIZATION_GROUP_USERS_PENDING'
export const ORGANIZATION_GROUP_USERS_SUCCESS = 'ORGANIZATION_GROUP_USERS_SUCCESS'
export const ORGANIZATION_GROUP_USERS_FAILURE = 'ORGANIZATION_GROUP_USERS_FAILURE'
export const ORGANIZATION_GROUP_USERS_NO_CONTENT = 'ORGANIZATION_GROUP_USERS_NO_CONTENT'

export const ORGANIZATION_GROUP_UNITS_PENDING = 'ORGANIZATION_GROUP_UNITS_PENDING'
export const ORGANIZATION_GROUP_UNITS_SUCCESS = 'ORGANIZATION_GROUP_UNITS_SUCCESS'
export const ORGANIZATION_GROUP_UNITS_FAILURE = 'ORGANIZATION_GROUP_UNITS_FAILURE'
export const ORGANIZATION_GROUP_UNITS_NO_CONTENT = 'ORGANIZATION_GROUP_UNITS_NO_CONTENT'

export const ORGANIZATION_GROUP_RESET_STORE = 'ORGANIZATION_GROUP_RESET_STORE'
