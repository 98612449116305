import * as C from './constants'

export default {

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getReportProcedureAppendixIndex ({ commit }) {
    try {
      commit(C.REPORT_PROCEDURE_APPENDIX_INDEX_PENDING)
      const res = await this.$axios.get('report-procedure-appendixes')
      if (res.status === 200) {
        commit(C.REPORT_PROCEDURE_APPENDIX_INDEX_SUCCESS, res.data)
      } else {
        commit(C.REPORT_PROCEDURE_APPENDIX_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.REPORT_PROCEDURE_APPENDIX_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createReportProcedureAppendix ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_APPENDIX_CREATE_PENDING)
      const res = await this.$axios.post('report-procedure-appendixes', payload)
      commit(C.REPORT_PROCEDURE_APPENDIX_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_APPENDIX_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateReportProcedureAppendix ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_APPENDIX_UPDATE_PENDING)
      const res = await this.$axios.post(`report-procedure-appendixes/${payload.id}`, payload)
      commit(C.REPORT_PROCEDURE_APPENDIX_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_APPENDIX_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteReportProcedureAppendix ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_APPENDIX_DELETE_PENDING)
      const res = await this.$axios.delete(`report-procedure-appendixes/${payload}`)
      commit(C.REPORT_PROCEDURE_APPENDIX_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_APPENDIX_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetReportProcedureAppendixStore ({ commit }) {
    commit(C.REPORT_PROCEDURE_APPENDIX_RESET_STORE)
  }
}
