import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4be4d00c = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _1bfb8ec2 = () => interopDefault(import('../pages/action/index.vue' /* webpackChunkName: "pages/action/index" */))
const _3a8b784f = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _f52406b0 = () => interopDefault(import('../pages/audit/index.vue' /* webpackChunkName: "pages/audit/index" */))
const _909e9664 = () => interopDefault(import('../pages/deviation/index.vue' /* webpackChunkName: "pages/deviation/index" */))
const _5d23f054 = () => interopDefault(import('../pages/it/index.vue' /* webpackChunkName: "pages/it/index" */))
const _64d5b6b6 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _5f3d7720 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _15d6ef6d = () => interopDefault(import('../pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _5347ff44 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _7abffcdc = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _5906894c = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _4474ea1d = () => interopDefault(import('../pages/report/index.vue' /* webpackChunkName: "pages/report/index" */))
const _34155578 = () => interopDefault(import('../pages/risk/index.vue' /* webpackChunkName: "pages/risk/index" */))
const _476ff991 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _10ff4870 = () => interopDefault(import('../pages/styles.vue' /* webpackChunkName: "pages/styles" */))
const _0a16f826 = () => interopDefault(import('../pages/suggestion/index.vue' /* webpackChunkName: "pages/suggestion/index" */))
const _f88e96a4 = () => interopDefault(import('../pages/task/index.vue' /* webpackChunkName: "pages/task/index" */))
const _7157a5ed = () => interopDefault(import('../pages/unit/index.vue' /* webpackChunkName: "pages/unit/index" */))
const _f8ece9bc = () => interopDefault(import('../pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _45b381c8 = () => interopDefault(import('../pages/account/organizations.vue' /* webpackChunkName: "pages/account/organizations" */))
const _24ec8823 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _334c6a48 = () => interopDefault(import('../pages/account/security.vue' /* webpackChunkName: "pages/account/security" */))
const _36d02f86 = () => interopDefault(import('../pages/audit/plan/index.vue' /* webpackChunkName: "pages/audit/plan/index" */))
const _81d5b4f6 = () => interopDefault(import('../pages/organization/billing.vue' /* webpackChunkName: "pages/organization/billing" */))
const _135aff36 = () => interopDefault(import('../pages/organization/companies.vue' /* webpackChunkName: "pages/organization/companies" */))
const _21e25a02 = () => interopDefault(import('../pages/organization/events.vue' /* webpackChunkName: "pages/organization/events" */))
const _00d3f63a = () => interopDefault(import('../pages/organization/groups.vue' /* webpackChunkName: "pages/organization/groups" */))
const _44a4f6c7 = () => interopDefault(import('../pages/organization/job.vue' /* webpackChunkName: "pages/organization/job" */))
const _0d1f4d23 = () => interopDefault(import('../pages/organization/members.vue' /* webpackChunkName: "pages/organization/members" */))
const _647b1683 = () => interopDefault(import('../pages/organization/subscription.vue' /* webpackChunkName: "pages/organization/subscription" */))
const _3bbe3e98 = () => interopDefault(import('../pages/password/activate/index.vue' /* webpackChunkName: "pages/password/activate/index" */))
const _89e85738 = () => interopDefault(import('../pages/password/forgot/index.vue' /* webpackChunkName: "pages/password/forgot/index" */))
const _9fa481f8 = () => interopDefault(import('../pages/password/reset/index.vue' /* webpackChunkName: "pages/password/reset/index" */))
const _a9fe4bc4 = () => interopDefault(import('../pages/task/group/index.vue' /* webpackChunkName: "pages/task/group/index" */))
const _1027be76 = () => interopDefault(import('../pages/unit/category/index.vue' /* webpackChunkName: "pages/unit/category/index" */))
const _948027ec = () => interopDefault(import('../pages/action/_id/index.vue' /* webpackChunkName: "pages/action/_id/index" */))
const _66288393 = () => interopDefault(import('../pages/audit/_id/index.vue' /* webpackChunkName: "pages/audit/_id/index" */))
const _6b328e8e = () => interopDefault(import('../pages/deviation/_id/index.vue' /* webpackChunkName: "pages/deviation/_id/index" */))
const _75b67d82 = () => interopDefault(import('../pages/it/_id/index.vue' /* webpackChunkName: "pages/it/_id/index" */))
const _4461ae88 = () => interopDefault(import('../pages/report/_id/index.vue' /* webpackChunkName: "pages/report/_id/index" */))
const _1adfe058 = () => interopDefault(import('../pages/suggestion/_id/index.vue' /* webpackChunkName: "pages/suggestion/_id/index" */))
const _f23bfb50 = () => interopDefault(import('../pages/unit/_id/index.vue' /* webpackChunkName: "pages/unit/_id/index" */))
const _5bd2213c = () => interopDefault(import('../pages/report/_id/_procedure/a/_appendix/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/a/_appendix/index" */))
const _8df900e4 = () => interopDefault(import('../pages/report/_id/_procedure/_content/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/_content/index" */))
const _114a6f5c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _4be4d00c,
    name: "account"
  }, {
    path: "/action",
    component: _1bfb8ec2,
    name: "action"
  }, {
    path: "/archive",
    component: _3a8b784f,
    name: "archive"
  }, {
    path: "/audit",
    component: _f52406b0,
    name: "audit"
  }, {
    path: "/deviation",
    component: _909e9664,
    name: "deviation"
  }, {
    path: "/it",
    component: _5d23f054,
    name: "it"
  }, {
    path: "/login",
    component: _64d5b6b6,
    name: "login"
  }, {
    path: "/logout",
    component: _5f3d7720,
    name: "logout"
  }, {
    path: "/offline",
    component: _15d6ef6d,
    name: "offline"
  }, {
    path: "/onboarding",
    component: _5347ff44,
    name: "onboarding"
  }, {
    path: "/organization",
    component: _7abffcdc,
    name: "organization"
  }, {
    path: "/register",
    component: _5906894c,
    name: "register"
  }, {
    path: "/report",
    component: _4474ea1d,
    name: "report"
  }, {
    path: "/risk",
    component: _34155578,
    name: "risk"
  }, {
    path: "/search",
    component: _476ff991,
    name: "search"
  }, {
    path: "/styles",
    component: _10ff4870,
    name: "styles"
  }, {
    path: "/suggestion",
    component: _0a16f826,
    name: "suggestion"
  }, {
    path: "/task",
    component: _f88e96a4,
    name: "task"
  }, {
    path: "/unit",
    component: _7157a5ed,
    name: "unit"
  }, {
    path: "/verify",
    component: _f8ece9bc,
    name: "verify"
  }, {
    path: "/account/organizations",
    component: _45b381c8,
    name: "account-organizations"
  }, {
    path: "/account/password",
    component: _24ec8823,
    name: "account-password"
  }, {
    path: "/account/security",
    component: _334c6a48,
    name: "account-security"
  }, {
    path: "/audit/plan",
    component: _36d02f86,
    name: "audit-plan"
  }, {
    path: "/organization/billing",
    component: _81d5b4f6,
    name: "organization-billing"
  }, {
    path: "/organization/companies",
    component: _135aff36,
    name: "organization-companies"
  }, {
    path: "/organization/events",
    component: _21e25a02,
    name: "organization-events"
  }, {
    path: "/organization/groups",
    component: _00d3f63a,
    name: "organization-groups"
  }, {
    path: "/organization/job",
    component: _44a4f6c7,
    name: "organization-job"
  }, {
    path: "/organization/members",
    component: _0d1f4d23,
    name: "organization-members"
  }, {
    path: "/organization/subscription",
    component: _647b1683,
    name: "organization-subscription"
  }, {
    path: "/password/activate",
    component: _3bbe3e98,
    name: "password-activate"
  }, {
    path: "/password/forgot",
    component: _89e85738,
    name: "password-forgot"
  }, {
    path: "/password/reset",
    component: _9fa481f8,
    name: "password-reset"
  }, {
    path: "/task/group",
    component: _a9fe4bc4,
    name: "task-group"
  }, {
    path: "/unit/category",
    component: _1027be76,
    name: "unit-category"
  }, {
    path: "/action/:id",
    component: _948027ec,
    name: "action-id"
  }, {
    path: "/audit/:id",
    component: _66288393,
    name: "audit-id"
  }, {
    path: "/deviation/:id",
    component: _6b328e8e,
    name: "deviation-id"
  }, {
    path: "/it/:id",
    component: _75b67d82,
    name: "it-id"
  }, {
    path: "/report/:id",
    component: _4461ae88,
    name: "report-id"
  }, {
    path: "/suggestion/:id",
    component: _1adfe058,
    name: "suggestion-id"
  }, {
    path: "/unit/:id",
    component: _f23bfb50,
    name: "unit-id"
  }, {
    path: "/report/:id/:procedure/a/:appendix",
    component: _5bd2213c,
    name: "report-id-procedure-a-appendix"
  }, {
    path: "/report/:id/:procedure/:content",
    component: _8df900e4,
    name: "report-id-procedure-content"
  }, {
    path: "/",
    component: _114a6f5c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
