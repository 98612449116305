// APPROVAL CONSTANTS
export const APPROVAL_INDEX_PENDING = 'APPROVAL_INDEX_PENDING'
export const APPROVAL_INDEX_SUCCESS = 'APPROVAL_INDEX_SUCCESS'
export const APPROVAL_INDEX_FAILURE = 'APPROVAL_INDEX_FAILURE'
export const APPROVAL_INDEX_NO_CONTENT = 'APPROVAL_INDEX_NO_CONTENT'

export const APPROVAL_SHOW_PENDING = 'APPROVAL_SHOW_PENDING'
export const APPROVAL_SHOW_SUCCESS = 'APPROVAL_SHOW_SUCCESS'
export const APPROVAL_SHOW_FAILURE = 'APPROVAL_SHOW_FAILURE'
export const APPROVAL_SHOW_NO_CONTENT = 'APPROVAL_SHOW_NO_CONTENT'

export const APPROVAL_CREATE_PENDING = 'APPROVAL_CREATE_PENDING'
export const APPROVAL_CREATE_SUCCESS = 'APPROVAL_CREATE_SUCCESS'
export const APPROVAL_CREATE_FAILURE = 'APPROVAL_CREATE_FAILURE'

export const APPROVAL_UPDATE_PENDING = 'APPROVAL_UPDATE_PENDING'
export const APPROVAL_UPDATE_SUCCESS = 'APPROVAL_UPDATE_SUCCESS'
export const APPROVAL_UPDATE_FAILURE = 'APPROVAL_UPDATE_FAILURE'

export const APPROVAL_DELETE_PENDING = 'APPROVAL_DELETE_PENDING'
export const APPROVAL_DELETE_SUCCESS = 'APPROVAL_DELETE_SUCCESS'
export const APPROVAL_DELETE_FAILURE = 'APPROVAL_DELETE_FAILURE'

export const APPROVAL_STATUS_PENDING = 'APPROVAL_STATUS_PENDING'
export const APPROVAL_STATUS_SUCCESS = 'APPROVAL_STATUS_SUCCESS'
export const APPROVAL_STATUS_FAILURE = 'APPROVAL_STATUS_FAILURE'

export const APPROVAL_RESET_STORE = 'APPROVAL_RESET_STORE'
