export default function ({ $axios, $config }, inject) {
  // Create a custom axios instance
  const base = $axios.create({
    withCredentials: true,
    headers: {
      common: {
        Accept: 'application/json'
      }
    }
  })

  // Set baseURL to something different
  base.setBaseURL($config.baseURL)

  // Inject to context as $api
  inject('base', base)
}
