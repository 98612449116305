import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getItGroupItemIndexById ({ commit }, payload) {
    try {
      commit(C.IT_GROUP_ITEM_INDEX_PENDING)
      const res = await this.$axios.get('it-group-items', { params: payload })
      commit(C.IT_GROUP_ITEM_INDEX_SUCCESS, res.data)
    } catch (err) {
      commit(C.IT_GROUP_ITEM_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createItGroupItem ({ commit }, payload) {
    try {
      commit(C.IT_GROUP_ITEM_CREATE_PENDING)
      const res = await this.$axios.post('it-group-items', payload)
      commit(C.IT_GROUP_ITEM_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.IT_GROUP_ITEM_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateItGroupItem ({ commit }, payload) {
    try {
      commit(C.IT_GROUP_ITEM_UPDATE_PENDING)
      const res = await this.$axios.put(`it-group-items/${payload.id}`, payload)
      commit(C.IT_GROUP_ITEM_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.IT_GROUP_ITEM_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteItGroupItem ({ commit }, payload) {
    try {
      commit(C.IT_GROUP_ITEM_DELETE_PENDING)
      const res = await this.$axios.delete(`it-group-items/${payload.id}`, payload)
      commit(C.IT_GROUP_ITEM_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.IT_GROUP_ITEM_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getItGroupItemConnections ({ commit }, payload) {
    try {
      commit(C.IT_GROUP_ITEM_CONNECTIONS_PENDING)
      const res = await this.$axios.get(`it-group-items/${payload.id}/connections`)
      if (res.status === 200) {
        commit(C.IT_GROUP_ITEM_CONNECTIONS_SUCCESS, res.data)
      } else {
        commit(C.IT_GROUP_ITEM_CONNECTIONS_NO_CONTENT)
      }
    } catch (err) {
      commit(C.IT_GROUP_ITEM_CONNECTIONS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateItGroupItemConnections ({ commit }, payload) {
    try {
      commit(C.IT_GROUP_ITEM_CONNECTIONS_UPDATE_PENDING)
      const res = await this.$axios.put(`it-group-items/${payload.id}/connections`, payload)
      commit(C.IT_GROUP_ITEM_CONNECTIONS_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.IT_GROUP_ITEM_CONNECTIONS_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async removeItGroupItemConnection ({ commit }, payload) {
    try {
      commit(C.IT_GROUP_ITEM_CONNECTIONS_DELETE_PENDING)
      const res = await this.$axios.delete(`it-group-items/${payload.id}/connections`, { params: payload })
      commit(C.IT_GROUP_ITEM_CONNECTIONS_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.IT_GROUP_ITEM_CONNECTIONS_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetItGroupItemStore ({ commit }) {
    commit(C.IT_GROUP_ITEM_RESET_STORE)
  }
}
