import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.ARCHIVE_FILE_INDEX_PENDING] (state) {
    set(state, 'index', {
      ...state.index,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_INDEX_SUCCESS] (state, payload) {
    set(state, 'index', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_INDEX_FAILURE] (state, payload) {
    set(state, 'index', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_INDEX_NO_CONTENT] (state, payload) {
    set(state, 'index', {
      items: [],
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_FILE_VERSIONS_PENDING] (state) {
    set(state, 'versions', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_VERSIONS_SUCCESS] (state, payload) {
    set(state, 'versions', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_VERSIONS_FAILURE] (state, payload) {
    set(state, 'versions', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_VERSIONS_NO_CONTENT] (state, payload) {
    set(state, 'versions', {
      items: [],
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_FILE_TRASHED_PENDING] (state) {
    set(state, 'trashed', {
      ...state.trashed,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_TRASHED_SUCCESS] (state, payload) {
    set(state, 'trashed', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_TRASHED_FAILURE] (state, payload) {
    set(state, 'trashed', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_TRASHED_NO_CONTENT] (state, payload) {
    set(state, 'trashed', {
      items: [],
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_FILE_CREATE_PENDING] (state) {
    set(state, 'create', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_CREATE_SUCCESS] (state, payload) {
    set(state, 'create', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_CREATE_FAILURE] (state, payload) {
    set(state, 'create', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_FILE_UPDATE_PENDING] (state) {
    set(state, 'update', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_UPDATE_SUCCESS] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_UPDATE_FAILURE] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_FILE_DELETE_PENDING] (state) {
    set(state, 'delete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_DELETE_SUCCESS] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_DELETE_FAILURE] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_FILE_RESTORE_PENDING] (state) {
    set(state, 'restore', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_RESTORE_SUCCESS] (state, payload) {
    set(state, 'restore', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_RESTORE_FAILURE] (state, payload) {
    set(state, 'restore', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_FILE_CREATED_BY_PENDING] (state) {
    set(state, 'createdBy', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_CREATED_BY_SUCCESS] (state, payload) {
    set(state, 'createdBy', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_CREATED_BY_FAILURE] (state, payload) {
    set(state, 'createdBy', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_FILE_USERS_PENDING] (state) {
    set(state, 'users', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_USERS_SUCCESS] (state, payload) {
    set(state, 'users', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_USERS_FAILURE] (state, payload) {
    set(state, 'users', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_FILE_BOOKMARKED_PENDING] (state) {
    set(state, 'bookmarked', {
      ...state.bookmarked,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_BOOKMARKED_SUCCESS] (state, payload) {
    set(state, 'bookmarked', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ARCHIVE_FILE_BOOKMARKED_FAILURE] (state, payload) {
    set(state, 'bookmarked', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ARCHIVE_FILE_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
