export default () => {
  return {
    company: {
      item: null,
      loading: false,
      error: null
    },
    units: {
      item: null,
      loading: false,
      error: null
    }
  }
}
