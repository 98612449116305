// CVR CONSTANTS
export const REPORT_TEMPLATE_INDEX_PENDING = 'REPORT_TEMPLATE_INDEX_PENDING'
export const REPORT_TEMPLATE_INDEX_SUCCESS = 'REPORT_TEMPLATE_INDEX_SUCCESS'
export const REPORT_TEMPLATE_INDEX_FAILURE = 'REPORT_TEMPLATE_INDEX_FAILURE'
export const REPORT_TEMPLATE_INDEX_NO_CONTENT = 'REPORT_TEMPLATE_INDEX_NO_CONTENT'

export const REPORT_TEMPLATE_TYPE_PENDING = 'REPORT_TEMPLATE_TYPE_PENDING'
export const REPORT_TEMPLATE_TYPE_SUCCESS = 'REPORT_TEMPLATE_TYPE_SUCCESS'
export const REPORT_TEMPLATE_TYPE_FAILURE = 'REPORT_TEMPLATE_TYPE_FAILURE'
export const REPORT_TEMPLATE_TYPE_NO_CONTENT = 'REPORT_TEMPLATE_TYPE_NO_CONTENT'

export const REPORT_TEMPLATE_SECTION_TYPE_PENDING = 'REPORT_TEMPLATE_SECTION_TYPE_PENDING'
export const REPORT_TEMPLATE_SECTION_TYPE_SUCCESS = 'REPORT_TEMPLATE_SECTION_TYPE_SUCCESS'
export const REPORT_TEMPLATE_SECTION_TYPE_FAILURE = 'REPORT_TEMPLATE_SECTION_TYPE_FAILURE'
export const REPORT_TEMPLATE_SECTION_TYPE_NO_CONTENT = 'REPORT_TEMPLATE_SECTION_TYPE_NO_CONTENT'

export const REPORT_TEMPLATE_PENDING = 'REPORT_TEMPLATE_PENDING'
export const REPORT_TEMPLATE_SUCCESS = 'REPORT_TEMPLATE_SUCCESS'
export const REPORT_TEMPLATE_FAILURE = 'REPORT_TEMPLATE_FAILURE'

export const REPORT_TEMPLATE_SECTION_PENDING = 'REPORT_TEMPLATE_SECTION_PENDING'
export const REPORT_TEMPLATE_SECTION_SUCCESS = 'REPORT_TEMPLATE_SECTION_SUCCESS'
export const REPORT_TEMPLATE_SECTION_FAILURE = 'REPORT_TEMPLATE_SECTION_FAILURE'

export const REPORT_TEMPLATE_UPDATE_PENDING = 'REPORT_TEMPLATE_UPDATE_PENDING'
export const REPORT_TEMPLATE_UPDATE_SUCCESS = 'REPORT_TEMPLATE_UPDATE_SUCCESS'
export const REPORT_TEMPLATE_UPDATE_FAILURE = 'REPORT_TEMPLATE_UPDATE_FAILURE'

export const REPORT_TEMPLATE_DELETE_PENDING = 'REPORT_TEMPLATE_DELETE_PENDING'
export const REPORT_TEMPLATE_DELETE_SUCCESS = 'REPORT_TEMPLATE_DELETE_SUCCESS'
export const REPORT_TEMPLATE_DELETE_FAILURE = 'REPORT_TEMPLATE_DELETE_FAILURE'

export const REPORT_TEMPLATE_RESET_STORE = 'REPORT_TEMPLATE_RESET_STORE'
