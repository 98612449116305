import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.ACTION_PLAN_INDEX_PENDING] (state) {
    set(state, 'index', {
      ...state.index,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_INDEX_SUCCESS] (state, payload) {
    set(state, 'index', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_INDEX_FAILURE] (state, payload) {
    set(state, 'index', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ACTION_PLAN_COUNT_PENDING] (state) {
    set(state, 'count', {
      ...state.index,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_COUNT_SUCCESS] (state, payload) {
    set(state, 'count', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_COUNT_FAILURE] (state, payload) {
    set(state, 'count', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ACTION_PLAN_TEMPLATES_PENDING] (state) {
    set(state, 'templates', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_TEMPLATES_SUCCESS] (state, payload) {
    set(state, 'templates', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_TEMPLATES_FAILURE] (state, payload) {
    set(state, 'templates', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_TEMPLATES_NO_CONTENT] (state, payload) {
    set(state, 'templates', {
      items: [],
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.ACTION_PLAN_SHOW_PENDING] (state) {
    set(state, 'show', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_SHOW_SUCCESS] (state, payload) {
    set(state, 'show', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_SHOW_FAILURE] (state, payload) {
    set(state, 'show', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ACTION_PLAN_CREATE_PENDING] (state) {
    set(state, 'create', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_CREATE_SUCCESS] (state, payload) {
    set(state, 'create', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_CREATE_FAILURE] (state, payload) {
    set(state, 'create', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ACTION_PLAN_TEMPLATE_PENDING] (state) {
    set(state, 'template', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_TEMPLATE_SUCCESS] (state, payload) {
    set(state, 'template', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_TEMPLATE_FAILURE] (state, payload) {
    set(state, 'template', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ACTION_PLAN_UPDATE_PENDING] (state) {
    set(state, 'update', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.ACTION_PLAN_UPDATE_SUCCESS] (state) {
    set(state, 'update', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_UPDATE_FAILURE] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ACTION_PLAN_DELETE_PENDING] (state) {
    set(state, 'delete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.ACTION_PLAN_DELETE_SUCCESS] (state) {
    set(state, 'delete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_DELETE_FAILURE] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ACTION_PLAN_COMPLETE_PENDING] (state) {
    set(state, 'complete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.ACTION_PLAN_COMPLETE_SUCCESS] (state) {
    set(state, 'complete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.ACTION_PLAN_COMPLETE_FAILURE] (state, payload) {
    set(state, 'complete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.ACTION_PLAN_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
