// ARCHIVE FILE BOOKMARK CONSTANTS
export const ARCHIVE_FILE_BOOKMARK_INDEX_PENDING = 'ARCHIVE_FILE_BOOKMARK_INDEX_PENDING'
export const ARCHIVE_FILE_BOOKMARK_INDEX_SUCCESS = 'ARCHIVE_FILE_BOOKMARK_INDEX_SUCCESS'
export const ARCHIVE_FILE_BOOKMARK_INDEX_FAILURE = 'ARCHIVE_FILE_BOOKMARK_INDEX_FAILURE'
export const ARCHIVE_FILE_BOOKMARK_INDEX_NO_CONTENT = 'ARCHIVE_FILE_BOOKMARK_INDEX_NO_CONTENT'

export const ARCHIVE_FILE_BOOKMARK_CREATE_PENDING = 'ARCHIVE_FILE_BOOKMARK_CREATE_PENDING'
export const ARCHIVE_FILE_BOOKMARK_CREATE_SUCCESS = 'ARCHIVE_FILE_BOOKMARK_CREATE_SUCCESS'
export const ARCHIVE_FILE_BOOKMARK_CREATE_FAILURE = 'ARCHIVE_FILE_BOOKMARK_CREATE_FAILURE'

export const ARCHIVE_FILE_BOOKMARK_UPDATE_PENDING = 'ARCHIVE_FILE_BOOKMARK_UPDATE_PENDING'
export const ARCHIVE_FILE_BOOKMARK_UPDATE_SUCCESS = 'ARCHIVE_FILE_BOOKMARK_UPDATE_SUCCESS'
export const ARCHIVE_FILE_BOOKMARK_UPDATE_FAILURE = 'ARCHIVE_FILE_BOOKMARK_UPDATE_FAILURE'

export const ARCHIVE_FILE_BOOKMARK_DELETE_PENDING = 'ARCHIVE_FILE_BOOKMARK_DELETE_PENDING'
export const ARCHIVE_FILE_BOOKMARK_DELETE_SUCCESS = 'ARCHIVE_FILE_BOOKMARK_DELETE_SUCCESS'
export const ARCHIVE_FILE_BOOKMARK_DELETE_FAILURE = 'ARCHIVE_FILE_BOOKMARK_DELETE_FAILURE'

export const ARCHIVE_FILE_BOOKMARK_RESET_STORE = 'ARCHIVE_FILE_BOOKMARK_RESET_STORE'
