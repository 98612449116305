import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createActionPlanTask ({ commit }, payload) {
    try {
      commit(C.ACTION_PLAN_TASK_CREATE_PENDING)
      const res = await this.$axios.post('action-plan-tasks', payload)
      commit(C.ACTION_PLAN_TASK_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ACTION_PLAN_TASK_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createActionPlanTasks ({ commit }, payload) {
    try {
      commit(C.ACTION_PLAN_TASK_CREATE_PENDING)
      const res = await this.$axios.post('action-plan-tasks/create', payload)
      commit(C.ACTION_PLAN_TASK_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ACTION_PLAN_TASK_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async showActionPlanTaskByTask ({ commit }, payload) {
    try {
      commit(C.ACTION_PLAN_TASK_SHOW_PENDING)
      const res = await this.$axios.get(`action-plan-tasks/${payload}`)
      commit(C.ACTION_PLAN_TASK_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.ACTION_PLAN_TASK_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetActionPlanTaskStore ({ commit }) {
    commit(C.ACTION_PLAN_TASK_RESET_STORE)
  }
}
