import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getReportProcedureContentSection ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_PENDING)
      const res = await this.$axios.post('report-procedure-contents/section', payload)
      if (res.status === 200 && res.data) {
        commit(C.REPORT_PROCEDURE_CONTENT_SECTION_SUCCESS, res.data)
      } else {
        commit(C.REPORT_PROCEDURE_CONTENT_SECTION_NO_CONTENT)
      }
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getReportProcedureContent ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_PENDING)
      const res = await this.$axios.get(`report-procedure-contents/${payload}`)
      if (res.status === 200 && res.data) {
        commit(C.REPORT_PROCEDURE_CONTENT_SECTION_SUCCESS, res.data)
      } else {
        commit(C.REPORT_PROCEDURE_CONTENT_SECTION_NO_CONTENT)
      }
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_SECTION_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async searchReportProcedureContentSection ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_CONTENT_SEARCH_PENDING)
      const res = await this.$axios.post('report-procedure-contents/section/search', payload)
      if (res.status === 200 && res.data) {
        commit(C.REPORT_PROCEDURE_CONTENT_SEARCH_SUCCESS, res.data)
      } else {
        commit(C.REPORT_PROCEDURE_CONTENT_SEARCH_NO_CONTENT)
      }
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_SEARCH_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateOrCreateReportProcedureContent ({ commit }, payload) {
    try {
      const formData = new FormData()
      formData.append('report_id', payload.report_id)
      formData.append('report_procedure_content_id', payload.report_procedure_content_id)
      payload.sections.forEach((section, index) => {
        for (const key in section) {
          if (section[key] !== null) {
            formData.append(`sections[${index}][${key}]`, section[key])
          } else {
            formData.append(`sections[${index}][${key}]`, '')
          }
        }
      })
      commit(C.REPORT_PROCEDURE_CONTENT_CREATE_PENDING)
      const res = await this.$axios.post('report-procedure-contents', formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        }
      })
      commit(C.REPORT_PROCEDURE_CONTENT_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateReportProcedureContent ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_CONTENT_CREATE_PENDING)
      const res = await this.$axios.post(`report-procedure-contents/${payload.id}`, payload)
      commit(C.REPORT_PROCEDURE_CONTENT_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createReportProcedureContentDraft ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_CONTENT_CREATE_PENDING)
      await this.$axios.post('report-procedure-contents/draft', payload)
      commit(C.REPORT_PROCEDURE_CONTENT_CREATE_SUCCESS)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createOrUpdateReportProcedureContentAssignedUserId ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_CONTENT_ASSIGNEE_PENDING)
      await this.$axios.post('report-procedure-contents/assignee', payload)
      commit(C.REPORT_PROCEDURE_CONTENT_ASSIGNEE_SUCCESS)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_ASSIGNEE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async publishReportProcedureContent ({ commit }, payload) {
    try {
      commit(C.REPORT_PROCEDURE_CONTENT_PUBLISH_PENDING)
      await this.$axios.put(`/report-procedure-contents/${payload}/publish`)
      commit(C.REPORT_PROCEDURE_CONTENT_PUBLISH_SUCCESS)
    } catch (err) {
      commit(C.REPORT_PROCEDURE_CONTENT_PUBLISH_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetReportProcedureContentStore ({ commit }) {
    commit(C.REPORT_PROCEDURE_CONTENT_RESET_STORE)
  }
}
