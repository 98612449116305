import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getArchiveIndex ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_INDEX_PENDING)
      const res = await this.$axios.get('archives', { params: payload })
      if (res.status === 200) {
        commit(C.ARCHIVE_INDEX_SUCCESS, res.data)
      } else {
        commit(C.ARCHIVE_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.ARCHIVE_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getArchiveTrashed ({ commit }) {
    try {
      commit(C.ARCHIVE_TRASHED_PENDING)
      const res = await this.$axios.get('archives/trashed')
      if (res.status === 200) {
        commit(C.ARCHIVE_TRASHED_SUCCESS, res.data)
      } else {
        commit(C.ARCHIVE_TRASHED_NO_CONTENT)
      }
    } catch (err) {
      commit(C.ARCHIVE_TRASHED_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getArchive ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_SHOW_PENDING)
      const res = await this.$axios.get(`archives/${payload}`)
      commit(C.ARCHIVE_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createArchive ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_CREATE_PENDING)
      const res = await this.$axios.post('archives', payload)
      commit(C.ARCHIVE_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateArchive ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_UPDATE_PENDING)
      const res = await this.$axios.put(`archives/${payload.id}`, payload)
      commit(C.ARCHIVE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteArchive ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_DELETE_PENDING)
      const res = await this.$axios.delete(`archives/${payload}`)
      commit(C.ARCHIVE_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async restoreArchive ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_RESTORE_PENDING)
      const res = await this.$axios.get(`archives/${payload}/restore`)
      commit(C.ARCHIVE_RESTORE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_RESTORE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateArchiveCreatedBy ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_CREATED_BY_PENDING)
      const res = await this.$axios.put(`archives/${payload.id}/created-by`, payload)
      commit(C.ARCHIVE_CREATED_BY_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_CREATED_BY_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateArchiveCompanyUnit ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_UPDATE_PENDING)
      const res = await this.$axios.put(`archives/${payload.id}/company-unit`, payload)
      commit(C.ARCHIVE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async moveArchive ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_UPDATE_PENDING)
      const res = await this.$axios.put(`archives/${payload.id}/move`, payload)
      commit(C.ARCHIVE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateArchiveUsers ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_USERS_PENDING)
      const res = await this.$axios.put(`archives/${payload.id}/users`, payload)
      commit(C.ARCHIVE_USERS_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_USERS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getArchiveBreadcrumbs ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_BREADCRUMBS_PENDING)
      const res = await this.$axios.get(`archives/${payload}/breadcrumbs`)
      commit(C.ARCHIVE_BREADCRUMBS_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_BREADCRUMBS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetArchiveBreadcrumbs ({ commit }) {
    commit(C.ARCHIVE_BREADCRUMBS_RESET)
  },

  /**
   * @param commit
   */
  resetArchiveStore ({ commit }) {
    commit(C.ARCHIVE_RESET_STORE)
  }
}
