// FILE CONSTANTS

export const FILE_DOWNLOAD_PENDING = 'FILE_DOWNLOAD_PENDING'
export const FILE_DOWNLOAD_SUCCESS = 'FILE_DOWNLOAD_SUCCESS'
export const FILE_DOWNLOAD_FAILURE = 'FILE_DOWNLOAD_FAILURE'

export const FILE_SHOW_PENDING = 'FILE_SHOW_PENDING'
export const FILE_SHOW_SUCCESS = 'FILE_SHOW_SUCCESS'
export const FILE_SHOW_FAILURE = 'FILE_SHOW_FAILURE'

export const FILE_UPDATE_PENDING = 'FILE_UPDATE_PENDING'
export const FILE_UPDATE_SUCCESS = 'FILE_UPDATE_SUCCESS'
export const FILE_UPDATE_FAILURE = 'FILE_UPDATE_FAILURE'

export const FILE_CREATE_PENDING = 'FILE_CREATE_PENDING'
export const FILE_CREATE_SUCCESS = 'FILE_CREATE_SUCCESS'
export const FILE_CREATE_FAILURE = 'FILE_CREATE_FAILURE'

export const FILE_DELETE_PENDING = 'FILE_DELETE_PENDING'
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS'
export const FILE_DELETE_FAILURE = 'FILE_DELETE_FAILURE'

export const FILE_RESET_STORE = 'FILE_RESET_STORE'
