// APPROVAL CONSTANTS
export const SNIPPET_INDEX_PENDING = 'SNIPPET_INDEX_PENDING'
export const SNIPPET_INDEX_SUCCESS = 'SNIPPET_INDEX_SUCCESS'
export const SNIPPET_INDEX_FAILURE = 'SNIPPET_INDEX_FAILURE'
export const SNIPPET_INDEX_NO_CONTENT = 'SNIPPET_INDEX_NO_CONTENT'

export const SNIPPET_CATEGORY_PENDING = 'SNIPPET_CATEGORY_PENDING'
export const SNIPPET_CATEGORY_SUCCESS = 'SNIPPET_CATEGORY_SUCCESS'
export const SNIPPET_CATEGORY_FAILURE = 'SNIPPET_CATEGORY_FAILURE'
export const SNIPPET_CATEGORY_NO_CONTENT = 'SNIPPET_CATEGORY_NO_CONTENT'

export const SNIPPET_SHOW_PENDING = 'SNIPPET_SHOW_PENDING'
export const SNIPPET_SHOW_SUCCESS = 'SNIPPET_SHOW_SUCCESS'
export const SNIPPET_SHOW_FAILURE = 'SNIPPET_SHOW_FAILURE'

export const SNIPPET_CREATE_PENDING = 'SNIPPET_CREATE_PENDING'
export const SNIPPET_CREATE_SUCCESS = 'SNIPPET_CREATE_SUCCESS'
export const SNIPPET_CREATE_FAILURE = 'SNIPPET_CREATE_FAILURE'

export const SNIPPET_UPDATE_PENDING = 'SNIPPET_UPDATE_PENDING'
export const SNIPPET_UPDATE_SUCCESS = 'SNIPPET_UPDATE_SUCCESS'
export const SNIPPET_UPDATE_FAILURE = 'SNIPPET_UPDATE_FAILURE'

export const SNIPPET_DELETE_PENDING = 'SNIPPET_DELETE_PENDING'
export const SNIPPET_DELETE_SUCCESS = 'SNIPPET_DELETE_SUCCESS'
export const SNIPPET_DELETE_FAILURE = 'SNIPPET_DELETE_FAILURE'

export const SNIPPET_RESET_STORE = 'SNIPPET_RESET_STORE'
