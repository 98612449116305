// REPORT TYPE CONSTANTS
export const REPORT_TYPE_INDEX_PENDING = 'REPORT_TYPE_INDEX_PENDING'
export const REPORT_TYPE_INDEX_SUCCESS = 'REPORT_TYPE_INDEX_SUCCESS'
export const REPORT_TYPE_INDEX_FAILURE = 'REPORT_TYPE_INDEX_FAILURE'
export const REPORT_TYPE_INDEX_NO_CONTENT = 'REPORT_TYPE_INDEX_NO_CONTENT'

export const REPORT_TYPE_TEMPLATES_PENDING = 'REPORT_TYPE_TEMPLATES_PENDING'
export const REPORT_TYPE_TEMPLATES_SUCCESS = 'REPORT_TYPE_TEMPLATES_SUCCESS'
export const REPORT_TYPE_TEMPLATES_FAILURE = 'REPORT_TYPE_TEMPLATES_FAILURE'
export const REPORT_TYPE_TEMPLATES_NO_CONTENT = 'REPORT_TYPE_TEMPLATES_NO_CONTENT'

export const REPORT_TYPE_NOTIFICATIONS_PENDING = 'REPORT_TYPE_NOTIFICATIONS_PENDING'
export const REPORT_TYPE_NOTIFICATIONS_SUCCESS = 'REPORT_TYPE_NOTIFICATIONS_SUCCESS'
export const REPORT_TYPE_NOTIFICATIONS_FAILURE = 'REPORT_TYPE_NOTIFICATIONS_FAILURE'
export const REPORT_TYPE_NOTIFICATIONS_NO_CONTENT = 'REPORT_TYPE_NOTIFICATIONS_NO_CONTENT'

export const REPORT_TYPE_NOTIFICATIONS_COUNT_PENDING = 'REPORT_TYPE_NOTIFICATIONS_COUNT_PENDING'
export const REPORT_TYPE_NOTIFICATIONS_COUNT_SUCCESS = 'REPORT_TYPE_NOTIFICATIONS_COUNT_SUCCESS'
export const REPORT_TYPE_NOTIFICATIONS_COUNT_FAILURE = 'REPORT_TYPE_NOTIFICATIONS_COUNT_FAILURE'

export const REPORT_TYPE_RESET_STORE = 'REPORT_TYPE_RESET_STORE'
