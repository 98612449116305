import * as C from './constants'

export default {

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async enableTwoFactor ({ commit }) {
    try {
      commit(C.TWO_FACTOR_ENABLE_PENDING)
      await this.$axios.post('/user/two-factor-authentication')
      commit(C.TWO_FACTOR_ENABLE_SUCCESS)
    } catch (err) {
      commit(C.TWO_FACTOR_ENABLE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async disableTwoFactor ({ commit }, payload) {
    try {
      commit(C.TWO_FACTOR_ENABLE_PENDING)
      await this.$axios.delete('/user/two-factor-authentication')
      commit(C.TWO_FACTOR_ENABLE_SUCCESS)
    } catch (err) {
      commit(C.TWO_FACTOR_ENABLE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async checkTwoFactorChallenge ({ commit }, payload) {
    try {
      commit(C.TWO_FACTOR_CHALLENGE_PENDING)
      await this.$axios.post('two-factor-challenge', payload)
      commit(C.TWO_FACTOR_CHALLENGE_SUCCESS)
    } catch (err) {
      commit(C.TWO_FACTOR_CHALLENGE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getTwoFactorRecoveryCodes ({ commit }) {
    try {
      commit(C.TWO_FACTOR_RECOVERY_CODES_PENDING)
      const res = await this.$axios.get('/user/two-factor-recovery-codes')
      commit(C.TWO_FACTOR_RECOVERY_CODES_SUCCESS, res.data)
    } catch (err) {
      commit(C.TWO_FACTOR_RECOVERY_CODES_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async createTwoFactorRecoveryCodes ({ commit }) {
    try {
      commit(C.TWO_FACTOR_RECOVERY_CODES_CREATE_PENDING)
      await this.$axios.post('/user/two-factor-recovery-codes')
      commit(C.TWO_FACTOR_RECOVERY_CODES_CREATE_SUCCESS)
    } catch (err) {
      commit(C.TWO_FACTOR_RECOVERY_CODES_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getTwoFactorQRCode ({ commit }) {
    try {
      commit(C.TWO_FACTOR_QR_CODE_PENDING)
      const res = await this.$axios.get('/user/two-factor-qr-code')
      commit(C.TWO_FACTOR_QR_CODE_SUCCESS, res.data)
    } catch (err) {
      commit(C.TWO_FACTOR_QR_CODE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async confirmTwoFactorPassword ({ commit }, payload) {
    try {
      commit(C.TWO_FACTOR_PASSWORD_CONFIRMATION_PENDING)
      const res = await this.$axios.post('/user/confirm-password', payload)
      commit(C.TWO_FACTOR_PASSWORD_CONFIRMATION_SUCCESS, res.data)
    } catch (err) {
      commit(C.TWO_FACTOR_PASSWORD_CONFIRMATION_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async confirmTwoFactor ({ commit }, payload) {
    try {
      commit(C.TWO_FACTOR_CONFIRMATION_PENDING)
      const res = await this.$axios.post('/user/confirmed-two-factor-authentication', payload)
      commit(C.TWO_FACTOR_CONFIRMATION_SUCCESS, res.data)
    } catch (err) {
      commit(C.TWO_FACTOR_CONFIRMATION_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getTwoFactorPasswordStatus ({ commit }) {
    try {
      commit(C.TWO_FACTOR_PASSWORD_STATUS_PENDING)
      const res = await this.$axios.get('/user/confirmed-password-status')
      commit(C.TWO_FACTOR_PASSWORD_STATUS_SUCCESS, res.data)
    } catch (err) {
      commit(C.TWO_FACTOR_PASSWORD_STATUS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetTwoFactorStore ({ commit }) {
    commit(C.TWO_FACTOR_RESET_STORE)
  }
}
