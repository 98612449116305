import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getTaskCommentIndex ({ commit }, payload) {
    try {
      commit(C.TASK_COMMENT_INDEX_PENDING)
      const res = await this.$axios.get(`task-comments/${payload}`)
      if (res.status === 200) {
        commit(C.TASK_COMMENT_INDEX_SUCCESS, res.data)
      } else {
        commit(C.TASK_COMMENT_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.TASK_COMMENT_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createTaskComment ({ commit }, payload) {
    try {
      commit(C.TASK_COMMENT_CREATE_PENDING)
      const res = await this.$axios.post('task-comments', payload)
      commit(C.TASK_COMMENT_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.TASK_COMMENT_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateTaskComment ({ commit }, payload) {
    try {
      commit(C.TASK_COMMENT_UPDATE_PENDING)
      const res = await this.$axios.put(`task-comments/${payload.id}`, payload)
      commit(C.TASK_COMMENT_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.TASK_COMMENT_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteTaskComment ({ commit }, payload) {
    try {
      commit(C.TASK_COMMENT_DELETE_PENDING)
      const res = await this.$axios.delete(`task-comments/${payload}`)
      commit(C.TASK_COMMENT_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.TASK_COMMENT_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetTaskCommentStore ({ commit }) {
    commit(C.TASK_COMMENT_RESET_STORE)
  }
}
