// COMPANY CONSTANTS
export const COMPANY_INDEX_PENDING = 'COMPANY_INDEX_PENDING'
export const COMPANY_INDEX_SUCCESS = 'COMPANY_INDEX_SUCCESS'
export const COMPANY_INDEX_FAILURE = 'COMPANY_INDEX_FAILURE'
export const COMPANY_INDEX_NO_CONTENT = 'COMPANY_INDEX_NO_CONTENT'

export const COMPANY_INDEX_FLATTEN_PENDING = 'COMPANY_INDEX_FLATTEN_PENDING'
export const COMPANY_INDEX_FLATTEN_SUCCESS = 'COMPANY_INDEX_FLATTEN_SUCCESS'
export const COMPANY_INDEX_FLATTEN_FAILURE = 'COMPANY_INDEX_FLATTEN_FAILURE'
export const COMPANY_INDEX_FLATTEN_NO_CONTENT = 'COMPANY_INDEX_FLATTEN_NO_CONTENT'

export const COMPANY_INDEX_FLAT_PENDING = 'COMPANY_INDEX_FLAT_PENDING'
export const COMPANY_INDEX_FLAT_SUCCESS = 'COMPANY_INDEX_FLAT_SUCCESS'
export const COMPANY_INDEX_FLAT_FAILURE = 'COMPANY_INDEX_FLAT_FAILURE'
export const COMPANY_INDEX_FLAT_NO_CONTENT = 'COMPANY_INDEX_FLAT_NO_CONTENT'

export const COMPANY_CREATE_PENDING = 'COMPANY_CREATE_PENDING'
export const COMPANY_CREATE_SUCCESS = 'COMPANY_CREATE_SUCCESS'
export const COMPANY_CREATE_FAILURE = 'COMPANY_CREATE_FAILURE'

export const COMPANY_UPDATE_PENDING = 'COMPANY_UPDATE_PENDING'
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS'
export const COMPANY_UPDATE_FAILURE = 'COMPANY_UPDATE_FAILURE'

export const COMPANY_CREATE_CUSTOM_PENDING = 'COMPANY_CREATE_CUSTOM_PENDING'
export const COMPANY_CREATE_CUSTOM_SUCCESS = 'COMPANY_CREATE_CUSTOM_SUCCESS'
export const COMPANY_CREATE_CUSTOM_FAILURE = 'COMPANY_CREATE_CUSTOM_FAILURE'

export const COMPANY_SYNC_PENDING = 'COMPANY_SYNC_PENDING'
export const COMPANY_SYNC_SUCCESS = 'COMPANY_SYNC_SUCCESS'
export const COMPANY_SYNC_FAILURE = 'COMPANY_SYNC_FAILURE'

export const COMPANY_DELETE_PENDING = 'COMPANY_DELETE_PENDING'
export const COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS'
export const COMPANY_DELETE_FAILURE = 'COMPANY_DELETE_FAILURE'

export const COMPANY_RESET_STORE = 'COMPANY_RESET_STORE'
