import * as C from './constants'

export default {

  /**
   * @param commit
   * @param $config
   * @returns {Promise<void>}
   */
  async getReportTemplateIndex ({ commit }) {
    try {
      commit(C.REPORT_TEMPLATE_INDEX_PENDING)
      const res = await this.$axios.get('report-templates')
      if (res.status === 200 && res.data) {
        commit(C.REPORT_TEMPLATE_INDEX_SUCCESS, res.data)
      } else {
        commit(C.REPORT_TEMPLATE_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.REPORT_TEMPLATE_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getReportTemplateIndexByType ({ commit }, payload) {
    try {
      commit(C.REPORT_TEMPLATE_TYPE_PENDING)
      const res = await this.$axios.get(`report-templates/type/${payload}`)
      if (res.status === 200 && res.data) {
        commit(C.REPORT_TEMPLATE_TYPE_SUCCESS, res.data)
      } else {
        commit(C.REPORT_TEMPLATE_TYPE_NO_CONTENT)
      }
    } catch (err) {
      commit(C.REPORT_TEMPLATE_TYPE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getReportTemplate ({ commit }, payload) {
    try {
      commit(C.REPORT_TEMPLATE_PENDING)
      const res = await this.$axios.get(`report-templates/${payload}`)
      commit(C.REPORT_TEMPLATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_TEMPLATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getReportTemplateSection ({ commit }, payload) {
    try {
      commit(C.REPORT_TEMPLATE_SECTION_PENDING)
      const res = await this.$axios.post('report-templates/section', payload)
      if (res.status === 200) {
        commit(C.REPORT_TEMPLATE_SECTION_SUCCESS, res.data)
      } else {
        commit(C.REPORT_TEMPLATE_SECTION_SUCCESS, null)
      }
    } catch (err) {
      commit(C.REPORT_TEMPLATE_SECTION_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateOrCreateReportTemplate ({ commit }, payload) {
    try {
      commit(C.REPORT_TEMPLATE_UPDATE_PENDING)
      const res = await this.$axios.post('report-templates', payload)
      commit(C.REPORT_TEMPLATE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_TEMPLATE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteReportTemplate ({ commit }, payload) {
    try {
      commit(C.REPORT_TEMPLATE_DELETE_PENDING)
      const res = await this.$axios.delete(`report-templates/${payload}`)
      commit(C.REPORT_TEMPLATE_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_TEMPLATE_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetReportTemplateStore ({ commit }) {
    commit(C.REPORT_TEMPLATE_RESET_STORE)
  }
}
