import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.TASK_INDEX_PENDING] (state) {
    set(state, 'index', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TASK_INDEX_SUCCESS] (state, payload) {
    set(state, 'index', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TASK_INDEX_FAILURE] (state, payload) {
    set(state, 'index', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TASK_INDEX_NO_CONTENT] (state) {
    set(state, 'index', {
      item: null,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.TASK_PRIORITIZED_PENDING] (state) {
    set(state, 'prioritized', {
      ...state.prioritized,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TASK_PRIORITIZED_SUCCESS] (state, payload) {
    set(state, 'prioritized', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TASK_PRIORITIZED_FAILURE] (state, payload) {
    set(state, 'prioritized', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TASK_PRIORITIZED_NO_CONTENT] (state) {
    set(state, 'prioritized', {
      items: [],
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.TASK_COUNT_PENDING] (state) {
    set(state, 'count', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TASK_COUNT_SUCCESS] (state, payload) {
    set(state, 'count', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TASK_COUNT_FAILURE] (state, payload) {
    set(state, 'count', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TASK_SHOW_PENDING] (state) {
    set(state, 'show', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TASK_SHOW_SUCCESS] (state, payload) {
    set(state, 'show', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TASK_SHOW_FAILURE] (state, payload) {
    set(state, 'show', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TASK_CREATE_PENDING] (state) {
    set(state, 'create', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TASK_CREATE_SUCCESS] (state, payload) {
    set(state, 'create', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TASK_CREATE_FAILURE] (state, payload) {
    set(state, 'create', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TASK_UPDATE_PENDING] (state) {
    set(state, 'update', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TASK_UPDATE_SUCCESS] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TASK_UPDATE_FAILURE] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TASK_DELETE_PENDING] (state) {
    set(state, 'delete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TASK_DELETE_SUCCESS] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.TASK_DELETE_FAILURE] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.TASK_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
