import * as C from './constants'

export default {

  async getProcedureTemplateIndex ({ commit }) {
    try {
      commit(C.PROCEDURE_TEMPLATE_INDEX_PENDING)
      const res = await this.$axios.get('procedure-templates')
      if (res.status === 200) {
        commit(C.PROCEDURE_TEMPLATE_INDEX_SUCCESS, res.data)
      } else {
        commit(C.PROCEDURE_TEMPLATE_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.PROCEDURE_TEMPLATE_INDEX_FAILURE, err.response.data)
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getProcedureTemplate ({ commit }, payload) {
    try {
      commit(C.PROCEDURE_TEMPLATE_SHOW_PENDING)
      const res = await this.$axios.get(`procedure-templates/${payload}`)
      commit(C.PROCEDURE_TEMPLATE_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.PROCEDURE_TEMPLATE_SHOW_FAILURE, err.response.data)
    }
  },

  /**
   * @param commit
   */
  resetProcedureTemplateStore ({ commit }) {
    commit(C.PROCEDURE_TEMPLATE_RESET_STORE)
  }
}
