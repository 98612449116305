import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateOrCreateItGroupProtection ({ commit }, payload) {
    try {
      commit(C.IT_GROUP_PROTECTION_UPDATE_OR_CREATE_PENDING)
      const res = await this.$axios.post('it-group-protections', payload)
      commit(C.IT_GROUP_PROTECTION_UPDATE_OR_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.IT_GROUP_PROTECTION_UPDATE_OR_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteItGroupProtection ({ commit }, payload) {
    try {
      commit(C.IT_GROUP_PROTECTION_DELETE_PENDING)
      const res = await this.$axios.delete(`it-group-protections/${payload.id}`, payload)
      commit(C.IT_GROUP_PROTECTION_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.IT_GROUP_PROTECTION_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetItGroupProtectionStore ({ commit }) {
    commit(C.IT_GROUP_PROTECTION_RESET_STORE)
  }
}
