// UNIT_CATEGORY_ CONSTANTS
export const UNIT_CATEGORY_INDEX_PENDING = 'UNIT_CATEGORY_INDEX_PENDING'
export const UNIT_CATEGORY_INDEX_SUCCESS = 'UNIT_CATEGORY_INDEX_SUCCESS'
export const UNIT_CATEGORY_INDEX_FAILURE = 'UNIT_CATEGORY_INDEX_FAILURE'
export const UNIT_CATEGORY_INDEX_NO_CONTENT = 'UNIT_CATEGORY_INDEX_NO_CONTENT'

export const UNIT_CATEGORY_CREATE_PENDING = 'UNIT_CATEGORY_CREATE_PENDING'
export const UNIT_CATEGORY_CREATE_SUCCESS = 'UNIT_CATEGORY_CREATE_SUCCESS'
export const UNIT_CATEGORY_CREATE_FAILURE = 'UNIT_CATEGORY_CREATE_FAILURE'

export const UNIT_CATEGORY_SHOW_PENDING = 'UNIT_CATEGORY_SHOW_PENDING'
export const UNIT_CATEGORY_SHOW_SUCCESS = 'UNIT_CATEGORY_SHOW_SUCCESS'
export const UNIT_CATEGORY_SHOW_FAILURE = 'UNIT_CATEGORY_SHOW_FAILURE'

export const UNIT_CATEGORY_UPDATE_PENDING = 'UNIT_CATEGORY_UPDATE_PENDING'
export const UNIT_CATEGORY_UPDATE_SUCCESS = 'UNIT_CATEGORY_UPDATE_SUCCESS'
export const UNIT_CATEGORY_UPDATE_FAILURE = 'UNIT_CATEGORY_UPDATE_FAILURE'

export const UNIT_CATEGORY_DELETE_PENDING = 'UNIT_CATEGORY_DELETE_PENDING'
export const UNIT_CATEGORY_DELETE_SUCCESS = 'UNIT_CATEGORY_DELETE_SUCCESS'
export const UNIT_CATEGORY_DELETE_FAILURE = 'UNIT_CATEGORY_DELETE_FAILURE'

export const UNIT_CATEGORY_BREADCRUMBS_PENDING = 'UNIT_CATEGORY_BREADCRUMBS_PENDING'
export const UNIT_CATEGORY_BREADCRUMBS_SUCCESS = 'UNIT_CATEGORY_BREADCRUMBS_SUCCESS'
export const UNIT_CATEGORY_BREADCRUMBS_FAILURE = 'UNIT_CATEGORY_BREADCRUMBS_FAILURE'
export const UNIT_CATEGORY_BREADCRUMBS_RESET = 'UNIT_CATEGORY_BREADCRUMBS_RESET'

export const UNIT_CATEGORY_RESET_STORE = 'UNIT_CATEGORY_RESET_STORE'
