import Vue from 'vue'

/**
 * Currency filter
 */
Vue.filter('currency', function (
  amount,
  decimal = 0,
  options = {
    locale: 'da',
    currency: 'DKK',
    suffix: null
  }
) {
  // Convert to float
  const floatAmount = parseFloat(amount)

  // Check if valid value or return null
  if (isNaN(floatAmount) || !isFinite(floatAmount)) {
    return null
  }

  // Format the amount with commas for thousands separator
  const value = floatAmount.toLocaleString(options.locale, {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
    style: 'currency',
    currency: options.currency
  })

  return options.suffix ? `${value}/${options.suffix}` : value
})
