// plugins/google-maps-loader.js
import { Loader } from '@googlemaps/js-api-loader'

export default async ({ $config, i18n }, inject) => {
  const loader = new Loader({
    apiKey: $config.googleMapsApiKey,
    language: i18n.locale || 'da', // Get from i18n and default to da
    region: 'DK',
    libraries: ['places', 'marker'] // Add other libraries as needed
  })

  try {
    const maps = await loader.importLibrary('maps')
    inject('google', maps)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('Google maps not injected correctly', err)
  }
}
