// ORGANIZATION CONSTANTS

export const ORGANIZATION_SUBSCRIPTION_INDEX_PENDING = 'ORGANIZATION_SUBSCRIPTION_INDEX_PENDING'
export const ORGANIZATION_SUBSCRIPTION_INDEX_SUCCESS = 'ORGANIZATION_SUBSCRIPTION_INDEX_SUCCESS'
export const ORGANIZATION_SUBSCRIPTION_INDEX_FAILURE = 'ORGANIZATION_SUBSCRIPTION_INDEX_FAILURE'
export const ORGANIZATION_SUBSCRIPTION_INDEX_NO_CONTENT = 'ORGANIZATION_SUBSCRIPTION_INDEX_NO_CONTENT'

export const ORGANIZATION_SUBSCRIPTION_ACTIVE_PENDING = 'ORGANIZATION_SUBSCRIPTION_ACTIVE_PENDING'
export const ORGANIZATION_SUBSCRIPTION_ACTIVE_SUCCESS = 'ORGANIZATION_SUBSCRIPTION_ACTIVE_SUCCESS'
export const ORGANIZATION_SUBSCRIPTION_ACTIVE_FAILURE = 'ORGANIZATION_SUBSCRIPTION_ACTIVE_FAILURE'
export const ORGANIZATION_SUBSCRIPTION_ACTIVE_NO_CONTENT = 'ORGANIZATION_SUBSCRIPTION_ACTIVE_NO_CONTENT'

export const ORGANIZATION_SUBSCRIPTION_CREATE_PENDING = 'ORGANIZATION_SUBSCRIPTION_CREATE_PENDING'
export const ORGANIZATION_SUBSCRIPTION_CREATE_SUCCESS = 'ORGANIZATION_SUBSCRIPTION_CREATE_SUCCESS'
export const ORGANIZATION_SUBSCRIPTION_CREATE_FAILURE = 'ORGANIZATION_SUBSCRIPTION_CREATE_FAILURE'

export const ORGANIZATION_SUBSCRIPTION_UPDATE_PENDING = 'ORGANIZATION_SUBSCRIPTION_UPDATE_PENDING'
export const ORGANIZATION_SUBSCRIPTION_UPDATE_SUCCESS = 'ORGANIZATION_SUBSCRIPTION_UPDATE_SUCCESS'
export const ORGANIZATION_SUBSCRIPTION_UPDATE_FAILURE = 'ORGANIZATION_SUBSCRIPTION_UPDATE_FAILURE'

export const ORGANIZATION_SUBSCRIPTION_RESET_STORE = 'ORGANIZATION_SUBSCRIPTION_RESET_STORE'
