
export default {
  name: 'NowLabel',
  props: {
    for: {
      type: String,
      default: null
    }
  }
}
