import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getLogIndexByModel ({ commit }, payload) {
    try {
      commit(C.LOG_INDEX_PENDING)
      const res = await this.$axios.post(`logs?page=${payload.page}&per-page=${payload.perPage}`, payload)
      commit(C.LOG_INDEX_SUCCESS, res.data)
    } catch (err) {
      commit(C.LOG_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getLogByOrganizationIndex ({ commit }, payload) {
    try {
      commit(C.LOG_ORGANIZATION_INDEX_PENDING)
      const res = await this.$axios.get('/logs', { params: payload })
      commit(C.LOG_ORGANIZATION_INDEX_SUCCESS, res.data)
    } catch (err) {
      commit(C.LOG_ORGANIZATION_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getLatestLogByModel ({ commit }, payload) {
    try {
      commit(C.LOG_LATEST_PENDING)
      const res = await this.$axios.post('logs/latest', payload)
      commit(C.LOG_LATEST_SUCCESS, res.data)
    } catch (err) {
      commit(C.LOG_LATEST_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetLogStore ({ commit }) {
    commit(C.LOG_RESET_STORE)
  }
}
