// plugins/auth.js
export default function ({ $auth, store }) {
  // Listen for the logout event
  $auth.$storage.watchState('loggedIn', (loggedIn) => {
    // Dispatch the action to reset all stores when logging out
    if (!loggedIn) {
      store.dispatch('app/resetFullStore')
    }
  })
}
