export default {
  TEXT: 'text',
  NUMBER: 'number',
  PASSWORD: 'password',
  EMAIL: 'email',
  DATE: 'date',
  DATETIME_LOCAL: 'datetime-local',
  FILE: 'file',
  IMAGE: 'image',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  SEARCH: 'search',
  SUBMIT: 'submit',
  COUNT: 'count',
  ADDRESS: 'address',
  ORGANIZATION: 'organization',
  TABLE: 'table',
  RISK: 'risk',
  STAKEHOLDER_ANALYSIS: 'stakeholderAnalysis',
  SECTION: 'section',
  DEVIATION: 'deviation',
  AUDIT: 'audit',
  ACTION_PLAN: 'actionPlan',
  BOOLEAN: 'boolean',
  BUTTON: 'button',
  SELECT: 'select',
  USER: 'user'
}
