import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getArchiveFileIndex ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_FILE_INDEX_PENDING)
      const res = await this.$axios.get('archive-files', { params: payload })
      if (res.status === 200) {
        commit(C.ARCHIVE_FILE_INDEX_SUCCESS, res.data)
      } else {
        commit(C.ARCHIVE_FILE_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.ARCHIVE_FILE_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getArchiveFileVersions ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_FILE_VERSIONS_PENDING)
      const res = await this.$axios.get(`archive-files/${payload}/versions`)
      if (res.status === 200) {
        commit(C.ARCHIVE_FILE_VERSIONS_SUCCESS, res.data)
      } else {
        commit(C.ARCHIVE_FILE_VERSIONS_NO_CONTENT)
      }
    } catch (err) {
      commit(C.ARCHIVE_FILE_VERSIONS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getArchiveFileTrashed ({ commit }) {
    try {
      commit(C.ARCHIVE_FILE_TRASHED_PENDING)
      const res = await this.$axios.get('archive-files/trashed')
      if (res.status === 200) {
        commit(C.ARCHIVE_FILE_TRASHED_SUCCESS, res.data)
      } else {
        commit(C.ARCHIVE_FILE_TRASHED_NO_CONTENT)
      }
    } catch (err) {
      commit(C.ARCHIVE_FILE_TRASHED_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createArchiveFile ({ commit }, payload) {
    const formData = new FormData()
    formData.append('file', payload.file)
    if (payload.archive_id) {
      formData.append('archive_id', payload.archive_id)
    }
    try {
      commit(C.ARCHIVE_FILE_CREATE_PENDING)
      const res = await this.$axios.post('archive-files', formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        }
      })
      commit(C.ARCHIVE_FILE_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_FILE_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateArchiveFile ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_FILE_UPDATE_PENDING)
      const res = await this.$axios.put(`archive-files/${payload.id}`, payload)
      commit(C.ARCHIVE_FILE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_FILE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async moveArchiveFile ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_FILE_UPDATE_PENDING)
      const res = await this.$axios.put(`archive-files/${payload.id}/move`, payload)
      commit(C.ARCHIVE_FILE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_FILE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async setArchiveFileVersion ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_FILE_UPDATE_PENDING)
      const res = await this.$axios.put(`archive-files/${payload.id}/versions`, payload)
      commit(C.ARCHIVE_FILE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_FILE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteArchiveFile ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_FILE_DELETE_PENDING)
      const res = await this.$axios.delete(`archive-files/${payload}`)
      commit(C.ARCHIVE_FILE_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_FILE_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async restoreArchiveFile ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_FILE_RESTORE_PENDING)
      const res = await this.$axios.get(`archive-files/${payload}/restore`)
      commit(C.ARCHIVE_FILE_RESTORE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_FILE_RESTORE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateArchiveFileCreatedBy ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_FILE_CREATED_BY_PENDING)
      const res = await this.$axios.put(`archive-files/${payload.id}/created-by`, payload)
      commit(C.ARCHIVE_FILE_CREATED_BY_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_FILE_CREATED_BY_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateArchiveFileCompanyUnit ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_FILE_UPDATE_PENDING)
      const res = await this.$axios.put(`archive-files/${payload.id}/company-unit`, payload)
      commit(C.ARCHIVE_FILE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_FILE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateArchiveFileUsers ({ commit }, payload) {
    try {
      commit(C.ARCHIVE_FILE_USERS_PENDING)
      const res = await this.$axios.put(`archive-files/${payload.id}/users`, payload)
      commit(C.ARCHIVE_FILE_USERS_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_FILE_USERS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateArchiveFileVersion ({ commit }, payload) {
    const formData = new FormData()
    formData.append('file', payload.file)
    try {
      commit(C.ARCHIVE_FILE_UPDATE_PENDING)
      const res = await this.$axios.post(`archive-files/${payload.id}/versions`, formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        }
      })
      commit(C.ARCHIVE_FILE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_FILE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getArchiveFileBookmarked ({ commit }) {
    try {
      commit(C.ARCHIVE_FILE_BOOKMARKED_PENDING)
      const res = await this.$axios.get('archive-files/bookmarked')
      commit(C.ARCHIVE_FILE_BOOKMARKED_SUCCESS, res.data)
    } catch (err) {
      commit(C.ARCHIVE_FILE_BOOKMARKED_FAILURE)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetArchiveFileStore ({ commit }) {
    commit(C.ARCHIVE_FILE_RESET_STORE)
  }
}
