import * as C from './constants'

export default {

  /**
   * @param commit
   * @param $config
   * @returns {Promise<void>}
   */
  async getOrganizationReportTypeIndex ({ commit }) {
    try {
      commit(C.ORGANIZATION_REPORT_TYPE_INDEX_PENDING)
      const res = await this.$axios.get('organization-report-types')
      if (res.status === 200 && res.data) {
        commit(C.ORGANIZATION_REPORT_TYPE_INDEX_SUCCESS, res.data)
      } else {
        commit(C.ORGANIZATION_REPORT_TYPE_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.ORGANIZATION_REPORT_TYPE_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createOrganizationReportType ({ commit }, payload) {
    try {
      commit(C.ORGANIZATION_REPORT_TYPE_CREATE_PENDING)
      const res = await this.$axios.post('organization-report-types', payload)
      commit(C.ORGANIZATION_REPORT_TYPE_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ORGANIZATION_REPORT_TYPE_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateOrganizationReportType ({ commit }, payload) {
    try {
      commit(C.ORGANIZATION_REPORT_TYPE_UPDATE_PENDING)
      const res = await this.$axios.put(`organization-report-types/${payload.id}`, payload)
      commit(C.ORGANIZATION_REPORT_TYPE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ORGANIZATION_REPORT_TYPE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteOrganizationReportType ({ commit }, payload) {
    try {
      commit(C.ORGANIZATION_REPORT_TYPE_DELETE_PENDING)
      const res = await this.$axios.delete(`organization-report-types/${payload.id}`)
      commit(C.ORGANIZATION_REPORT_TYPE_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ORGANIZATION_REPORT_TYPE_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetOrganizationReportTypeStore ({ commit }) {
    commit(C.ORGANIZATION_REPORT_TYPE_RESET_STORE)
  }
}
