// DEVIATION CONSTANTS
export const REPORT_PROCEDURE_INDEX_PENDING = 'REPORT_PROCEDURE_INDEX_PENDING'
export const REPORT_PROCEDURE_INDEX_SUCCESS = 'REPORT_PROCEDURE_INDEX_SUCCESS'
export const REPORT_PROCEDURE_INDEX_FAILURE = 'REPORT_PROCEDURE_INDEX_FAILURE'
export const REPORT_PROCEDURE_INDEX_NO_CONTENT = 'REPORT_PROCEDURE_INDEX_NO_CONTENT'

export const REPORT_PROCEDURE_UNREAD_PENDING = 'REPORT_PROCEDURE_UNREAD_PENDING'
export const REPORT_PROCEDURE_UNREAD_SUCCESS = 'REPORT_PROCEDURE_UNREAD_SUCCESS'
export const REPORT_PROCEDURE_UNREAD_FAILURE = 'REPORT_PROCEDURE_UNREAD_FAILURE'
export const REPORT_PROCEDURE_UNREAD_NO_CONTENT = 'REPORT_PROCEDURE_UNREAD_NO_CONTENT'

export const REPORT_PROCEDURE_CREATE_PENDING = 'REPORT_PROCEDURE_CREATE_PENDING'
export const REPORT_PROCEDURE_CREATE_SUCCESS = 'REPORT_PROCEDURE_CREATE_SUCCESS'
export const REPORT_PROCEDURE_CREATE_FAILURE = 'REPORT_PROCEDURE_CREATE_FAILURE'

export const REPORT_PROCEDURE_UPDATE_PENDING = 'REPORT_PROCEDURE_UPDATE_PENDING'
export const REPORT_PROCEDURE_UPDATE_SUCCESS = 'REPORT_PROCEDURE_UPDATE_SUCCESS'
export const REPORT_PROCEDURE_UPDATE_FAILURE = 'REPORT_PROCEDURE_UPDATE_FAILURE'

export const REPORT_PROCEDURE_DELETE_PENDING = 'REPORT_PROCEDURE_DELETE_PENDING'
export const REPORT_PROCEDURE_DELETE_SUCCESS = 'REPORT_PROCEDURE_DELETE_SUCCESS'
export const REPORT_PROCEDURE_DELETE_FAILURE = 'REPORT_PROCEDURE_DELETE_FAILURE'

export const REPORT_PROCEDURE_RESTORE_PENDING = 'REPORT_PROCEDURE_RESTORE_PENDING'
export const REPORT_PROCEDURE_RESTORE_SUCCESS = 'REPORT_PROCEDURE_RESTORE_SUCCESS'
export const REPORT_PROCEDURE_RESTORE_FAILURE = 'REPORT_PROCEDURE_RESTORE_FAILURE'

export const REPORT_PROCEDURE_VERSION_PENDING = 'REPORT_PROCEDURE_VERSION_PENDING'
export const REPORT_PROCEDURE_VERSION_SUCCESS = 'REPORT_PROCEDURE_VERSION_SUCCESS'
export const REPORT_PROCEDURE_VERSION_FAILURE = 'REPORT_PROCEDURE_VERSION_FAILURE'

export const REPORT_PROCEDURE_APPENDIX_MODAL_TOGGLE = 'REPORT_PROCEDURE_APPENDIX_MODAL_TOGGLE'

export const REPORT_PROCEDURE_RESET_STORE = 'REPORT_PROCEDURE_RESET_STORE'
