import * as C from './constants'

export default {

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getReportIndex ({ commit }) {
    try {
      commit(C.REPORT_INDEX_PENDING)
      const res = await this.$axios.get('reports')
      if (res.status === 200 && res.data) {
        commit(C.REPORT_INDEX_SUCCESS, res.data)
      } else {
        commit(C.REPORT_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.REPORT_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getReport ({ commit }, payload) {
    try {
      commit(C.REPORT_PENDING)
      const res = await this.$axios.get(`reports/${payload}`)
      commit(C.REPORT_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createReport ({ commit }, payload) {
    try {
      commit(C.REPORT_CREATE_PENDING)
      const res = await this.$axios.post('reports', payload)
      commit(C.REPORT_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateReportResponsible ({ commit }, payload) {
    try {
      commit(C.REPORT_UPDATE_PENDING)
      const res = await this.$axios.put(`reports/${payload.id}/responsible`, payload)
      commit(C.REPORT_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteReport ({ commit }, payload) {
    try {
      commit(C.REPORT_DELETE_PENDING)
      const res = await this.$axios.delete(`reports/${payload}`)
      commit(C.REPORT_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getReportLinks ({ commit }, payload) {
    try {
      commit(C.REPORT_LINKS_PENDING)
      const res = await this.$axios.get(`reports/${payload}/links`)
      commit(C.REPORT_LINKS_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_LINKS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getReportLinksTrashed ({ commit }, payload) {
    try {
      commit(C.REPORT_LINKS_TRASHED_PENDING)
      const res = await this.$axios.get(`reports/${payload}/links/trashed`)
      commit(C.REPORT_LINKS_TRASHED_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_LINKS_TRASHED_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateOrCreateReportCompanies ({ commit }, payload) {
    try {
      commit(C.REPORT_COMPANIES_PENDING)
      const res = await this.$axios.put(`reports/${payload.report_id}/companies`, payload)
      commit(C.REPORT_COMPANIES_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_COMPANIES_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getReportProgress ({ commit }, payload) {
    try {
      commit(C.REPORT_PROGRESS_PENDING)
      const res = await this.$axios.get(`reports/${payload}/progress`)
      commit(C.REPORT_PROGRESS_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PROGRESS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async downloadReportAsPdf ({ commit }, payload) {
    try {
      commit(C.REPORT_PDF_PENDING)
      const res = await this.$axios.get(`reports/${payload}/pdf`, {
        responseType: 'blob' // <-- Important: Specify 'blob' here
      })
      commit(C.REPORT_PDF_SUCCESS, res.data)
    } catch (err) {
      commit(C.REPORT_PDF_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetReportStore ({ commit }) {
    commit(C.REPORT_RESET_STORE)
  }
}
